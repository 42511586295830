import React, { useMemo } from 'react';
import {
  ModalDataInfiniteScrollParams,
  useGetStatsTargetStoresQuery,
} from '../apiCalculationStatistics';
import { useCurrency } from '../../../../hooks/useCurrency';
import { getStatisticsTargetStoresTableColumns } from './statisticsModalTableColumns';
import { computeRemSize, getDefaultSorting, InfiniteScrollTable } from '@ydistri/ds';
import { CalculationStatisticStoreTargetResponse } from '@ydistri/api-sdk';
import {
  EntityColumns,
  useStoreTableColumnsProvider,
} from '../../../../hooks/useEntityTableColumnsProvider';

interface StatisticsModalTableTargetStoresProps {
  params: ModalDataInfiniteScrollParams;
  setParams: React.Dispatch<React.SetStateAction<ModalDataInfiniteScrollParams>>;
  displayDepartment?: boolean;
}

const StatisticsModalTableTargetStores: React.FC<StatisticsModalTableTargetStoresProps> = ({
  params,
  setParams,
  displayDepartment = false,
}) => {
  const currencyId = useCurrency();
  const storeColumnsProvider = useStoreTableColumnsProvider();

  const columns = useMemo(() => {
    const columnConfig = {
      [EntityColumns.ID]: {
        width: computeRemSize(120),
      },
      [EntityColumns.CODE]: {
        width: computeRemSize(120),
      },
    };
    const storeColumns = storeColumnsProvider<CalculationStatisticStoreTargetResponse>(
      columnConfig,
      'targetStore',
      'Target Store',
    );

    return getStatisticsTargetStoresTableColumns(currencyId, displayDepartment, storeColumns);
  }, [currencyId, displayDepartment, storeColumnsProvider]);

  const { data, isFetching } = useGetStatsTargetStoresQuery({
    ...params,
    sortings: params.sortings ?? getDefaultSorting(columns),
  });

  return (
    <InfiniteScrollTable<CalculationStatisticStoreTargetResponse, ModalDataInfiniteScrollParams>
      id="statistics-modal-target-stores-table"
      columns={columns}
      dataSource={data}
      loading={isFetching}
      size="small"
      setParams={setParams}
      height={computeRemSize(400)}
    />
  );
};

export default StatisticsModalTableTargetStores;
