import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCategoryDetailType } from '../../../../detailLib';
import {
  SaleMonthlyOverviewResponse,
  SaleMonthlyOverviewResponseCollectionResponse,
} from '@ydistri/api-sdk';
import { CategoryDetailType, DEFAULT_SALE_MONTH_COUNT } from '../../../../detailTypes';
import useCategoryId from '../../../../../../hooks/useCategoryId';
import { CategoriesCollection } from '../../../../../../swagger/collections';
import { setIsLoadingSalesData } from '../../../../detailSlice';
import DetailSales from '../DetailSales/DetailSales';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';
import { useAppDispatch } from '../../../../../../store';
import { AxiosResponse } from 'axios';

const DetailCategoryBody: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedCategoryId = useCategoryId();
  const storeId = useSelector(state => state.detailReducer.storeId);
  const productId = useSelector(state => state.detailReducer.productId);
  const regionId = useSelector(state => state.detailReducer.regionId);
  const isLoadingSalesData = useSelector(state => state.detailReducer.isLoadingSalesData);

  const categoryDetailType = useMemo(
    () => getCategoryDetailType(productId, regionId, storeId),
    [productId, regionId, storeId],
  );

  const [salesData, setSalesData] = useState<SaleMonthlyOverviewResponse[]>();

  // called from inside useEffect when a response is received. There are three different queries
  // but the response is always handled the same way
  const handleMonthlySalesResponse = useCallback(
    (response: AxiosResponse<SaleMonthlyOverviewResponseCollectionResponse>) => {
      setSalesData(response.data.data);
      dispatch(setIsLoadingSalesData(false));
    },
    [dispatch],
  );

  //TODO review 3 - add new query, which checks params and starts 1 of 3 queries?
  useEffect(() => {
    if (!(selectedCategoryId && productId === NO_VALUE)) {
      return;
    }

    if (categoryDetailType === undefined) {
      return;
    }

    const controller: AbortController = new AbortController();
    const signal: AbortSignal = controller.signal;

    switch (categoryDetailType) {
      case CategoryDetailType.DEFAULT: {
        CategoriesCollection.getCategoryMonthlySalesWithinMonthsRange(
          selectedCategoryId,
          DEFAULT_SALE_MONTH_COUNT,
          { signal: signal },
        ).then(handleMonthlySalesResponse);
        break;
      }
      case CategoryDetailType.STORE: {
        if (storeId) {
          CategoriesCollection.getWarehouseCategoryMonthlySalesWithinMonthsRange(
            selectedCategoryId,
            storeId,
            DEFAULT_SALE_MONTH_COUNT,
            { signal: signal },
          ).then(handleMonthlySalesResponse);
        }
        break;
      }
      case CategoryDetailType.REGION: {
        CategoriesCollection.getRegionCategoryMonthlySalesWithinMonthsRange(
          selectedCategoryId,
          regionId,
          DEFAULT_SALE_MONTH_COUNT,
          { signal: signal },
        ).then(handleMonthlySalesResponse);
        break;
      }
    }

    return () => {
      controller.abort();
    };
  }, [
    categoryDetailType,
    dispatch,
    handleMonthlySalesResponse,
    productId,
    regionId,
    selectedCategoryId,
    storeId,
  ]);

  return (
    <DetailSales salesData={salesData} loadingSalesData={isLoadingSalesData} hasForecasts={false} />
  );
};

export default DetailCategoryBody;
