import React, { useMemo } from 'react';
import { Section } from '@ydistri/ds';
import PickingResultsNavigation from './PickingResultsNavigation';
import PickingResultsExecution from '../parts/executions/PickingResultsExecution';
import { PickingResultsPart } from '../pickingResultsLib';
import PickingPerformance from '../parts/performance/PickingPerformance';
import CalculationPickingResultsHeader from '../parts/executions/CalculationPickingResultsHeader';
import CalculationPickingPerformanceHeader from '../parts/performance/CalculationPickingPerformanceHeader';
import PickingIssues from '../parts/issues/PickingIssues';
import CalculationPickingIssuesHeader from '../parts/issues/CalculationPickingIssuesHeader';
import { usePickingResultsPart } from '../hooks/usePickingResultsPart';

/**
 * Content of the section with header, table and button to export the table.
 */
const SectionStorePickingResults: React.FC = () => {
  const currentPart = usePickingResultsPart();

  const headerComponent = useMemo(() => {
    switch (currentPart) {
      case PickingResultsPart.EXECUTION:
        return <CalculationPickingResultsHeader />;
      case PickingResultsPart.PERFORMANCE:
        return <CalculationPickingPerformanceHeader />;
      case PickingResultsPart.ISSUES:
        return <CalculationPickingIssuesHeader />;
      default:
        return null;
    }
  }, [currentPart]);

  const bodyComponent = useMemo(() => {
    switch (currentPart) {
      case PickingResultsPart.EXECUTION:
        return <PickingResultsExecution />;
      case PickingResultsPart.PERFORMANCE:
        return <PickingPerformance />;
      case PickingResultsPart.ISSUES:
        return <PickingIssues />;
      default:
        return null;
    }
  }, [currentPart]);

  return (
    <Section
      header={headerComponent}
      headerActions={<PickingResultsNavigation />}
      headerActionsIncludeMarginBottom={false}
    >
      {bodyComponent}
    </Section>
  );
};

export default SectionStorePickingResults;
