import React, { useMemo } from 'react';
import { useGetMonthlyForecastQuery } from '../../../../../../Calculations/apiCalculationsSkus';
import { useTemplateOrCalculation } from '../../../../../../../hooks/useTemplateOrCalculation';
import {
  ConfigurationFieldType,
  ForecastMonthlyOverviewResponse,
  OverviewValueType,
  TransactionOverviewType,
} from '@ydistri/api-sdk';
import { SkuData, SkuRedistributionPaneType } from '../../../../redistributionTypes';
import { Spinner } from '@ydistri/ds';
import { Popover } from 'antd';

const getForecastRow = (
  data: ForecastMonthlyOverviewResponse,
  confidence: number,
  forecastTransactionType: TransactionOverviewType,
) => {
  return data.overviews.find(
    o =>
      o.confidence === confidence &&
      o.overviewValueType === OverviewValueType.Quantity &&
      o.transactionOverviewType === forecastTransactionType,
  );
};

interface SupplyCoverageProps {
  skuId: number;
  skuData: SkuData;
  infoType: SkuRedistributionPaneType;
  supply: number;
  displayType?: 'popover' | 'text';
}

const SupplyCoverage: React.FC<SupplyCoverageProps> = ({
  skuId,
  skuData,
  infoType,
  supply,
  displayType = 'text',
}) => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: monthlyForecast, isFetching } = useGetMonthlyForecastQuery({
    skuId,
    calculationId: templateOrCalculation.id,
  });

  const confidenceType =
    infoType === 'Target'
      ? ConfigurationFieldType.PtMinForecastConfidence
      : ConfigurationFieldType.PsMaxForecastConfidence;
  const forecastTransactionType =
    infoType === 'Target'
      ? TransactionOverviewType.ForecastMin
      : TransactionOverviewType.ForecastMax;

  const confidence = parseInt(skuData.config[confidenceType] ?? '75');

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- too much going on in the dependency array
  const finalMonthOverview = useMemo(() => {
    if (monthlyForecast) {
      let lowerMonthValue = 0;
      let lowerMonthValueIndex = 0;
      let upperMonthValueIndex = 0;

      const mLower = monthlyForecast.find((month, i) => {
        const lowerValue = getForecastRow(month, confidence, forecastTransactionType);
        if (!lowerValue) return false;
        const result = lowerMonthValue + lowerValue.value > supply;
        if (!result) {
          lowerMonthValue += lowerValue.value;
          lowerMonthValueIndex = i;
          upperMonthValueIndex = i + 1;
        } else if (i === 0) {
          upperMonthValueIndex = 0;
        }
        return result;
      });

      const mUpper = monthlyForecast[upperMonthValueIndex];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- may be undefined
      if (mLower && mUpper) {
        const lowerValue = lowerMonthValue;
        const upperValue =
          lowerMonthValue +
          (getForecastRow(mUpper, confidence, forecastTransactionType)?.value ?? 0);
        if (upperValue) {
          const rangeDiff = upperValue - lowerValue;
          const valueDiff = supply - lowerValue;
          if (rangeDiff > 0 && valueDiff < rangeDiff) {
            //valueDiff > 0 &&
            return upperMonthValueIndex + valueDiff / rangeDiff;
          }
        }
      }

      // when lowerMonthValueIndex is 5, that means mUpper will be undefined (6 doesn't exist), so function will return 6 (5+1)
      return lowerMonthValueIndex + 1;
    }
    return 0;
  }, [monthlyForecast, confidence, forecastTransactionType, supply]);

  const finalMonths =
    finalMonthOverview > 0 && finalMonthOverview < 6
      ? finalMonthOverview.toFixed(Math.floor(finalMonthOverview) === finalMonthOverview ? 0 : 1)
      : '6+';

  const textValue = `${finalMonths} month${finalMonths !== '1' ? 's' : ''}`;

  if (supply === 0) return;
  if (isFetching) return <Spinner />;
  if (displayType === 'text') return textValue;
  return (
    <Popover title="Inventory coverage" content={textValue}>
      ({finalMonths} m)
    </Popover>
  );
};

export default SupplyCoverage;
