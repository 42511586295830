import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { Input, Text } from '@ydistri/ds';
import TemplateMultiselectAndSort from './TemplateMultiselectAndSort';
import { TemplateResponse } from '@ydistri/api-sdk';

export type MergedCalculationTitles = Record<number, string | undefined>;

export interface MergedCalculationData {
  sortedTemplateIds: number[];
  calculationTitles: MergedCalculationTitles;
}

interface TemplateMultiselectAndSortProps {
  onChange: (v: MergedCalculationData) => void;
  defaultValues?: MergedCalculationData;
}

const MergedCalculationTemplateSelect: React.FC<TemplateMultiselectAndSortProps> = ({
  onChange,
  defaultValues,
}) => {
  const [sortedTemplateIds, setSortedTemplateIds] = useState<number[]>(() => {
    if (defaultValues) {
      return defaultValues.sortedTemplateIds;
    } else {
      return [];
    }
  });
  const [calculationTitles, setCalculationTitles] = useState<MergedCalculationTitles>(() => {
    if (defaultValues) {
      return defaultValues.calculationTitles;
    } else {
      return {};
    }
  });

  const onTemplateChangeHandler = useCallback(
    (x: number[]) => {
      setSortedTemplateIds(x);
    },
    [setSortedTemplateIds],
  );

  const onRemoveTemplate = useCallback((templateId: number) => {
    setCalculationTitles(p => {
      return { ...p, [templateId]: undefined };
    });
  }, []);

  const onChangeCalculationTitle = useCallback((templateId: number, calculationTitle: string) => {
    setCalculationTitles(p => ({ ...p, [templateId]: calculationTitle }));
  }, []);

  const onChangeInput = useCallback(
    (templateId: number) => {
      const eventHandler: ChangeEventHandler<HTMLInputElement> = e => {
        onChangeCalculationTitle(templateId, e.target.value);
      };

      return eventHandler;
    },
    [onChangeCalculationTitle],
  );

  const customElement = useCallback(
    (templateId: number, index: number) => {
      return (
        <>
          <Text>[{index + 1}]</Text>
          <Input
            size="large"
            value={calculationTitles[templateId]}
            infoFlexGrow={1}
            onChange={onChangeInput(templateId)}
            suffix={
              <Text $type="light" $size="small">
                template #{templateId}
              </Text>
            }
          />
        </>
      );
    },
    [calculationTitles, onChangeInput],
  );

  const onTemplateAddedPrefillTitle = useCallback(
    (tpl: TemplateResponse) => {
      onChangeCalculationTitle(tpl.id, tpl.title);
    },
    [onChangeCalculationTitle],
  );

  useEffect(() => {
    onChange({ sortedTemplateIds, calculationTitles });
  }, [calculationTitles, onChange, sortedTemplateIds]);

  return (
    <TemplateMultiselectAndSort
      defaultValues={defaultValues?.sortedTemplateIds}
      customElements={customElement}
      onChangeSelectedTemplates={onTemplateChangeHandler}
      onCustomTemplateEvent={onTemplateAddedPrefillTitle}
      onRemoveTemplate={onRemoveTemplate}
    />
  );
};

export default MergedCalculationTemplateSelect;
