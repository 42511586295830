import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import { formatNumber } from '@ydistri/utils';
import StatisticsOverallIcon from '../../../../assets/statistics-overall.svg';
import { SimpleTable, SimpleTableDivider } from './components/SimpleTable';
import { computeRemSize, Title } from '@ydistri/ds';
import StatisticsSection from './components/StatisticsSection';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { useGetStatisticsQuery } from './apiCalculationStatistics';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';

type OverallStatistics =
  | {
      title: string;
      key: string;
      value: string | number;
      unit: string;
    }
  | SimpleTableDivider;

const overallStatisticsColumns: ColumnsType<OverallStatistics> = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: computeRemSize(300),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    align: 'right',
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
  },
];

const SectionOverallStatistics: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { departments: hasDepartments } = useApplicationData();
  const { forceQuantities, minimalLayers } = useApplicationData();
  const currencyId = useCurrency();
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);

  const displayDepartmentText =
    hasDepartments && clusterType === CalculationStatisticClusterType.StoreDepartment;

  const { data, isFetching: isFetchingStatistics } = useGetStatisticsQuery({
    calculationId: templateOrCalculation.id,
    departmentId,
    clusterType,
  });

  const tableData: OverallStatistics[] = useMemo(() => {
    const newData: OverallStatistics[] = [];
    if (data) {
      const storesSourceAvailableCount = data.storesSourceAvailableCount
        ? ` / ${formatNumber(data.storesSourceAvailableCount)}`
        : '';

      const storesTargetAvailableCount = data.storesTargetAvailableCount
        ? ` / ${formatNumber(data.storesTargetAvailableCount)}`
        : '';

      newData.push(
        ...[
          {
            title: 'Total value',
            key: 'totalValue',
            value: formatNumber(data.value),
            unit: currencyId,
          },
          {
            title: 'Total quantity',
            key: 'totalQuantity',
            value: formatNumber(data.quantity),
            unit: 'MU',
          },
          {
            divider: true,
            key: 'divider1',
          },
          {
            title: `Source store${displayDepartmentText ? '-department' : ''}s`,
            key: 'sourceStores',
            value: `${formatNumber(data.storesSourceUsedCount)}${storesSourceAvailableCount}`,
            unit: '',
          },
          {
            title: `Target store${displayDepartmentText ? '-department' : ''}s`,
            key: 'targetStores',
            value: `${formatNumber(data.storesTargetUsedCount)}${storesTargetAvailableCount}`,
            unit: '',
          },
          {
            divider: true,
            key: 'divider2',
          },
          {
            title: 'Value of replenished out-of-stocks (on target)',
            key: 'replenishedOutOfStocks',
            value: formatNumber(data.filledStockoutValue),
            unit: `${currencyId} (${parseInt(
              formatNumber((data.filledStockoutValue / data.value) * 100),
            )}%)`,
          },
        ],
      );

      //minimal layers must be enabled in the application settings to show the stats
      if (minimalLayers) {
        newData.push({
          title: 'Value of replenished minimum layers (on target)\n',
          key: 'replenishedMinLayers',
          value: formatNumber(data.targetMinimalLayerValue),
          unit: `${currencyId} (${parseInt(
            formatNumber((data.targetMinimalLayerValue / data.value) * 100),
          )}%)`,
        });
      }

      //forced quantities must be enabled in the application settings to show the stats
      if (forceQuantities) {
        newData.push({
          title: 'Value of forced products (on source)',
          key: 'forcedValueOnSource',
          value: formatNumber(data.sourceForcedProductValue),
          unit: `${currencyId} (${parseInt(
            formatNumber((data.sourceForcedProductValue / data.value) * 100),
          )}%)`,
        });
      }

      newData.push(
        ...[
          {
            divider: true,
            key: 'divider3',
          },
          {
            title: 'Total picking positions count',
            key: 'pickingPositions',
            value: formatNumber(data.pickingPositionCount),
            unit: '',
          },
          {
            title: 'Total distinct product count',
            key: 'distinctProducts',
            value: formatNumber(data.productCount),
            unit: '',
          },
        ],
      );
    }

    return newData;
  }, [data, currencyId, minimalLayers, forceQuantities, displayDepartmentText]);

  return (
    <StatisticsSection icon={StatisticsOverallIcon}>
      <Title>Overall statistics</Title>
      <SimpleTable<OverallStatistics>
        id="overall-statistics"
        rowKey="key"
        dataSource={tableData}
        columns={overallStatisticsColumns}
        loading={isFetchingStatistics}
      />
    </StatisticsSection>
  );
};

export default SectionOverallStatistics;
