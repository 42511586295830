import React from 'react';
import { SubPageButton } from './SubPageButton';
import { PickingResultsPart } from '../pickingResultsLib';
import { ActionsBar } from '@ydistri/ds';
import { generatePath, Link, useParams } from 'react-router';
import { getPickingResultsPart, usePickingResultsPart } from '../hooks/usePickingResultsPart';
import { ROUTES } from '../../../../components/menu/menuLeftItemTemplate';
import { CalculationDetailSubpages } from '../../../Calculations/calculationsLib';

interface PickingResultsPartConfig {
  id: PickingResultsPart;
  label: string;
}

const parts: PickingResultsPartConfig[] = [
  { id: PickingResultsPart.EXECUTION, label: 'Execution' },
  { id: PickingResultsPart.ISSUES, label: 'Issues' },
  { id: PickingResultsPart.PERFORMANCE, label: 'Performance' },
];

const PickingResultsNavigation: React.FC = () => {
  const currentPart = usePickingResultsPart();
  const { projectShortName, calculationId, slug } = useParams();

  return (
    <ActionsBar moveToRight={true} wrap={false}>
      {parts.map(part => {
        const linkPath = generatePath(ROUTES.calculationDetailPickingResults, {
          projectShortName,
          calculationId,
          subpage: CalculationDetailSubpages.PICKING_RESULTS,
          pickingResultsPart: getPickingResultsPart(part.id),
          slug: slug ?? '',
        });
        return (
          <Link to={linkPath} key={part.id}>
            <SubPageButton
              key={part.id}
              id={part.id}
              label={part.label}
              selected={currentPart === part.id}
            />
          </Link>
        );
      })}
    </ActionsBar>
  );
};

export default PickingResultsNavigation;
