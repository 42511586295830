import React, { useCallback, useMemo, useState } from 'react';
import { SaleMonthlyOverviewResponse } from '@ydistri/api-sdk';
import { Chart, ChartData, ChartDataset, TooltipModel } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {
  LINE_TYPES,
  lineTypeTooltipConfig,
  SkuSaleGraphValueColumnType,
  TLineType,
  TParsedTransactions,
  Units,
} from '../../../../../../../lib/charts/saleChartsTypes';
import {
  addSalesUsedForForecastToSaleTypes,
  changeFillInLineTypesBasedOnHiddenLines,
  copyLineTypeToParsedMonthlyTransactionsByAnotherLineType,
  createChartDataFromDatasetsAndParsedMonthlyTransactions,
  createDatasetsFromLineTypesAndGraphLines,
  getForecastLineDefinitions,
  getSaleLineDefinitions,
  hideNullLineTypes,
  NonSaleDataByDay,
  parseTransactions,
  ParseTransactionsInput,
  saleUsedForForecast,
  setupChartLines,
} from '../../../../../../../lib/charts/saleChartsLib';
import SaleChart from '../../../../../../../components/global/SaleChart/SaleChart';
import { computeTooltipPosition } from '../../../../../../../lib/charts/saleChartsLibUI';
import isEqual from 'lodash/isEqual';
import { SkuSaleChartTooltipGenerator } from './SkuSaleChartLib';
import SaleChartTooltip from '../../../../../../../components/global/SalesChartTooltip/SaleChartTooltip';
import {
  TooltipContentSection,
  TooltipPosition,
} from '../../../../../../../components/global/SalesChartTooltip/saleChartTooltipTypes';
import { Alert } from 'antd';

interface SkuSaleChartProps {
  rawData: SaleMonthlyOverviewResponse[];
  applicationDate: Date;
  cumulativeMode: boolean;
  vat?: number;
  width?: number;
  height?: number;
}

const tooltipId = 'SkuSalesChartTooltipWrapper';

const SkuSaleChart: React.FC<SkuSaleChartProps> = ({
  rawData,
  applicationDate,
  width,
  height,
  cumulativeMode,
  vat = 0,
}) => {
  const tmpSaleTypes = useMemo(
    () => addSalesUsedForForecastToSaleTypes(saleUsedForForecast, getSaleLineDefinitions()),
    [],
  );

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- can be expensive calculation
  const hasInfiniteForecast = useMemo(
    () => !!rawData.find(d => d.overviews.find(o => o.value >= 1000000)),
    [rawData],
  );

  const forecastTypes: TLineType[] = useMemo(
    () =>
      getForecastLineDefinitions(
        hasInfiniteForecast
          ? ['ForecastMax', 'ForecastMax50', 'ForecastMax75', 'ForecastMax80']
          : [],
      ),
    [hasInfiniteForecast],
  );

  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    visible: false,
    left: 0,
    top: 0,
    right: 0,
  });
  const [tooltipMonthlyData, setTooltipMonthlyData] = useState<TParsedTransactions>();
  const [tooltipData, setTooltipData] = useState<TooltipContentSection[]>([]);

  const parsedTransactions: TParsedTransactions[] = useMemo(
    () =>
      copyLineTypeToParsedMonthlyTransactionsByAnotherLineType(
        parseTransactions({
          type: 'monthly',
          transactions: rawData,
          lineTypes: tmpSaleTypes.concat(forecastTypes),
          dateToStartCalculatingCumulativeValues: applicationDate,
        }),
        saleUsedForForecast,
        'SaleSellout',
      ),
    [applicationDate, forecastTypes, rawData, tmpSaleTypes],
  );

  const chartLines = useMemo(
    () =>
      setupChartLines(
        tmpSaleTypes.concat(forecastTypes),
        parsedTransactions,
        cumulativeMode,
        applicationDate,
        'Quantity',
      ),
    [applicationDate, cumulativeMode, forecastTypes, parsedTransactions, tmpSaleTypes],
  );

  /** tmpSaleTypesWithLineTypes */
  const saleTypes: TLineType[] = useMemo(
    () =>
      changeFillInLineTypesBasedOnHiddenLines(hideNullLineTypes(tmpSaleTypes, parsedTransactions)),
    [parsedTransactions, tmpSaleTypes],
  );

  const chartData: ChartData<'line'> = useMemo(
    () =>
      createChartDataFromDatasetsAndParsedMonthlyTransactions(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needs to be done, mixing line and bar charts is apparently not so easy
        createDatasetsFromLineTypesAndGraphLines(
          saleTypes.concat(forecastTypes),
          chartLines,
        ) as ChartDataset<'line'>[],
        parsedTransactions,
      ),
    [chartLines, parsedTransactions, saleTypes, forecastTypes],
  );

  const lines = useMemo(() => saleTypes.concat(forecastTypes), [forecastTypes, saleTypes]);

  const skuSaleChartTooltip = useCallback(
    (
      tooltipModel: TooltipModel<'line'>,
      lines: TLineType[],
      datasets: ChartDataset[],
      parsedTransactions: TParsedTransactions[],
      applicationDate: Date,
      chartRef: React.RefObject<Chart<'line'>>,
      currency: string,
      vat: number,
      valueColumnType: SkuSaleGraphValueColumnType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not used in this component
      chartType: ParseTransactionsInput['type'] = 'monthly',
      nonSaleDataByDay?: NonSaleDataByDay,
      // eslint-disable-next-line max-params -- we need all these parameters
    ): Date | undefined => {
      let result: Date | undefined = undefined;
      let isFuture = false;

      const dataPoint = tooltipModel.dataPoints[0];

      const monthIndex: number = dataPoint.dataIndex || 0;
      const monthlyData = parsedTransactions[monthIndex];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- eslint thinks that monthlyData can NOT be undefined (but it can)
      if (monthlyData) {
        isFuture = monthlyData.dates.dateTo > applicationDate;
        result = monthlyData.dates.dateFrom;
      }

      const newPosition = computeTooltipPosition(chartRef, tooltipModel, `[id="${tooltipId}"]`);

      if (!isEqual(tooltipPosition, newPosition)) {
        if (newPosition.visible) {
          setTooltipMonthlyData(monthlyData);

          const tooltipGenerator = new SkuSaleChartTooltipGenerator();
          const tooltipData = tooltipGenerator.generateTooltipData(
            lines,
            monthlyData,
            Units.QUANTITY,
            currency,
            isFuture,
            vat,
            LINE_TYPES,
            lineTypeTooltipConfig,
            valueColumnType === 'avg',
            nonSaleDataByDay,
          );
          setTooltipData(tooltipData);
        }
        setTooltipPosition(newPosition);
      }

      return result;
    },
    [tooltipPosition],
  );

  return (
    <>
      {hasInfiniteForecast && (
        <Alert
          message="Notice: High Max Forecast Set for Precaution"
          description="Forecast accounts for seasonal unpredictability"
          type="warning"
          showIcon
        />
      )}
      <SaleChart
        chartData={chartData}
        width={width}
        height={height}
        currentDate={applicationDate}
        transactions={parsedTransactions}
        lines={lines}
        animate={true}
        vat={vat}
        valueColumnType="avg"
        tooltipCallback={skuSaleChartTooltip}
      />
      {tooltipMonthlyData && (
        <SaleChartTooltip
          data={tooltipData}
          position={tooltipPosition}
          rootId={tooltipId}
          currentDate={applicationDate}
          dateFrom={tooltipMonthlyData.dates.dateFrom}
          dateTo={tooltipMonthlyData.dates.dateTo}
          wide
        />
      )}
    </>
  );
};

export default SkuSaleChart;
