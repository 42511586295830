import BalancingField from './BalancingFieldTable';
import { CalculationConfigurationExperimentalType } from '@ydistri/api-sdk';
import { balancingGroupV100, BalancingGroupV100, emptyBalancingObjectV100 } from './balancingV100';
import { balancingGroupV200, BalancingGroupV200, emptyBalancingObjectV200 } from './balancingV200';
import {
  balancingGroupVUnknown,
  BalancingGroupVUnknown,
  emptyBalancingObjectVUnknown,
} from './balancingVUnknown';
import React from 'react';

export type BalancingFieldType = 'Boolean' | 'Int' | 'Decimal';

export interface BalancingFieldRowInfo {
  type: BalancingFieldType;
  itemSequence: number;
  value: number;
  customSuffix?: React.ReactNode;
  min?: number;
  max?: number;
  replaceWith?: string;
}

export interface BalancingFieldRow {
  index: number;
  data: Record<string, BalancingFieldRowInfo | undefined>;
}

export type BalancingGroupKey = BalancingGroupV100 | BalancingGroupV200 | BalancingGroupVUnknown;

export interface BalancingGroupObject {
  id: BalancingGroupKey;
  header: string;
  display: boolean;
  isMainGroup?: boolean;
  enableSetting?: CalculationConfigurationExperimentalType;
}

export enum BalancingVersions {
  V100 = '1.0.0',
  V200 = '2.0.0',
  V_UNKNOWN = 'unknown',
}

export interface BalancingField {
  groupId: BalancingGroupKey;
  fieldKey: CalculationConfigurationExperimentalType;
  label?: string;
  description?: string;
  groupedWith?: CalculationConfigurationExperimentalType[];
  columnLabel?: string;
  alwaysDisabled?: boolean;
  values: number[];
  type: BalancingFieldType;
  customSuffix?: React.ReactNode;
  min?: number;
  max?: number;
  lastValuePlaceholder?: string;
}

export type BalancingObject = Partial<
  Record<CalculationConfigurationExperimentalType, BalancingField>
>;

export type BalancingGroupType = Partial<Record<BalancingGroupKey, BalancingGroupObject>>;

export interface BalancingConfiguration {
  version: BalancingVersions;
  configuration: BalancingObject;
}

export const versionedBalancingGroups: Record<BalancingVersions, BalancingGroupType> = {
  [BalancingVersions.V100]: balancingGroupV100,
  [BalancingVersions.V200]: balancingGroupV200,
  [BalancingVersions.V_UNKNOWN]: balancingGroupVUnknown,
} as const;

export const versionedEmptyBalancingObjects: Record<BalancingVersions, BalancingObject> = {
  [BalancingVersions.V100]: emptyBalancingObjectV100,
  [BalancingVersions.V200]: emptyBalancingObjectV200,
  [BalancingVersions.V_UNKNOWN]: emptyBalancingObjectVUnknown,
} as const;
