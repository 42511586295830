import { ProductResponse } from '@ydistri/api-sdk';
import React, { PropsWithChildren, useMemo } from 'react';
import ProductTooltipContent from './ProductTooltipContent';
import { Tooltip } from 'antd';

interface ProductTooltipProps extends PropsWithChildren {
  product: ProductResponse;
}

const overlayStyle = { root: { minWidth: '35rem' } };

const ProductTooltip: React.FC<ProductTooltipProps> = ({ product, children }) => {
  const title = useMemo(() => <ProductTooltipContent product={product} />, [product]);

  return (
    <Tooltip title={title} placement="right" styles={overlayStyle}>
      {children}
    </Tooltip>
  );
};

export default ProductTooltip;
