import { CalculationImportResponse } from '@ydistri/api-sdk';
import React, { useCallback, useMemo, useState } from 'react';
import { Result } from 'antd';
import { useUser } from '../../../../../../../../hooks/useUser';
import { format } from 'date-fns';
import { ActionsBar, Button, GenericConfirmationModal } from '@ydistri/ds';
import { useDeleteCalculationImportMutation } from '../../../apiCalculationRedistributionImport';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import { addToast } from '@ydistri/utils';
import { ProcessingStep } from '../../../redistributionImportTypes';
import {
  setImportStep,
  setImportVerificationTimeStamp,
} from '../../../../../../../Calculations/calculationsSlice';
import { useAppDispatch } from '../../../../../../../../store';

interface RedistributionPendingImportProps {
  calculationImport: CalculationImportResponse;
}

const RedistributionPendingImport: React.FC<RedistributionPendingImportProps> = ({
  calculationImport,
}) => {
  const dispatch = useAppDispatch();
  const calculation = useTemplateOrCalculation();
  const user = useUser();
  const [deleteCalculationImport] = useDeleteCalculationImportMutation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we do not want to recalculate this on every render
  const subTitle = useMemo(() => {
    const createdDateText = format(new Date(calculationImport.createdAt), 'd. M. yyyy HH:mm');

    let userInfo = calculationImport.user.fullName;
    if (calculationImport.user.id === user?.id) {
      userInfo = 'You';
    }

    return `Created by ${userInfo} at ${createdDateText}`;
  }, [
    calculationImport.createdAt,
    calculationImport.user.fullName,
    calculationImport.user.id,
    user?.id,
  ]);

  const handleConfirmStartOver = useCallback(() => {
    deleteCalculationImport({
      calculationId: calculation.id,
      verificationTimeStamp: calculationImport.verificationTimeStamp,
    })
      .unwrap()
      .catch(() => {
        dispatch(addToast({ message: 'There was an error deleting the import', isError: true }));
      });
  }, [calculation.id, calculationImport.verificationTimeStamp, deleteCalculationImport, dispatch]);

  const handleStartOver = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const handleContinue = useCallback(() => {
    dispatch(setImportVerificationTimeStamp(calculationImport.verificationTimeStamp));
    dispatch(setImportStep({ stepIndex: ProcessingStep.VALIDATION, stepRunning: false }));
  }, [calculationImport.verificationTimeStamp, dispatch]);

  const handleCancelStartOver = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  return (
    <>
      <Result
        status="warning"
        title="Picking Positions Import is currently in progress"
        subTitle={subTitle}
        extra={
          <ActionsBar>
            <Button onClick={handleContinue}>Continue with import</Button>
            <Button onClick={handleStartOver} $colorVariant="ghostPrimary">
              Start over...
            </Button>
          </ActionsBar>
        }
      />
      <GenericConfirmationModal
        open={showConfirmation}
        title="Remove import in progress"
        message="Current import items will be permanently removed. Continue?"
        onConfirmed={handleConfirmStartOver}
        onCanceled={handleCancelStartOver}
        okText="Yes"
        cancelText="No"
      />
    </>
  );
};

export default RedistributionPendingImport;
