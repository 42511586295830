import React, { PropsWithChildren, useMemo } from 'react';
import { ConfigurationRuleSetupType } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { Popover } from 'antd';
import { Column, Text } from '@ydistri/ds';

interface ScopeConfigurationFieldPopoverProps extends PropsWithChildren {
  scopeId: number;
  field: ConfigurationRuleSetupType;
}

const ScopeConfigurationFieldPopover: React.FC<ScopeConfigurationFieldPopoverProps> = ({
  scopeId,
  field,
  children,
}) => {
  const fieldOverwrites = useSelector((state: ReduxState) => {
    const configuration = state.scope.exceptionOverlapData.overlap[scopeId]?.configurations[field];
    if (configuration) {
      return configuration.array.map(eid => {
        return state.scope.exceptionOverlapData.scopeList.object[eid]?.name ?? `Exception ${eid}`;
      });
    } else {
      return [];
    }
  });

  const popoverContent = useMemo(() => {
    return (
      <Column>
        <Text>This setting is partially overwritten in these exceptions:</Text>
        <ul>
          {fieldOverwrites.map(eid => (
            <li key={eid}>{eid}</li>
          ))}
        </ul>
      </Column>
    );
  }, [fieldOverwrites]);

  if (fieldOverwrites.length === 0) {
    return children;
  } else {
    return (
      <Popover content={popoverContent}>
        <div>{children}</div>
      </Popover>
    );
  }
};

export default ScopeConfigurationFieldPopover;
