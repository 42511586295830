import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { Panel } from '@ydistri/ds';

interface ScrolledTableWrapperProps extends PropsWithChildren {
  offset?: number;
  minHeight?: number;
}

const ScrolledTableWrapper: React.FC<ScrolledTableWrapperProps> = ({
  offset = 0,
  children,
  minHeight = 500,
}): React.JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(minHeight);

  useEffect(() => {
    if (ref.current) {
      //initial height
      const computedHeight = ref.current.clientHeight - offset;
      // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- we are setting a listener here and store the height in state
      setTableHeight(Math.max(computedHeight, minHeight));
      //compute height on window resize
      window.addEventListener('resize', () => {
        const computedHeight = (ref.current?.clientHeight ?? 60) - offset;
        // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- we are setting a listener here and store the height in state
        setTableHeight(Math.max(computedHeight, minHeight));
      });
    }
  }, [minHeight, offset, ref]);

  const childrenWithHeightProp = useMemo(() => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        // @ts-expect-error height prop is not defined in ReactElement type
        return React.cloneElement(child, { height: `${tableHeight}px` });
      }
      return child;
    });
  }, [children, tableHeight]);

  return (
    <Panel ref={ref} $noAutoHeight={true} $grow={1}>
      {childrenWithHeightProp}
    </Panel>
  );
};

export default ScrolledTableWrapper;
