import React from 'react';
import { Popover } from 'antd';
import { formatNumber, shortenNumber } from '@ydistri/utils';
import { Column, Text, Title } from '@ydistri/ds';
import { useCurrency } from '../../../../hooks/useCurrency';

interface TitleWithNumericPopoverProps {
  value: number;
  appendCurrency?: boolean;
  text?: string;
}

const TitleWithNumericPopover: React.FC<TitleWithNumericPopoverProps> = ({
  value,
  appendCurrency = false,
  text = '',
}) => {
  const currency = useCurrency();

  const displayedCurrency = appendCurrency ? ` ${currency}` : '';

  return (
    <Column>
      <Popover placement="topLeft" content={`${formatNumber(value)}${displayedCurrency}`}>
        <Title $size="xLarge">
          {shortenNumber(value)}
          {displayedCurrency}
        </Title>
      </Popover>
      <Text $size="large">{text}</Text>
    </Column>
  );
};

export default TitleWithNumericPopover;
