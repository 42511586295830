import React, { useCallback, useMemo, useState } from 'react';
import { ActionsBar, Section } from '@ydistri/ds';
import ShowArchivedItemsToggle from '../../common/ShowArchivedItemsToggle';
import { useSelector } from 'react-redux';
import {
  setSelectedTargetList,
  setShowArchivedItems,
  setSortField,
} from '../targetListAdministrationSlice';
import {
  getArchivedItemsCount,
  getArchivedStateSorter,
  lastUpdatedAtSorter,
  nameSorter,
  onlyActive,
} from '../../common/administrationItemsLib';
import { defaultTargetListParams, useGetTargetListsQuery } from '../apiTargetLists';
import { addToast } from '@ydistri/utils';
import TargetListsList from './TargetListsList';
import CreateIconButton from '../../../../components/buttons/CreateIconButton';
import TargetListModal from '../modals/TargetListModal';
import { ReduxState, useAppDispatch } from '../../../../store';
import SortSelector from '../../common/SortSelector';

const TargetListAdministrationCatalog: React.FC = () => {
  const dispatch = useAppDispatch();

  const showArchivedItems = useSelector(
    (state: ReduxState) => state.targetListAdministration.showArchivedItems,
  );
  const sortField = useSelector((state: ReduxState) => state.targetListAdministration.sortField);
  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );
  const { data: targetLists, isFetching } = useGetTargetListsQuery(defaultTargetListParams, {
    refetchOnMountOrArgChange: false,
  });

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  //Archived target lists are not displayed by default
  //If they are, they are listed after the active ones and sorted by name/recency
  const displayedData = useMemo(() => {
    if (targetLists) {
      const sorter = sortField === 'LastUpdatedAt' ? lastUpdatedAtSorter : nameSorter;
      let result;

      if (!showArchivedItems) {
        result = onlyActive(targetLists).sort(sorter);
      } else {
        result = Array.from(targetLists).sort(getArchivedStateSorter(sorter));
      }

      return result;
    }
  }, [sortField, targetLists, showArchivedItems]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we compute the value by processing the list, let's cache it
  const archivedItemsCount = useMemo(
    () => (!targetLists ? 0 : getArchivedItemsCount(targetLists)),
    [targetLists],
  );

  const onCreateTargetListClicked = useCallback(() => {
    setModalOpened(previousModalOpened => !previousModalOpened);
  }, []);

  /**
   * Shows or hides archived items
   */
  const toggleShowArchived = useCallback(() => {
    const newShowArchivedItems = !showArchivedItems;
    dispatch(setShowArchivedItems(newShowArchivedItems));
    if (newShowArchivedItems) {
      dispatch(
        addToast({
          message: `Showing archived Target lists`,
          description: 'Archived Target lists are shown below the active ones',
        }),
      );
    }

    if (selectedTargetList?.isArchived) {
      dispatch(setSelectedTargetList(undefined));
    }
  }, [dispatch, selectedTargetList, showArchivedItems]);

  const onCreateTargetListModalClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  const onSortChange = useCallback(
    (v: string) => {
      dispatch(setSortField(v));
    },
    [dispatch],
  );

  const contentActions = useMemo(() => {
    return (
      <ActionsBar>
        <ShowArchivedItemsToggle
          archivedItemsCount={archivedItemsCount}
          checked={showArchivedItems}
          onClick={toggleShowArchived}
        />
        <SortSelector onSortChange={onSortChange} defaultValue={sortField} />
      </ActionsBar>
    );
  }, [archivedItemsCount, showArchivedItems, toggleShowArchived, onSortChange, sortField]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <CreateIconButton
          size="small"
          tooltip="Create Target List"
          onClick={onCreateTargetListClicked}
          data-testid="create_new_target_list"
        />
      </ActionsBar>
    );
  }, [onCreateTargetListClicked]);

  return (
    <>
      <Section
        header="Target lists"
        $scrollableContent={true}
        headerActions={headerActions}
        contentActions={contentActions}
        $ratio={0}
        $shrink={0}
        data-testid="TargetListsSection"
        $width="25rem"
      >
        <TargetListsList
          data={displayedData}
          selectedItem={selectedTargetList}
          loading={isFetching}
        />
      </Section>

      {modalOpened && (
        <TargetListModal onClose={onCreateTargetListModalClose} confirmButtonLabel="Create" />
      )}
    </>
  );
};

export default TargetListAdministrationCatalog;
