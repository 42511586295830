import { Scope, scopeEntityTitle } from './scopeLib';
import {
  iterableConfigurationRuleScopeEntityType,
  iterableConfigurationRuleSetupType,
} from '../../../helpers/types/iterableEnums';
import { ConfigurationRuleScopeMode, ConfigurationRuleSetupType } from '@ydistri/api-sdk';
import { plural } from '@ydistri/utils';

export const generateScopeTitle = (
  data: Scope,
  useClosing: boolean,
  useSuperTarget: boolean,
): string => {
  let part1 = '';

  const entityTypes = iterableConfigurationRuleScopeEntityType
    .map(et => {
      const { selectedIds, selectionOption } = data.entities[et];
      if (selectedIds.length > 0) {
        const cnt = `${selectedIds.length} ${plural(
          selectedIds.length,
          scopeEntityTitle[et].toLowerCase(),
        )}`;
        if (selectionOption === ConfigurationRuleScopeMode.AllExcept) {
          return `excluded ${cnt}`;
        } else if (selectionOption === ConfigurationRuleScopeMode.Specified) {
          return cnt;
        }
      }

      return '';
    })
    .filter(x => x !== '');

  if (entityTypes.length > 1) {
    const last = entityTypes.pop();
    part1 = `${entityTypes.join(', ')} and ${last}`;
  } else if (entityTypes.length === 1) {
    part1 = entityTypes[0];
  }

  const configurationParts = iterableConfigurationRuleSetupType
    .map(c => {
      const conf = data.configuration[c];
      if (conf === null) return null;
      if (!useClosing && c === ConfigurationRuleSetupType.IsClosing) return null;
      if (!useSuperTarget && c === ConfigurationRuleSetupType.IsSuperTarget) return null;

      switch (c) {
        case ConfigurationRuleSetupType.IsClosing:
          return conf ? `closing` : `not closing`;
        case ConfigurationRuleSetupType.IsEnabled:
          return conf ? `enabled` : `disabled`;
        case ConfigurationRuleSetupType.UseAsSource:
          return conf ? `used as source` : `not used as source`;
        case ConfigurationRuleSetupType.UseAsTarget:
          return conf ? `used as target` : `not used as target`;
        case ConfigurationRuleSetupType.MaxNumberOfSourceStores:
          return conf ? `max. ${conf} incoming routes` : `unlimited incoming routes`;
        case ConfigurationRuleSetupType.MaxNumberOfTargetStores:
          return conf ? `max. ${conf} outgoing routes` : `unlimited outgoing routes`;
        case ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions:
          return conf
            ? `max. ${conf} outgoing picking positions`
            : `unlimited outgoing picking positions`;
        case ConfigurationRuleSetupType.MinimumRouteValue:
          return conf ? `min. ${conf} route value` : `unlimited route value`;
        case ConfigurationRuleSetupType.IsSuperTarget:
          return conf ? `super target` : `not super target`;
      }
    })
    .filter(c => !!c)
    .join(', ');

  if (part1 === '') {
    return configurationParts === '' ? `Exception ${data.id}` : configurationParts;
  }
  if (configurationParts === '') {
    return part1;
  }
  return `${part1} - ${configurationParts}`;
};
