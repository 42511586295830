import React, { useCallback } from 'react';
import { Button } from '@ydistri/ds';

interface SubPageButtonProps {
  id: string;
  label: string;
  selected?: boolean;
  onClick?: (id: string) => void;
}

/**
 * Button used to switch between subpages or parts of a page
 * @param id
 * @param label
 * @param onClick
 * @param selected
 * @constructor
 */
export const SubPageButton: React.FC<SubPageButtonProps> = ({
  id,
  label,
  onClick,
  selected = false,
}) => {
  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick(id);
    }
  }, [id, onClick]);

  return (
    <Button
      $colorVariant={selected ? 'selected' : 'ghost'}
      $textVariant="semibold"
      onClick={clickHandler}
      data-type="drill-down-sku-type-button"
    >
      {label}
    </Button>
  );
};
