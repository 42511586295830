import { apiSlice, TemplateOrCalculation } from '../../../apis/api';
import { CalculationsCollection, TemplatesCollection } from '../../../swagger/collections';
import { getTags } from '../../../apis/apiLib';
import {
  BalancingConfiguration,
  BalancingObject,
  BalancingVersions,
  versionedEmptyBalancingObjects,
} from './balancingLib';
import cloneDeep from 'lodash.clonedeep';
import {
  CalculationConfigurationExperimentalType,
  CalculationExperimentalConfigurationRequest,
  CalculationExperimentalConfigurationResponse,
} from '@ydistri/api-sdk';

const { TAGS, TAGS_ARRAY } = getTags('balancingConfiguration');

export interface PatchBalancingConfigurationRequest {
  templateOrCalculation: TemplateOrCalculation;
  data: CalculationExperimentalConfigurationRequest;
}

export const apiBalancingConfiguration = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getBalancingConfiguration: builder.query<BalancingConfiguration, TemplateOrCalculation>({
        queryFn: async arg => {
          const { data } =
            arg.type === 'Template'
              ? await TemplatesCollection.getTemplateExperimentalConfigurations(arg.id)
              : await CalculationsCollection.getCalculationExperimentalConfigurations(arg.id);

          // eslint-disable-next-line no-console -- we want to log the data
          console.log('getBalancingConfiguration DATA', data);

          const versionString = data.data.find(
            d =>
              d.key === CalculationConfigurationExperimentalType.ExperimentalConfigurationVersion,
          )?.value;

          let version = BalancingVersions.V_UNKNOWN;

          switch (versionString) {
            case BalancingVersions.V100.toString():
              version = BalancingVersions.V100;
              break;
            case BalancingVersions.V200.toString():
              version = BalancingVersions.V200;
              break;
            case undefined:
              break;
          }

          const emptyAdvancedConfigObject = versionedEmptyBalancingObjects[version];
          const result: BalancingObject = cloneDeep(emptyAdvancedConfigObject);
          data.data
            .sort((x, y) => x.itemSequence - y.itemSequence)
            .forEach(d =>
              result[d.key]?.values.push(
                result[d.key]?.type === 'Decimal' ? parseFloat(d.value) : parseInt(d.value),
              ),
            );
          // eslint-disable-next-line no-console -- we want to log the result
          console.log('RESULT', result);

          return { data: { version, configuration: result } };
        },
        providesTags: (result, error, arg) => [
          { type: TAGS.balancingConfiguration, id: `balancingConfig-${arg.type}-${arg.id}` },
        ],
      }),

      patchBalancingConfiguration: builder.mutation<
        CalculationExperimentalConfigurationResponse,
        PatchBalancingConfigurationRequest
      >({
        queryFn: async ({ templateOrCalculation, data }) => {
          const response = await TemplatesCollection.patchTemplateExperimentalConfigurations(
            templateOrCalculation.id,
            [data],
          );
          return { data: response.data.data[0] };
        },
        onQueryStarted({ templateOrCalculation, data }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiBalancingConfiguration.util.updateQueryData(
              'getBalancingConfiguration',
              templateOrCalculation,
              draft => {
                const key = data.key;
                if (
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- they may be undefined
                  key !== undefined &&
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- they may be undefined
                  data.value !== undefined &&
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- they may be undefined
                  data.itemSequence !== undefined
                ) {
                  const draftObj = draft.configuration[key];
                  if (draftObj) {
                    draftObj.values[data.itemSequence] =
                      draft.configuration[key]?.type === 'Decimal'
                        ? parseFloat(data.value)
                        : parseInt(data.value);
                  }
                }
              },
            ),
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),
    }),
  });

export const { useGetBalancingConfigurationQuery, usePatchBalancingConfigurationMutation } =
  apiBalancingConfiguration;
