import { Column, Section } from '@ydistri/ds';
import { styled } from 'styled-components';
import React, { useCallback } from 'react';
import useCategoryId from '../../../../hooks/useCategoryId';
import ProductsList from './components/ProductsList';
import { ProductResponse } from '@ydistri/api-sdk';
import SelectedProductDetails from './components/SelectedProductDetails';
import SearchPanel from './components/SearchPanel';
import { useGetSingleProductQuery } from '../../apiDetail';
import { useDetail } from '../../hooks/useDetail';

const Content = styled(Column)`
  width: 25rem;
`;

interface DetailProductsListSectionProps {
  onProductSearch: (query: string) => void;
  onSkuSearch: (skuId: number) => void;
  onProductSelected: (product: ProductResponse | null) => void;
  searchQuery?: string;
}

/**
 * Displays a list of products in the selected category.
 * <p>Allows to search the product and when a number is entered, it will search for the sku with that number.</p>
 * @constructor
 */
const DetailProductsListSection: React.FC<DetailProductsListSectionProps> = ({
  onProductSearch,
  onSkuSearch,
  onProductSelected,
  searchQuery,
}) => {
  const { productId } = useDetail('productId');
  const { data: productEntity } = useGetSingleProductQuery(productId);
  const categoryId = useCategoryId();

  const deselectProduct = useCallback(() => {
    onProductSelected(null);
  }, [onProductSelected]);

  return (
    <Section header="Products in selected category" $ratio={0}>
      <Content $flexGrow={1} $flexWrap="nowrap" $gap="1rem">
        <SearchPanel value={searchQuery} onSearch={onProductSearch} onSkuSearch={onSkuSearch} />
        {productEntity && (
          <SelectedProductDetails product={productEntity} onProductDeselected={deselectProduct} />
        )}
        <ProductsList
          categoryId={categoryId}
          searchQuery={searchQuery}
          onProductSelected={onProductSelected}
          selectedProductId={productEntity?.id}
        />
      </Content>
    </Section>
  );
};

export default DetailProductsListSection;
