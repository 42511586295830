import { useSelector } from 'react-redux';
import { ReduxState } from '../store';
import { useMemo } from 'react';

const useCategoryId = (): number => {
  const selectedCategorySlug = useSelector(
    (state: ReduxState) => state.categoryTreeReducer.currentlySelectedCategorySlug,
  );

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we do not want to recalculate on every render
  return useMemo(
    () => parseInt(selectedCategorySlug.split('-').pop() ?? '1'),
    [selectedCategorySlug],
  );
};

export default useCategoryId;
