import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Checkbox, Form, Input, InputRef, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Text } from '@ydistri/ds';
import { useSelectedTemplate } from '../../../hooks/useSelectedTemplate';
import { useCreateCalculationMutation } from '../../Calculations/apiCalculations';
import { generatePath, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../../components/menu/menuLeftItemTemplate';
import { createDebugLog } from '../../../lib/utils/logging';
import { useUser } from '../../../hooks/useUser';
import { CalculationMode, CalculationRequest, CalculationType } from '@ydistri/api-sdk';
import ValidateStoreDepartmentConfiguration from '../../Configuration/ConfigurationStoresAndDepartments/ValidateStoreDepartmentConfiguration';
import { ErrorType } from '../../../apis/api';
import { addToast } from '@ydistri/utils';
import { useSelector } from 'react-redux';
import OverlappingCalculationForm, { OVERLAP_TABLE_ID } from './OverlappingCalculationForm';
import { ReduxState, useAppDispatch } from '../../../store';
import CalculationModeSelect from './CalculationModeSelect';
import MergedCalculationTemplateSelect, {
  MergedCalculationData,
} from './TemplateMultiselectAndSort/MergedCalculationTemplateSelect';
import { TemplateSelect } from '../../Configuration/TemplateSelect';
import ManualCalculationSelect from '../../Calculations/common/ManualCalculationSelect';
import { CalculationDataType, CalculationInfo } from '../../Calculations/calculationsTypes';
import { resetSelectedRowKeys } from '../../Calculations/calculationsSlice';

const debugLog = createDebugLog('Configuration', 'CreateCalculationModal');

interface CreateCalculationModalProps {
  open: boolean;
  onCancel: () => void;
  displayTemplateSelect?: boolean;
  initialValues?: FormFields;
}

export interface FormFields {
  title: string;
  description: string;
  private: boolean;
  calculationMode: CalculationMode;
  manualCalculation?: CalculationInfo;
  mergedCalculationTemplates?: CalculationInfo[];
  overlappingCalculations?: CalculationDataType[];
}

const titleInputRules = [{ required: true, message: 'Please input the title of calculation' }];
const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const CreateCalculationModal: React.FC<CreateCalculationModalProps> = ({
  open,
  onCancel,
  displayTemplateSelect = false,
  initialValues,
}) => {
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const selectedTemplate = useSelectedTemplate();
  const dispatch = useAppDispatch();

  const { projectShortName } = useParams();
  const [form] = Form.useForm<FormFields>();
  const [createCalculation, createCalculationStatus] = useCreateCalculationMutation();
  const user = useUser();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [mergedCalculationData, setMergedCalculationData] = useState<MergedCalculationData>();
  const [calculationMode, setCalculationMode] = useState<CalculationMode>(
    initialValues?.calculationMode ?? CalculationMode.Computed,
  );
  const [templateId, setTemplateId] = React.useState<number | undefined>(
    displayTemplateSelect ? undefined : (selectedTemplate?.id ?? 0),
  );
  const [manualCalculation, setManualCalculation] = useState<CalculationInfo | undefined>(
    initialValues?.manualCalculation,
  );

  const selectedOverlappingCalculations = useSelector(
    (state: ReduxState) => state.calculations.selectedRowKeys[OVERLAP_TABLE_ID]?.arr,
  );

  useEffect(() => {
    if (!manualCalculation && initialValues?.manualCalculation) {
      setManualCalculation(initialValues.manualCalculation);
    }
  }, [initialValues, manualCalculation]);

  const voidClickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    switch (calculationMode) {
      case CalculationMode.Merged: {
        let disableButton = false;
        const templateIds = mergedCalculationData?.sortedTemplateIds ?? [];
        if (
          !manualCalculation &&
          (templateIds.length === 0 ||
            templateIds.find(tid => mergedCalculationData?.calculationTitles[tid] === ''))
        ) {
          disableButton = true;
        }
        setButtonDisabled(disableButton);
        break;
      }
      case CalculationMode.Manual: {
        setButtonDisabled(false);
        break;
      }
      case CalculationMode.Computed: {
        if (displayTemplateSelect) {
          setButtonDisabled(!templateId);
        }
      }
    }
  }, [
    calculationMode,
    displayTemplateSelect,
    manualCalculation,
    mergedCalculationData?.calculationTitles,
    mergedCalculationData?.sortedTemplateIds,
    templateId,
  ]);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const request: CalculationRequest = {
        title: values.title,
        description: values.description,
        calculationMode,
        calculationType: values.private ? CalculationType.Private : CalculationType.Simulation,
      };

      if (calculationMode === CalculationMode.Merged) {
        request.overlappingCalculations = selectedOverlappingCalculations ?? [];

        const subOrdinateCalculations = [];

        //if manual calculation is selected, it must be the first one in subOrdinateCalculations
        if (manualCalculation) {
          subOrdinateCalculations.push({
            originalCalculationId: manualCalculation.id,
            title: manualCalculation.title,
            calculationMode: CalculationMode.Manual,
            calculationType: CalculationType.Submerged,
          });
        }

        //if templates are selected, they must be added to subOrdinateCalculations after manual calculation
        if (mergedCalculationData) {
          const mergedSubordinateCalculations = mergedCalculationData.sortedTemplateIds.map(
            (tid, i) => ({
              templateId: tid,
              title: `[${i + 1}] ${mergedCalculationData.calculationTitles[tid] ?? ''}`,
              calculationMode: CalculationMode.Computed,
              calculationType: CalculationType.Submerged,
            }),
          );
          subOrdinateCalculations.push(...mergedSubordinateCalculations);

          request.subordinateCalculations = subOrdinateCalculations;
        }
      } else if (calculationMode === CalculationMode.Computed) {
        request.overlappingCalculations = selectedOverlappingCalculations ?? [];
        request.templateId = templateId;
      }

      createCalculation(request)
        .unwrap()
        .then(() => {
          form.resetFields();
          const path = generatePath(ROUTES.calculations, { projectShortName });
          debugLog('Should redirect to path:', path);
          navigate(path);
          onCancel();
        })
        .catch((error: unknown) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it's an ErrorType
          const err = error as ErrorType;
          dispatch(
            addToast({
              message: `Calculation was not created: ${err.response.data.Messages.join(', ')}`,
              isError: true,
            }),
          );
        });
    });
  }, [
    form,
    calculationMode,
    createCalculation,
    selectedOverlappingCalculations,
    manualCalculation,
    mergedCalculationData,
    templateId,
    projectShortName,
    navigate,
    onCancel,
    dispatch,
  ]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    debugLog('useEffect', inputRef.current);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  //prevent opening the calculation when used in context menu
  const onModalCancel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onCancel();

      //clear overlapping selections
      dispatch(resetSelectedRowKeys(OVERLAP_TABLE_ID));
    },
    [dispatch, onCancel],
  );

  //prevent opening the calculation when used in context menu
  const onModalConfirm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  const templateDefaultValues = useMemo(() => {
    if (initialValues?.mergedCalculationTemplates) {
      const sortedTemplateIds = initialValues.mergedCalculationTemplates.map(t => t.id);
      const calculationTitles = initialValues.mergedCalculationTemplates.reduce(
        (acc, t) => ({ ...acc, [t.id]: t.title }),
        {},
      );
      return { sortedTemplateIds, calculationTitles };
    }
    return undefined;
  }, [initialValues]);

  return (
    <div onClick={voidClickHandler}>
      <Modal
        data-type="modal"
        title="Create calculation"
        open={open}
        width={1000}
        okText="Create"
        okButtonProps={{
          disabled: buttonDisabled,
        }}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
        confirmLoading={createCalculationStatus.isLoading}
      >
        <Form
          data-type="create-calculation-form"
          form={form}
          labelCol={formLayout.labelCol}
          wrapperCol={formLayout.wrapperCol}
          name="create_calculation_modal"
          layout="horizontal"
          initialValues={initialValues}
        >
          <Form.Item name="title" label="Title" rules={titleInputRules}>
            <Input ref={inputRef} onKeyDown={handleKeyDown} />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea rows={4} />
          </Form.Item>
          {user?.isSuperUser && (
            <Form.Item label="Type" name="private" valuePropName="checked">
              <Checkbox>Private calculation</Checkbox>
            </Form.Item>
          )}

          <Form.Item label="Mode" name="calculationMode">
            <CalculationModeSelect
              value={calculationMode}
              onChange={setCalculationMode}
              includeManualCalculation={user?.isSuperUser}
            />
          </Form.Item>
          {calculationMode === CalculationMode.Computed && (
            <Form.Item label="Template">
              {displayTemplateSelect ? (
                <TemplateSelect onChange={setTemplateId} templateId={templateId} />
              ) : (
                <Text>
                  #{selectedTemplate?.id} {selectedTemplate?.title}
                </Text>
              )}
            </Form.Item>
          )}
          {calculationMode === CalculationMode.Merged && (
            <>
              <Form.Item label="Manual Calculation">
                <ManualCalculationSelect
                  onChange={setManualCalculation}
                  selectedCalculationId={manualCalculation?.id}
                />
              </Form.Item>
              <Form.Item label="Templates">
                <MergedCalculationTemplateSelect
                  onChange={setMergedCalculationData}
                  defaultValues={templateDefaultValues}
                />
              </Form.Item>
            </>
          )}

          {calculationMode !== CalculationMode.Manual && (
            <>
              <Form.Item name="overlappingCalculations" label="Overlapping">
                <OverlappingCalculationForm
                  initialValues={initialValues?.overlappingCalculations}
                />
              </Form.Item>
              {!displayTemplateSelect && (
                <ValidateStoreDepartmentConfiguration setDisabledButton={setButtonDisabled} />
              )}
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default CreateCalculationModal;
