import React, { useCallback, useMemo } from 'react';
import { Spin } from 'antd';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import {
  UpdateCalculationConfigurationPayload,
  useGetCalculationConfigurationQuery,
  useUpdateCalculationConfigurationMutation,
} from '../../../../apis/apiGlobalConfiguration';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import { styled } from 'styled-components';
import { Button, GenericConfirmationModal, RowCentered } from '@ydistri/ds';
import { useResetBrandConfigurationMutation } from '../apiBrands';

const ExceptionRow = styled(RowCentered)`
  justify-content: space-evenly;
  flex-grow: 1;
  flex-wrap: wrap;
`;

interface BrandTypeOfExceptionProps {
  hasException: boolean;
}

const BrandTypeOfException: React.FC<BrandTypeOfExceptionProps> = ({ hasException }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const { data: calculationConfiguration, isFetching } =
    useGetCalculationConfigurationQuery(templateOrCalculation);
  const allEnabled = calculationConfiguration?.brandsAllEnabled;

  const [updateConfiguration, updateConfigurationStatus] =
    useUpdateCalculationConfigurationMutation();
  const [resetConfiguration] = useResetBrandConfigurationMutation();

  const status = useMemo(() => {
    // eslint-disable-next-line no-console -- we want this output
    console.log('STATUS: ', isFetching, updateConfigurationStatus);

    return isFetching
      ? ConfigurationFieldStatus.LOADING
      : rtkQueryPatchStatusToConfigurationFieldStatus(updateConfigurationStatus);
  }, [updateConfigurationStatus, isFetching]);

  const onChangeHandler = useCallback(
    (value: boolean) => {
      const payload: UpdateCalculationConfigurationPayload = {
        templateOrCalculation,
        request: {
          brandsAllEnabled: value,
        },
      };
      resetConfiguration(templateOrCalculation);

      updateConfiguration(payload);
      setShowConfirmation(false);
    },
    [resetConfiguration, templateOrCalculation, updateConfiguration],
  );

  const setAllEnabled = useCallback(() => onChangeHandler(true), [onChangeHandler]);
  const setAllDisabled = useCallback(() => onChangeHandler(false), [onChangeHandler]);
  const toggleConfirmation = useCallback(() => setShowConfirmation(p => !p), []);

  const allEnabledHandler = useCallback(() => {
    if (allEnabled) return;
    if (hasException) {
      toggleConfirmation();
    } else {
      setAllEnabled();
    }
  }, [allEnabled, hasException, setAllEnabled, toggleConfirmation]);

  const allDisabledHandler = useCallback(() => {
    if (!allEnabled) return;
    if (hasException) {
      toggleConfirmation();
    } else {
      setAllDisabled();
    }
  }, [allEnabled, hasException, setAllDisabled, toggleConfirmation]);

  const allEnabledBorderVariant = useMemo(() => {
    if (templateOrCalculation.type === 'Template') {
      return 'none';
    }
    return allEnabled ? 'full' : 'transparent';
  }, [allEnabled, templateOrCalculation.type]);

  const allDisabledBorderVariant = useMemo(() => {
    if (templateOrCalculation.type === 'Template') {
      return 'none';
    }
    return allEnabled ? 'transparent' : 'full';
  }, [allEnabled, templateOrCalculation.type]);

  return (
    <ExceptionRow $gap="1rem">
      <Spin spinning={status === ConfigurationFieldStatus.LOADING}>
        <Button
          $colorVariant={allEnabled ? 'primary' : 'ghost'}
          $borderVariant={allEnabledBorderVariant}
          onClick={allEnabledHandler}
          $textVariant="semibold"
          disabled={templateOrCalculation.type === 'Calculation'}
        >
          All enabled, except...
        </Button>
      </Spin>
      <Spin spinning={status === ConfigurationFieldStatus.LOADING}>
        <Button
          $colorVariant={allEnabled ? 'ghost' : 'primary'}
          $borderVariant={allDisabledBorderVariant}
          onClick={allDisabledHandler}
          $textVariant="semibold"
          disabled={templateOrCalculation.type === 'Calculation'}
        >
          All disabled, except...
        </Button>
      </Spin>

      {showConfirmation && (
        <GenericConfirmationModal
          title="Brands"
          message={`You are about to change type of exception to “${
            allEnabled ? 'All disabled, except...' : 'All enabled, except...'
          }”.
All exceptions will be cleared.`}
          confirmationButtonLabel="Clear all"
          onConfirmed={allEnabled ? setAllDisabled : setAllEnabled}
          onCanceled={toggleConfirmation}
        />
      )}
    </ExceptionRow>
  );
};

export default BrandTypeOfException;
