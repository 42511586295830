import React from 'react';
import {
  CategoryConfigurationDefinitionResponse,
  ConfigurationDataType,
  ConfigurationFieldType,
} from '@ydistri/api-sdk';
import {
  categoryStringRoute,
  CategoryTreeNode,
  PartialException,
} from '../../../components/global/CategoryTree/apiCategoryTree';
import { Modal, SegmentedProps } from 'antd';
import { styled } from 'styled-components';
import ConfigurationRow from '../../Configuration/ConfigurationCategories/SegmentPotentialDefinition/ConfigurationRow';
import ConfigurationField from '../../../components/global/ConfigurationField/ConfigurationField';
import ConfigurationEntityLists from '../../Configuration/ConfigurationCategories/SegmentPotentialDefinition/ConfigurationEntityLists/ConfigurationEntityLists';

export const CATEGORY_EXCEPTIONS_MODAL_HEIGHT = 'calc(100vh - 10rem)';

export const StyledModal = styled(Modal)`
  top: 20px;
  height: ${CATEGORY_EXCEPTIONS_MODAL_HEIGHT};
`;

export enum ExceptionsModalDisplayType {
  FIELD = 'field',
  CATEGORY = 'category',
}

export const displayTypeOptions: SegmentedProps['options'] = [
  {
    label: 'Field',
    value: ExceptionsModalDisplayType.FIELD,
  },
  {
    label: 'Category',
    value: ExceptionsModalDisplayType.CATEGORY,
  },
];

export type PartialExceptionExpanded = PartialException & {
  category: CategoryTreeNode;
  titleFull: string;
};

export type ExceptionsBy<T extends ConfigurationFieldType | number> = Partial<
  Record<T, PartialExceptionExpanded[] | undefined>
>;

export const keyGetterCategoryId = (exc: PartialExceptionExpanded): number => exc.category.id;
export const keyGetterFieldType = (exc: PartialExceptionExpanded): ConfigurationFieldType =>
  ConfigurationFieldType.CatSlowMoverSales === exc.fieldType
    ? ConfigurationFieldType.CatSlowMoverMonths
    : exc.fieldType;

export const getExceptionsBy = <T extends ConfigurationFieldType | number>(
  exceptions: PartialExceptionExpanded[],
  keyGetter: (exc: PartialExceptionExpanded) => T,
  sorter: (a: PartialExceptionExpanded, b: PartialExceptionExpanded) => number,
): ExceptionsBy<T> => {
  const result: ExceptionsBy<T> = {};

  exceptions.sort(sorter).forEach(exc => {
    const key = keyGetter(exc);
    if (!result[key]) {
      result[key] = [];
    }
    if (
      !result[key].find(e => e.category.id === exc.category.id && e.fieldType === exc.fieldType)
    ) {
      //we want to "merge" CatSlowMoverMonths and CatSlowMoverSales
      let second: ConfigurationFieldType | undefined = undefined;
      if (exc.fieldType === ConfigurationFieldType.CatSlowMoverSales) {
        second = ConfigurationFieldType.CatSlowMoverMonths;
      } else if (exc.fieldType === ConfigurationFieldType.CatSlowMoverMonths) {
        second = ConfigurationFieldType.CatSlowMoverSales;
      }

      if (
        !second ||
        !result[key].find(e => e.category.id === exc.category.id && e.fieldType === second)
      ) {
        result[key].push(exc);
      }
    }
  });

  return result;
};

export const getDefinitionByFieldType = (
  ft: ConfigurationFieldType,
  configurationDefinitions: CategoryConfigurationDefinitionResponse[],
): CategoryConfigurationDefinitionResponse | undefined => {
  return configurationDefinitions.find(cd => cd.fieldType === ft);
};

export const forcedTitleAsCategory = (
  exc: PartialExceptionExpanded,
  _: CategoryConfigurationDefinitionResponse[],
): string => categoryStringRoute(exc.category);

export const forcedTitleAsFieldType = (
  exc: PartialExceptionExpanded,
  configurationDefinitions: CategoryConfigurationDefinitionResponse[],
): string => getDefinitionByFieldType(exc.fieldType, configurationDefinitions)?.title ?? '';

export const renderer = (
  exceptionExpanded: PartialExceptionExpanded,
  forcedTitleGetter: (
    exc: PartialExceptionExpanded,
    configurationDefinitions: CategoryConfigurationDefinitionResponse[],
  ) => string,
  configurationDefinitions: CategoryConfigurationDefinitionResponse[],
  forcedEntityListId?: number,
): React.ReactNode => {
  const definition = getDefinitionByFieldType(
    exceptionExpanded.fieldType,
    configurationDefinitions,
  );

  if (!definition) return null;

  const forcedTitle = forcedTitleGetter(exceptionExpanded, configurationDefinitions);
  const key = `key-${definition.id}-${exceptionExpanded.category.id}`;

  if (
    definition.fieldType === ConfigurationFieldType.CatSlowMoverSales ||
    definition.fieldType === ConfigurationFieldType.CatSlowMoverMonths
  ) {
    return (
      <ConfigurationRow
        key={key}
        definition={definition}
        forcedCategoryId={exceptionExpanded.category.id}
        forcedTitle={forcedTitle}
      >
        <ConfigurationField
          disabled={false}
          skuTypeColumn={null}
          fieldType={ConfigurationFieldType.CatSlowMoverSales}
          dataType={ConfigurationDataType.InputNumber}
          forcedCategoryId={exceptionExpanded.category.id}
        />
        <span>sales within</span>
        <ConfigurationField
          disabled={false}
          skuTypeColumn={null}
          fieldType={ConfigurationFieldType.CatSlowMoverMonths}
          dataType={ConfigurationDataType.SelectBoxMonthIdentifySkuType}
          forcedCategoryId={exceptionExpanded.category.id}
        />
        <span>months</span>
      </ConfigurationRow>
    );
  } else if (definition.fieldType === ConfigurationFieldType.CatDeadStock) {
    return (
      <ConfigurationRow
        definition={definition}
        key={key}
        forcedCategoryId={exceptionExpanded.category.id}
        forcedTitle={forcedTitle}
      >
        <ConfigurationField
          disabled={false}
          skuTypeColumn={null}
          fieldType={ConfigurationFieldType.CatDeadStock}
          dataType={ConfigurationDataType.InputNumber}
          forcedCategoryId={exceptionExpanded.category.id}
        />
        <span>months without sale</span>
      </ConfigurationRow>
    );
  }

  if (definition.dataType === ConfigurationDataType.SelectBoxMinLayerList) {
    return (
      <ConfigurationEntityLists
        definition={definition}
        forcedCategoryId={exceptionExpanded.category.id}
        forcedEntityListId={forcedEntityListId}
        forcedTitle={forcedTitle}
        fullyFeatured={false}
      />
    );
  }

  return (
    <ConfigurationRow
      definition={definition}
      key={key}
      forcedCategoryId={exceptionExpanded.category.id}
      forcedTitle={forcedTitle}
    />
  );
};
