import React, { PropsWithChildren } from 'react';
import { COLORS, computeRemSize } from '@ydistri/ds';
import {
  CategoryConfigurationDefinitionResponse,
  ConfigurationSectionType,
  SkuType,
} from '@ydistri/api-sdk';
import { styled } from 'styled-components';
import ConfigurationField from '../../../../components/global/ConfigurationField/ConfigurationField';
import { List } from 'antd';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import ConfigurationRowTitle from './ConfigurationRowTitle';
import { isSectionTypeSource, isSectionTypeTarget } from '../configurationUtils';

export const ConfigurationRowWrapper = styled(List.Item)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  flex-wrap: wrap;
  font-size: 1rem;
  min-height: ${computeRemSize(30)};

  &:hover {
    background-color: ${COLORS.HOVER_GREY_LIGHT};
  }
`;

export const ConfigurationFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${computeRemSize(360)};
  max-width: ${computeRemSize(360)};
  min-width: ${computeRemSize(360)};
  height: 100%;
`;

export const ConfigurationFieldsWrapperCentered = styled(ConfigurationFieldsWrapper)`
  justify-content: center;
`;

export const ConfigurationFieldsWrapperTarget = styled(ConfigurationFieldsWrapperCentered)`
  width: ${computeRemSize(200)};
  max-width: ${computeRemSize(200)};
  min-width: ${computeRemSize(200)};
`;

interface ConfigurationRowProps extends PropsWithChildren {
  definition: CategoryConfigurationDefinitionResponse;
  forcedTitle?: string;
  forcedCategoryId?: number;
}

const ConfigurationRow: React.FC<ConfigurationRowProps> = ({
  definition,
  forcedTitle,
  forcedCategoryId,
  children,
}) => {
  const { type: templateOrCalculationType } = useTemplateOrCalculation();
  const fieldsDisabled = templateOrCalculationType === 'Calculation';

  return (
    <ConfigurationRowWrapper>
      <ConfigurationRowTitle definition={definition} forcedTitle={forcedTitle} />
      {isSectionTypeSource(definition.sectionType) && (
        <ConfigurationFieldsWrapperCentered>
          <ConfigurationField
            skuTypeColumn={SkuType.DeadStock}
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            forcedCategoryId={forcedCategoryId}
          />
          <ConfigurationField
            skuTypeColumn={SkuType.SlowMover}
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            forcedCategoryId={forcedCategoryId}
          />
          <ConfigurationField
            skuTypeColumn={SkuType.FastMover}
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            forcedCategoryId={forcedCategoryId}
          />
        </ConfigurationFieldsWrapperCentered>
      )}
      {isSectionTypeTarget(definition.sectionType) && (
        <ConfigurationFieldsWrapperTarget>
          <ConfigurationField
            skuTypeColumn="Target"
            fieldType={definition.fieldType}
            dataType={definition.dataType}
            disabled={fieldsDisabled}
            inputWidth={computeRemSize(150)}
            forcedCategoryId={forcedCategoryId}
          />
        </ConfigurationFieldsWrapperTarget>
      )}
      {definition.sectionType === ConfigurationSectionType.General && (
        <ConfigurationFieldsWrapper>{children}</ConfigurationFieldsWrapper>
      )}
    </ConfigurationRowWrapper>
  );
};

export default ConfigurationRow;
