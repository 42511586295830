import React, { useMemo } from 'react';
import { Popover } from 'antd';
import { Column, Text } from '@ydistri/ds';
import { MdWarning } from 'react-icons/md';
import { ScopeErrorType } from './scopeLib2';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';

export type ScopeConfigurationFieldRowWarningType =
  | 'closingAndSuperTargetAtOnce'
  | 'departmentClosingSuperTargetWarning'
  | ScopeErrorType;

interface ScopeConfigurationFieldRowWarningProps {
  scopeId: number;
  warningType: ScopeConfigurationFieldRowWarningType;
  value: number | boolean | null | undefined;
  title: string;
}

interface ScopeErrorTypePopoverProps {
  higherPriorityException: string;
  exceptionIds: number[] | undefined;
}

const ScopeErrorTypePopover: React.FC<ScopeErrorTypePopoverProps> = ({
  higherPriorityException,
  exceptionIds,
}) => {
  const scopeList = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.scopeList.object,
  );

  return (
    <Column $gap="1rem">
      <Column>
        <Text>
          Exception with higher priority is set as &apos;{higherPriorityException}&apos;, but its
          scope overlaps with this exception.
        </Text>
        <Text>
          That causes some stores to be{' '}
          <b>&apos;Super Target&apos; and &apos;Closing&apos; at once</b>.
        </Text>
      </Column>
      <Column>
        <Text>These exceptions cause this warning:</Text>
        <ul>
          {exceptionIds?.map(id => <li key={id}>{scopeList[id]?.name ?? `Exception ${id}`}</li>)}
        </ul>
      </Column>
    </Column>
  );
};

const ScopeConfigurationFieldRowWarning: React.FC<ScopeConfigurationFieldRowWarningProps> = ({
  scopeId,
  warningType,
  value,
  title,
}) => {
  const scopeOverlapErrors = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.scopesWithErrors[scopeId],
  );

  const popoverContent: React.ReactNode = useMemo(() => {
    switch (warningType) {
      case 'closingAndSuperTargetAtOnce': {
        return 'Can not be Closing and Super target at once';
      }
      case 'departmentClosingSuperTargetWarning': {
        return 'Department-only scopes can not be used as closing or super target';
      }

      case ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET: {
        return (
          <ScopeErrorTypePopover
            higherPriorityException="Closing"
            exceptionIds={scopeOverlapErrors?.isClosingOverlapsSuperTarget}
          />
        );
      }
      case ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING: {
        return (
          <ScopeErrorTypePopover
            higherPriorityException="Super target"
            exceptionIds={scopeOverlapErrors?.isSuperTargetOverlapsClosing}
          />
        );
      }
    }
  }, [
    scopeOverlapErrors?.isClosingOverlapsSuperTarget,
    scopeOverlapErrors?.isSuperTargetOverlapsClosing,
    warningType,
  ]);

  return (
    <Popover content={popoverContent}>
      <Text $type={value !== null ? 'danger' : 'light'} $size="large" $semiBold>
        <MdWarning size={16} /> {title}
      </Text>
    </Popover>
  );
};

export default ScopeConfigurationFieldRowWarning;
