import React from 'react';
import { Row, Section } from '@ydistri/ds';
import DeadStockTypeDefinition from './components/DeadStockTypeDefinition';
import SlowMoversTypeDefinition from './components/SlowMoversTypeDefinition';
import FastMoversTypeDefinition from './components/FastMoversTypeDefinition';
import { Spin } from 'antd';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';

interface CategoryInventoryDefinitionSectionProps {
  fetching?: boolean;
}

const CategoryInventoryDefinitionSection: React.FC<CategoryInventoryDefinitionSectionProps> = ({
  fetching,
}) => {
  const { type: templateOrCalculationType } = useTemplateOrCalculation();
  const fieldsDisabled = templateOrCalculationType === 'Calculation';

  return (
    <Section header="Inventory type definition" $contentMarginTop={false} $ratio={0}>
      <Spin spinning={fetching}>
        <Row $flexWrap="nowrap">
          <DeadStockTypeDefinition disabled={fieldsDisabled} />
          <SlowMoversTypeDefinition disabled={fieldsDisabled} />
          <FastMoversTypeDefinition />
        </Row>
      </Spin>
    </Section>
  );
};

export default CategoryInventoryDefinitionSection;
