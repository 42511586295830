import SKUTableController from '../../../../../../components/domain/SKUTable/SKUTableController';
import React from 'react';
import { Section } from '@ydistri/ds';
import { Units } from '../../../../../../lib/charts/saleChartsTypes';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';

interface SKUTableSectionProps {
  productId?: number;
  storeId?: number;
  units: Units;
}

const SKUTableSection: React.FC<SKUTableSectionProps> = ({
  productId = NO_VALUE,
  storeId = NO_VALUE,
  units,
}) => {
  if (productId === NO_VALUE && storeId === NO_VALUE) {
    return null;
  }

  return (
    <Section header="Product / Store combinations">
      <SKUTableController productId={productId} storeId={storeId} units={units} />
    </Section>
  );
};

export default SKUTableSection;
