import React from 'react';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useGetPickingResultsSummaryQuery } from '../../apiCalculationPickingResults';
import StatisticsItem from '../../components/StatisticsItem';
import { useCurrency } from '../../../../../hooks/useCurrency';

/**
 * Set of statistics for the overall picking results.
 */
const CalculationOverallPickingStatistics: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency();

  const { data, isFetching } = useGetPickingResultsSummaryQuery({
    calculationId: templateOrCalculation.id,
  });

  //compute picking statistics for all source stores

  if (!isFetching && data) {
    return (
      <>
        <StatisticsItem
          title="Executed quanity [MU]"
          unit="MU"
          value={data.pickingResults.executedQuantity ?? 0}
          totalValue={data.pickingResults.totalQuantity ?? 0}
          percentage={data.pickingResults.executedQuantityPercentage}
        />
        <StatisticsItem
          title={`Executed value [${currencyId}]`}
          unit={currencyId}
          value={data.pickingResults.executedValue ?? 0}
          totalValue={data.pickingResults.totalValue ?? 0}
          percentage={data.pickingResults.executedValuePercentage}
        />
        <StatisticsItem
          title="Executed Picking Positions"
          unit=""
          value={data.pickingResults.executedSkuCount ?? 0}
          totalValue={data.pickingResults.totalSkuCount ?? 0}
          percentage={data.pickingResults.executedSkuCountPercentage}
        />
      </>
    );
  }
};

export default CalculationOverallPickingStatistics;
