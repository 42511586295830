import { YColumnsType } from '@ydistri/ds';
import { get } from 'lodash';
import { capitalizeFirstLetter } from '@ydistri/utils';
import { useApplicationConfiguration } from './useApplicationConfiguration';
import { useCallback, useMemo } from 'react';
import { DisplayEntityIdType } from '@ydistri/api-sdk';
import { makeColumnFilterable } from '../lib/tables/tablesLocalFilterLib';

export enum EntityColumns {
  NAME = 1,
  ID = 2,
  CODE = 4,
}

export type EntityColumnsConfig<T> = Partial<Record<EntityColumns, YColumnsType<T>>>;

export type EntityTableColumnProvider = <T>(
  config?: EntityColumnsConfig<T>,
  objectName?: string | string[],
  baseTitle?: string,
  customerIdPropertyName?: string,
) => YColumnsType<T>[];

interface ColumnCreationParameters {
  /** title of the column */
  title: string;
  /** path to the object holding the final value. Use multiple elements for nested objects */
  objectPath: string[];
  /** name of the property holding a value within the last object in storeObjectPath */
  propertyName: string;
  /** type of property pointing to by propertyName. Needed for sorter and filter */
  propertyType: 'text' | 'number';
  /** use API based sortering and filtering */
  sortedAndFilteredWithApi?: boolean;
  /** Name of the API column if API sortering and filtering is used */
  apiColumnName?: string;
}

/**
 * Create column for a table based on parameters
 * @param params
 */
const createTableColumn = <T,>(params: ColumnCreationParameters): YColumnsType<T> => {
  const {
    title,
    objectPath,
    propertyName,
    propertyType,
    sortedAndFilteredWithApi = false,
    apiColumnName,
  } = params;

  const finalDataIndex = [...objectPath, propertyName];
  const key = `key${finalDataIndex.map(capitalizeFirstLetter).join('')}`;

  const tmpApiColumnName = apiColumnName ?? finalDataIndex.map(capitalizeFirstLetter).join('/');

  const sorterString = (a: T, b: T) => {
    const defaultValue = '';
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
    const el1: string = get(a, finalDataIndex, defaultValue) as string;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
    const el2: string = get(b, finalDataIndex, defaultValue) as string;

    return el1.localeCompare(el2);
  };

  const sorterNumber = (a: T, b: T) => {
    const defaultValue = 0;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
    const el1: number = get(a, finalDataIndex, defaultValue) as number;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
    const el2: number = get(b, finalDataIndex, defaultValue) as number;
    return el1 - el2;
  };

  const sorter = propertyType === 'number' ? sorterNumber : sorterString;

  const column: YColumnsType<T> = {
    title,
    key,
    dataIndex: finalDataIndex,
    sortDirections: ['ascend', 'descend'],
    sorter: sortedAndFilteredWithApi ? true : sorter,
    apiFilterable: sortedAndFilteredWithApi,
    apiFilterType: propertyType,
    apiColumnName: tmpApiColumnName,
  };

  //add local filter if not filterable with API
  if (!sortedAndFilteredWithApi) {
    return makeColumnFilterable(column);
  } else {
    return column;
  }
};

interface IdColumnProperties {
  idPropertyName: string;
  idPropertyType: 'text' | 'number';
}

/**
 * Helper functions to return properties for property name and type
 * @param useSystemIdForStoreId Either CustomerId or system Id can be used. True if System Id is used, if false CustomerId will be used
 * @param customerIdPropertyName Name of the property holding the CustomerId
 */
const getIdColumnProperties = (
  useSystemIdForStoreId: boolean,
  customerIdPropertyName: string,
): IdColumnProperties => {
  return useSystemIdForStoreId
    ? { idPropertyName: 'id', idPropertyType: 'number' }
    : { idPropertyName: customerIdPropertyName, idPropertyType: 'text' };
};

/**
 * Generate columns for store to be used for Ant Design table
 * @param columnsMask bitmask of columns to include
 * @param config additional configuration for columns
 * @param objectName name of the store object in the data, defaults to 'store'
 * @param baseTitle base title for the columns, defaults to 'Store'
 * @param useSystemIdForStoreId
 * @param customerIdPropertyName
 */
const getBaseStoreColumns = <T,>(
  columnsMask: number,
  config: EntityColumnsConfig<T> = {},
  objectName: string | string[] = 'store',
  baseTitle: string = 'Store',
  useSystemIdForStoreId: boolean = false,
  customerIdPropertyName: string = 'customerStoreId',
  // eslint-disable-next-line max-params -- we need all parameters
): YColumnsType<T>[] => {
  const columns: YColumnsType<T>[] = [];

  const addColumn = (
    columnType: EntityColumns,
    titleSuffix: string,
    propertyName: string,
    propertyType: 'text' | 'number',
  ) => {
    if (columnsMask & columnType) {
      let sortedAndFilteredWithApi = false;
      let apiColumnName: string | undefined;

      const columnConfig = config[columnType];
      if (columnConfig) {
        sortedAndFilteredWithApi = columnConfig.apiFilterable ?? false;
        apiColumnName = columnConfig.apiColumnName;
      }

      const col = createTableColumn<T>({
        title: `${baseTitle} ${titleSuffix}`,
        objectPath: Array.isArray(objectName) ? objectName : [objectName],
        propertyName: propertyName,
        propertyType: propertyType,
        sortedAndFilteredWithApi,
        apiColumnName,
      });

      columns.push({
        ...col,
        ...columnConfig,
      });
    }
  };

  const { idPropertyName, idPropertyType } = getIdColumnProperties(
    useSystemIdForStoreId,
    customerIdPropertyName,
  );

  addColumn(EntityColumns.ID, 'Id', idPropertyName, idPropertyType);
  addColumn(EntityColumns.CODE, 'Code', 'code', 'text');
  addColumn(EntityColumns.NAME, 'Name', 'name', 'text');

  return columns;
};

interface EntityColumnsFunctionCreatorParams {
  displayEntityIdType: DisplayEntityIdType;
  useEntityCode: boolean;
  useSystemIdForStoreId: boolean;
  objectName: string | string[];
  baseTitle: string;
  customerIdPropertyName: string;
}

const useEntityColumnsFunctionCreator = (
  params: EntityColumnsFunctionCreatorParams,
): EntityTableColumnProvider => {
  return useCallback(
    <T,>(
      config?: EntityColumnsConfig<T>,
      objectName: string | string[] = params.objectName,
      baseTitle: string = params.baseTitle,
      customerIdPropertyName: string = params.customerIdPropertyName,
    ) => {
      const { useEntityCode, displayEntityIdType, useSystemIdForStoreId } = params;
      let columnsMask = EntityColumns.NAME;

      if (displayEntityIdType === DisplayEntityIdType.Code && useEntityCode) {
        columnsMask |= EntityColumns.CODE;
      }

      if (displayEntityIdType === DisplayEntityIdType.CustomerId) {
        columnsMask |= EntityColumns.ID;
      }

      return getBaseStoreColumns<T>(
        columnsMask,
        config,
        objectName,
        baseTitle,
        useSystemIdForStoreId,
        customerIdPropertyName,
      );
    },
    [params],
  );
};

/**
 * Create a function that will provide store columns.
 * The generated function will provide columns based on the application configuration.
 * It takes into account whether store codes are enabled and what is the displayStoreId setting
 * in ApplicationConfiguration.
 */
export const useProductTableColumnsProvider = (): EntityTableColumnProvider => {
  const { displayProductId, mdbProductIdAutoIncrement } = useApplicationConfiguration();

  const params = useMemo(() => {
    return {
      displayEntityIdType: displayProductId,
      useEntityCode: true,
      useSystemIdForStoreId: mdbProductIdAutoIncrement === 'INT',
      objectName: 'product',
      baseTitle: 'Product',
      customerIdPropertyName: 'customerId',
    };
  }, [displayProductId, mdbProductIdAutoIncrement]);

  return useEntityColumnsFunctionCreator(params);
};

/**
 * Create a function that will provide product columns.
 * The generated function will provide columns based on the application configuration.
 * It takes into account whether product codes are enabled and what is the displayProductId setting
 */
export const useStoreTableColumnsProvider = (): EntityTableColumnProvider => {
  const { displayStoreId, mdbWarehouseIdAutoIncrement } = useApplicationConfiguration();

  const params = useMemo(() => {
    return {
      displayEntityIdType: displayStoreId,
      useEntityCode: true,
      useSystemIdForStoreId: mdbWarehouseIdAutoIncrement === 'INT',
      objectName: 'store',
      baseTitle: 'Store',
      customerIdPropertyName: 'customerStoreId',
    };
  }, [displayStoreId, mdbWarehouseIdAutoIncrement]);

  return useEntityColumnsFunctionCreator(params);
};
