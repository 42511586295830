import React, { useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState, useAppDispatch } from '../../store';
import CategoryInsights from '../../components/global/CategoryInsights/CategoryInsights';
import { styled } from 'styled-components';
import { ColumnScroll, Title } from '@ydistri/ds';
import useCategoryId from '../../hooks/useCategoryId';
import { useGetCategoryTreeQuery } from '../../components/global/CategoryTree/apiCategoryTree';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import AlertOutdated from '../../components/global/AlertOutdated/AlertOutdated';

const CategoryInsightsWrapper = styled(ColumnScroll)`
  padding-top: 0;
  padding-bottom: 0;
`;

const MODAL_HEIGHT = 'calc(100vh - 10rem)';

const StyledModal = styled(Modal)`
  top: 20px;
  height: ${MODAL_HEIGHT};
`;

const modalId = AvailableModals.CATEGORY_INSIGHTS;

const CategoryInsightsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const categoryId = useCategoryId();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: categories } = useGetCategoryTreeQuery(templateOrCalculation);
  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const categoryTitle =
    categoryId > 1 ? `${categories?.[categoryId]?.title ?? 'Unknown category '} - ` : 'Company';

  const title = useMemo(
    () => <Title $size="large">{categoryTitle} potential overview with this setting </Title>,
    [categoryTitle],
  );

  return (
    <StyledModal title={title} open={open} width="90vw" onCancel={handleClose} footer={null}>
      <CategoryInsightsWrapper $height={MODAL_HEIGHT}>
        <AlertOutdated />
        <CategoryInsights />
      </CategoryInsightsWrapper>
    </StyledModal>
  );
};

export default CategoryInsightsModal;
