import { ActionsBar, Section, Spinner } from '@ydistri/ds';
import React, { useCallback, useMemo, useState } from 'react';
import CreateIconButton from '../../../../components/buttons/CreateIconButton';
import { useSelector } from 'react-redux';
import {
  getArchivedItemsCount,
  getArchivedStateSorter,
  lastUpdatedAtSorter,
  nameSorter,
  onlyActive,
} from '../../common/administrationItemsLib';
import { addToast } from '@ydistri/utils';
import ShowArchivedItemsToggle from '../../common/ShowArchivedItemsToggle';
import { ReduxState, useAppDispatch } from '../../../../store';
import { getDefaultEntityListsParams, useGetEntityListsQuery } from '../apiEntityLists';
import { EntityListType } from '@ydistri/api-sdk';
import {
  setSelectedEntityList,
  setShowArchivedItems,
  setSortField,
} from '../entityListAdministrationSlice';
import { titleByEntityListType } from '../entityListsLib';
import EntityListsList from './EntityListsList';
import CreateEntityListModal from '../modals/CreateEntityListModal';
import SortSelector from '../../common/SortSelector';

interface EntityListAdministrationCatalogProps {
  entityListType: EntityListType;
}

const EntityListAdministrationCatalog: React.FC<EntityListAdministrationCatalogProps> = ({
  entityListType,
}) => {
  const dispatch = useAppDispatch();

  const { data: entityLists, isFetching } = useGetEntityListsQuery(
    getDefaultEntityListsParams(entityListType),
  );

  const showArchivedItems = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].showArchivedItems,
  );
  const sortField = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].sortField,
  );
  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const onCreateEntityListClicked = useCallback(() => {
    setModalOpened(previousModalOpened => !previousModalOpened);
  }, []);

  const onCreateEntityListModalClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  //Archived entity lists are not displayed by default
  //If they are, they are listed after the active ones and sorted by name
  const displayedData = useMemo(() => {
    if (entityLists) {
      const sorter = sortField === 'LastUpdatedAt' ? lastUpdatedAtSorter : nameSorter;
      let result;

      if (!showArchivedItems) {
        result = onlyActive(entityLists).sort(sorter);
      } else {
        result = Array.from(entityLists).sort(getArchivedStateSorter(sorter));
      }

      return result;
    }
  }, [sortField, entityLists, showArchivedItems]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- calls a function that does the heavy lifting
  const archivedItemsCount = useMemo(
    () => (!entityLists ? 0 : getArchivedItemsCount(entityLists)),
    [entityLists],
  );

  /**
   * Shows or hides archived items
   */
  const toggleShowArchived = useCallback(() => {
    const newShowArchivedItems = !showArchivedItems;
    dispatch(setShowArchivedItems({ entityListType, data: newShowArchivedItems }));
    if (newShowArchivedItems) {
      dispatch(
        addToast({
          message: `Showing archived ${titleByEntityListType[entityListType]}s`,
          description: `Archived ${titleByEntityListType[entityListType]}s are shown below the active ones`,
        }),
      );
    }

    if (selectedEntityList?.isArchived) {
      dispatch(setSelectedEntityList({ entityListType, data: undefined }));
    }
  }, [dispatch, entityListType, selectedEntityList?.isArchived, showArchivedItems]);

  const existingEntityListNames = useMemo(
    () => entityLists?.map(el => el.name ?? ''),
    [entityLists],
  );

  const onSortChange = useCallback(
    (v: string) => {
      dispatch(setSortField({ entityListType, data: v }));
    },
    [dispatch, entityListType],
  );

  const contentActions = useMemo(() => {
    return (
      <ActionsBar>
        <ShowArchivedItemsToggle
          archivedItemsCount={archivedItemsCount}
          checked={showArchivedItems}
          onClick={toggleShowArchived}
        />
        <SortSelector onSortChange={onSortChange} defaultValue={sortField} />
      </ActionsBar>
    );
  }, [archivedItemsCount, showArchivedItems, toggleShowArchived, onSortChange, sortField]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <CreateIconButton
          size="small"
          tooltip="Create list"
          onClick={onCreateEntityListClicked}
          data-testid="create_new_entity_list"
        />
      </ActionsBar>
    );
  }, [onCreateEntityListClicked]);

  return (
    <>
      <Section
        header={titleByEntityListType[entityListType]}
        headerActions={headerActions}
        contentActions={contentActions}
        $scrollableContent={true}
        $ratio={0}
        data-testid="EntityListsSection"
        $width="25rem"
      >
        {isFetching && <Spinner />}
        <EntityListsList
          entityListType={entityListType}
          selectedItem={selectedEntityList}
          data={displayedData}
          loading={isFetching}
        />
      </Section>
      {modalOpened && (
        <CreateEntityListModal
          entityListType={entityListType}
          onClose={onCreateEntityListModalClose}
          confirmButtonLabel="Create"
          restrictedNames={existingEntityListNames}
        />
      )}
    </>
  );
};

export default EntityListAdministrationCatalog;
