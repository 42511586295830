import React from 'react';
import { COLORS, Column, computeRemSize, Row, Text, Title } from '@ydistri/ds';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { styled } from 'styled-components';
import { useCurrency } from '../../../../../../hooks/useCurrency';
import { formatNumber } from '@ydistri/utils';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../../../../apiCalculationDetail';
import { CalculationMode } from '@ydistri/api-sdk';
import { useSelectedPickingPositionFromUrl } from '../useSelectedPickingPositionFromUrl';

const ContentWrapper = styled(Column)`
  margin: 0.8rem;
  justify-content: center;
  align-items: center;
  flex-basis: ${computeRemSize(110)};
  flex-shrink: 0;
`;

const SummaryLabel = styled(Text)`
  text-transform: uppercase;
`;

interface RedistributionLabeledValueProps {
  label: string;
  value: string;
  unit: string;
}

/**
 * Single large redistribution value with label and unit. Used for quantity and value.
 * @param label Displayed in uppercase
 * @param value
 * @param unit
 * @constructor
 */
const RedistributionLabeledValue: React.FC<RedistributionLabeledValueProps> = ({
  label,
  value,
  unit,
}) => {
  return (
    <ContentWrapper $gap={computeRemSize(4)}>
      <SummaryLabel>{label}</SummaryLabel>
      <Row $gap="6pt" $justifyContent="center">
        <Title>{value}</Title>
        <Title>{unit}</Title>
      </Row>
    </ContentWrapper>
  );
};

/**
 * Summarizes the redistribution of a single SKU. Displays a quantity and value
 * with graphical icon
 * @constructor
 */
const CalculationRedistributionSummary: React.FC = () => {
  const skuRedistribution = useSelectedPickingPositionFromUrl();
  const currency = useCurrency();

  const calculationData = useTemplateOrCalculation();
  const { data: calculation } = useGetCalculationQuery(calculationData.id);

  const formattedValue = skuRedistribution ? formatNumber(skuRedistribution.value, 0) : '';
  const formattedQuantity = skuRedistribution ? formatNumber(skuRedistribution.quantity, 0) : '';

  return (
    <ContentWrapper>
      <RedistributionLabeledValue label="Quantity" value={formattedQuantity} unit="MU" />
      {calculation?.mode !== CalculationMode.Merged && (
        <FaRegArrowAltCircleRight size={computeRemSize(48)} color={COLORS.PRIMARY} />
      )}
      <RedistributionLabeledValue label="Value" value={formattedValue} unit={currency} />
    </ContentWrapper>
  );
};

export default CalculationRedistributionSummary;
