import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { ApiOperationType, EntityListType } from '@ydistri/api-sdk';
import {
  EntityListInputDataByEntityListType,
  getParserForContentType,
  IMPORT_STEPS,
  markDuplicates,
} from './entityListsLib';
import { ReduxState, useAppDispatch } from '../../../store';
import {
  setEntityListItems,
  setImportStepRunning,
  setVerificationTimeStamp,
} from './entityListAdministrationSlice';
import {
  useCreateEntityListImportMutation,
  usePutEntityListImportItemsMutation,
} from './apiEntityLists';
import { apiSlice, APITAGS, ErrorType } from '../../../apis/api';
import { addToast } from '@ydistri/utils';
import { useSetImportStep } from './useSetImportStep';
import { useSetImportStepRunning } from './useSetImportStepRunning';
import { QueryStatus } from '@reduxjs/toolkit/query';

export type ImportItemFunction = () => void;

export interface UseImportItemsData {
  isPending: boolean;
  callback: ImportItemFunction;
}

const entityListInputDataToString = <T extends keyof EntityListInputDataByEntityListType>(
  type: T,
  data: Partial<EntityListInputDataByEntityListType[T]>,
): string => {
  if (type === EntityListType.ProductList) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- the type is known at this point
    const tmp = data as EntityListInputDataByEntityListType[EntityListType.ProductList];
    return tmp.product;
  } else if (type === EntityListType.MinLayerList) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- the type is known at this point
    const tmp = data as EntityListInputDataByEntityListType[EntityListType.MinLayerList];
    return `${tmp.store},${tmp.product},${tmp.count}`;
  } else if (type === EntityListType.TargetList) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- the type is known at this point
    const tmp = data as EntityListInputDataByEntityListType[EntityListType.TargetList];
    return `${tmp.store},${tmp.product},${tmp.count}`;
  }

  return '';
};

const useImportItems = (entityListType: EntityListType): UseImportItemsData => {
  const dispatch = useAppDispatch();
  const setStep = useSetImportStep(entityListType);
  const setStepStatus = useSetImportStepRunning(entityListType);

  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );
  const storeIdentificationType = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].storeIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) =>
      state.entityListsAdministration[entityListType].productIdentificationType,
  );
  const productItemsContent = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].productItemsContent,
  );

  const [createEntityListImport, createEntityListImportStatus] =
    useCreateEntityListImportMutation();
  const [putEntityListImportItems, putEntityListImportItemsStatus] =
    usePutEntityListImportItemsMutation();

  const callback = useCallback(() => {
    if (productItemsContent.length === 0) {
      return;
    }

    const parser = getParserForContentType('text/plain');
    if (!parser) {
      return;
    }

    const parsedRows = parser.parse(entityListType, productItemsContent);
    if (!(parsedRows.length > 0 && selectedEntityList)) {
      return;
    }

    setStepStatus(IMPORT_STEPS.ITEM_VALIDATION, true);
    markDuplicates(parsedRows);

    // dispatch(setIsLoading(true));
    dispatch(setEntityListItems({ entityListType, data: parsedRows }));

    const verificationTimeStamp = new Date().toISOString();

    createEntityListImport({
      entityListType,
      entityListId: selectedEntityList.entityListId,
      apiOperationType: ApiOperationType.Replace,
      verificationTimeStamp,
      productIdentificationTypeId: productIdentificationType,
      storeIdentificationTypeId: storeIdentificationType,
    })
      .then(() => {
        dispatch(setVerificationTimeStamp({ entityListType, data: verificationTimeStamp }));

        //we only want to send valid items
        const validParsedRowsFlat = parsedRows
          .filter(row => !row.isError)
          .map(row => entityListInputDataToString(entityListType, row.inputData))
          .join('\n');
        const itemsWithHeaders = `StoreIdentification,ProductIdentification,Quantity\n${validParsedRowsFlat}`;

        putEntityListImportItems({
          entityListId: selectedEntityList.entityListId,
          importItemsEncodedBase64: btoa(itemsWithHeaders),
          verificationTimeStamp,
        })
          .then(() => {
            setStepStatus(IMPORT_STEPS.ITEM_VALIDATION, false);
            setStep(IMPORT_STEPS.ITEM_VALIDATION);

            dispatch(
              apiSlice.util.invalidateTags([
                APITAGS.entityListsAdministration.entityListImportSummary,
              ]),
            );
            dispatch(
              apiSlice.util.invalidateTags([
                APITAGS.entityListsAdministration.entityListImportItems,
              ]),
            );
          })
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- the error is known
          .catch((err: ErrorType) => {
            dispatch(
              addToast({
                message: `Import failed: ${err.response.data.Messages.join(', ')}`,
                isError: true,
              }),
            );
          })
          .finally(() => {
            dispatch(
              setImportStepRunning({
                entityListType,
                data: {
                  step: IMPORT_STEPS.ITEM_VALIDATION,
                  value: false,
                },
              }),
            );
          });
      })
      // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- the error is known
      .catch((err: ErrorType) => {
        dispatch(
          addToast({
            message: `Import failed: ${err.response.data.Messages.join(', ')}`,
            isError: true,
          }),
        );
        setStepStatus(IMPORT_STEPS.ITEM_VALIDATION, false);
      });

    // validateEntityListContent(
    //   selectedEntityList,
    //   parsedRows,
    //   productIdentificationType,
    //   storeIdentificationType,
    // )
    //   .then(data => {
    //     const errorItems = data.items.filter(validatedItem => validatedItem.isError);
    //     const plainErrorData = getParseableString(
    //       errorItems,
    //       data.result?.warehouseIdentificationTypeId ?? IdentificationType.CustomerId,
    //       data.result?.productIdentificationTypeId ?? IdentificationType.CustomerId,
    //       ';',
    //     );
    //
    //     if (!handlingMethod) {
    //       dispatch(setHandlingMethod(AdministrationItemListHandlingMethod.ADD));
    //     }
    //
    //     batch(() => {
    //       dispatch(validationFinished({ validation: data, errors: plainErrorData }));
    //     });
    //   })
  }, [
    createEntityListImport,
    dispatch,
    entityListType,
    productIdentificationType,
    productItemsContent,
    putEntityListImportItems,
    selectedEntityList,
    setStep,
    setStepStatus,
    storeIdentificationType,
  ]);

  const isPending =
    putEntityListImportItemsStatus.status === QueryStatus.pending ||
    createEntityListImportStatus.status === QueryStatus.pending;

  return useMemo(
    () => ({
      isPending,
      callback,
    }),
    [isPending, callback],
  );
};

export default useImportItems;
