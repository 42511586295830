import React, { useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { useCurrency } from '../../../../../hooks/useCurrency';
import { SimpleTable } from '../../../Statistics/components/SimpleTable';
import { useGetTargetStoresPickingResultsQuery } from '../../apiCalculationPickingResults';
import {
  evaluationsRowClassName,
  getBasePickResultsColumns,
  SourceStorePickingResultExtendedResponse,
} from '../../pickingResultsLib';
import EmbeddedTableWrapper from '../../components/EmbeddedTableWrapper';
import { computeRemSize } from '@ydistri/ds';
import {
  EntityColumns,
  useStoreTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

interface TargetStoreDetailTableProps {
  sourceStoreId: number;
}

const TargetStorePickingResultsDetailTable: React.FC<TargetStoreDetailTableProps> = ({
  sourceStoreId,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const clusterType = useSelector((state: ReduxState) => state.evaluations.clusterType);
  const currencyId = useCurrency();

  const { data, isFetching: isFetchingStatistics } = useGetTargetStoresPickingResultsQuery({
    calculationId: templateOrCalculation.id,
    clusterType,
    sourceStoreId,
  });

  const storeColumnsProvider = useStoreTableColumnsProvider();

  const columns = useMemo(() => {
    const columnsConfig = {
      [EntityColumns.ID]: {
        width: computeRemSize(80),
      },
    };

    const storeColumns =
      storeColumnsProvider<SourceStorePickingResultExtendedResponse>(columnsConfig);
    return getBasePickResultsColumns(currencyId, storeColumns, false);
  }, [storeColumnsProvider, currencyId]);

  return (
    <>
      {isFetchingStatistics && <LoadingIndicator />}
      {data && (
        <EmbeddedTableWrapper title="Target stores">
          <SimpleTable<SourceStorePickingResultExtendedResponse>
            id={`target-store-picking-results-${sourceStoreId}`}
            dataSource={data}
            columns={columns}
            loading={isFetchingStatistics}
            showHeader={true}
            rowClassName={evaluationsRowClassName}
          />
        </EmbeddedTableWrapper>
      )}
    </>
  );
};

export default TargetStorePickingResultsDetailTable;
