import { apiSlice, TemplateOrCalculation } from '../../../apis/api';
import {
  ApiOperationType,
  ConfigurationRuleCreateRequest,
  ConfigurationRuleReplaceRequest,
  ConfigurationRuleResponse,
  ConfigurationRuleScopeEntityType,
  ConfigurationRuleSetupType,
  ConfigurationRuleUpdateRequest,
  ScopeEntityConfigurationResponse,
} from '@ydistri/api-sdk';
import { CalculationsCollection, TemplatesCollection } from '../../../swagger/collections';
import { getTags } from '../../../apis/apiLib';
import {
  configurationRuleValueToString,
  createEmptyScope,
  Scope,
  setConfigurationRuleSetupType,
} from './scopeLib';
import {
  SignalConfigurationRulesChanged,
  SignalConfigurationRulesReset,
} from '../../../signalr/signalrInterfaces';
import { signalrClient } from '../../../signalr/client';
import { createDebugLog } from '../../../lib/utils/logging';

const { TAGS, TAGS_ARRAY } = getTags('scopes');

const debugLog = createDebugLog('Scopes', 'apiScopes');

export interface PostConfigurationRulePayload {
  templateId: number;
  data: ConfigurationRuleCreateRequest;
}

export interface PutConfigurationRulePayload {
  templateId: number;
  id: number;
  data: ConfigurationRuleReplaceRequest;
}

export interface PatchConfigurationRulePayload {
  templateId: number;
  id: number;
  data: ConfigurationRuleUpdateRequest;
}

export interface DeleteConfigurationRulePayload {
  templateId: number;
  id: number;
}

export interface DeleteConfigurationRulesPayload {
  templateId: number;
}

export function transformFromConfigurationRule(scope: ConfigurationRuleResponse): Scope {
  const response: Scope = createEmptyScope(
    scope.id,
    scope.name ?? undefined,
    scope.priority,
    false,
  );

  scope.scopes.forEach(s => {
    response.entities[s.entityType] = {
      selectionOption: s.scopeMode,
      selectedIds: s.entityIds,
    };
  });

  scope.setupSet.forEach(s =>
    setConfigurationRuleSetupType(response.configuration, s.setupType, s.value),
  );

  return response;
}

export function transformToConfigurationRule(scope: Scope): ConfigurationRuleCreateRequest {
  return {
    name: scope.name,
    scopes: Object.entries(scope.entities).map(([entityType, entity]) => ({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- entityType was made a string we need to type it
      entityType: entityType as ConfigurationRuleScopeEntityType,
      scopeMode: entity.selectionOption,
      entityIds: entity.selectedIds,
    })),
    setupSet: Object.entries(scope.configuration)
      .map(([setupType, setupValue]) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type of setupType
        const ruleSetupType = setupType as ConfigurationRuleSetupType;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type of setupValue
        const ruleSetupValue = setupValue as number | boolean | null;
        const value = configurationRuleValueToString(ruleSetupType, ruleSetupValue);
        return {
          setupType: ruleSetupType,
          value: value ?? '',
        };
      })
      .filter(s => s.value),
  };
}

export const apiScopes = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      getConfigurationRules: builder.query<Scope[], TemplateOrCalculation>({
        queryFn: async arg => {
          const { data: scopes } = await (arg.type === 'Template'
            ? TemplatesCollection.getTemplateConfigurationRules(arg.id)
            : CalculationsCollection.getCalculationConfigurationRules(arg.id));

          return { data: scopes.data.map(transformFromConfigurationRule) };
        },
        providesTags: [TAGS.scopes],

        async onCacheEntryAdded({ type: _, id: selectedTemplateId }, api) {
          try {
            await api.cacheDataLoaded;

            const listener = (signals: SignalConfigurationRulesChanged[]) => {
              debugLog('API - Scopes - SIGNAL!', signals);
              if (selectedTemplateId !== signals[0].templateId) return;

              api.updateCachedData(draft => {
                signals.forEach(signal => {
                  const { updates, entityId, operationType, data } = signal;

                  if (operationType === ApiOperationType.Replace) {
                    const indexToReplace = draft.findIndex(scope => scope.id === entityId);
                    if (indexToReplace >= 0 && data) {
                      draft[indexToReplace] = transformFromConfigurationRule(data);
                    }
                  } else if (operationType === ApiOperationType.Create && data) {
                    draft.push(transformFromConfigurationRule(data));
                  } else if (operationType === ApiOperationType.Patch) {
                    const indexToPatch = draft.findIndex(scope => scope.id === entityId);
                    if (indexToPatch >= 0) {
                      updates.forEach(update => {
                        switch (update.fieldName) {
                          case 'Name':
                            draft[indexToPatch].name = update.value;
                            break;
                          case 'Priority':
                            draft[indexToPatch].priority = parseInt(update.value);
                            break;
                        }
                      });
                    }
                  } else if (operationType === ApiOperationType.Delete) {
                    const indexToDelete = draft.findIndex(scope => scope.id === signal.entityId);
                    if (indexToDelete >= 0) {
                      draft.splice(indexToDelete, 1);
                    }
                  }
                });
              });
            };

            const listenerReset = (signal: SignalConfigurationRulesReset) => {
              debugLog('API - Scopes - SIGNAL!', signal);
              if (selectedTemplateId !== signal.templateId) return;

              api.updateCachedData(draft => {
                const mainConfigurationRule = draft.find(r => r.priority === 0);
                draft.splice(0, draft.length);
                if (mainConfigurationRule) {
                  draft.push(mainConfigurationRule);
                }
              });
            };

            debugLog('API - Scopes - onCacheEntryAdded [signalrClient.on]');
            signalrClient.on('ConfigurationRulesChanged', listener);
            signalrClient.on('ConfigurationRulesReset', listenerReset);

            api.cacheEntryRemoved.then(() => {
              debugLog('API - Scopes - cacheEntryRemoved [signalrClient.off]');
              signalrClient.off('ConfigurationRulesChanged', listener);
              signalrClient.off('ConfigurationRulesReset', listenerReset);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),

      postConfigurationRule: builder.mutation<
        ConfigurationRuleResponse,
        PostConfigurationRulePayload
      >({
        queryFn: async ({ templateId, data }) => {
          const response = await TemplatesCollection.postTemplateConfigurationRule(
            templateId,
            data,
          );
          return { data: response.data.data };
        },
      }),

      putConfigurationRule: builder.mutation<
        ConfigurationRuleResponse,
        PutConfigurationRulePayload
      >({
        queryFn: async ({ templateId, id, data }) => {
          const response = await TemplatesCollection.putTemplateConfigurationRule(
            templateId,
            id,
            data,
          );

          return { data: response.data.data };
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- returns no response
      patchConfigurationRule: builder.mutation<void, PatchConfigurationRulePayload>({
        queryFn: async ({ templateId, id, data }) => {
          await TemplatesCollection.patchTemplateConfigurationRule(templateId, id, data);

          return { data: undefined };
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- returns no response
      deleteConfigurationRule: builder.mutation<void, DeleteConfigurationRulePayload>({
        queryFn: async ({ templateId, id }) => {
          await TemplatesCollection.deleteTemplateConfigurationRule(templateId, id, {});

          return { data: undefined };
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- returns no response
      deleteConfigurationRules: builder.mutation<void, DeleteConfigurationRulesPayload>({
        queryFn: async ({ templateId }) => {
          await TemplatesCollection.deleteTemplateConfigurationRules(templateId, {});
          return { data: undefined };
        },
      }),

      getFinalStoreDepartmentConfiguration: builder.query<
        ScopeEntityConfigurationResponse[],
        TemplateOrCalculation
      >({
        queryFn: async arg => {
          const { data: scopeEntityConfigurations } = await (arg.type === 'Template'
            ? TemplatesCollection.getTemplateScopeEntityConfigurations(arg.id)
            : CalculationsCollection.getCalculationScopeEntityConfigurations(arg.id));

          return { data: scopeEntityConfigurations.data };
        },
        providesTags: [TAGS.finalScopeConfigurations],
      }),
    }),
  });

export const {
  useGetConfigurationRulesQuery,
  usePostConfigurationRuleMutation,
  usePutConfigurationRuleMutation,
  usePatchConfigurationRuleMutation,
  useDeleteConfigurationRuleMutation,
  useDeleteConfigurationRulesMutation,
  useGetFinalStoreDepartmentConfigurationQuery,
} = apiScopes;
