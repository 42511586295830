import { GenericInputModal } from '@ydistri/ds';
import React, { useCallback, useMemo, useState } from 'react';

import {
  ProductListUpdateRequest,
  useCreateProductListMutation,
  useUpdateProductListMutation,
} from '../apiProductLists';
import { addToast } from '@ydistri/utils';
import { setSelectedProductList } from '../productListAdministrationSlice';
import { ProductListResponse } from '@ydistri/api-sdk';
import { ModalMode } from '../../common/administrationItemsTypes';
import { ErrorType } from '../../../../apis/api';
import { useAppDispatch } from '../../../../store';

interface CreateProductListModalProps {
  onClose: () => void;
  productList?: ProductListResponse;
  confirmButtonLabel?: string;
  restrictedNames?: string[];
}

interface FormType {
  name: string;
}

const CreateProductListModal: React.FC<CreateProductListModalProps> = ({
  onClose,
  productList,
  confirmButtonLabel,
  restrictedNames,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [addProductList] = useCreateProductListMutation();
  const [updateProductList] = useUpdateProductListMutation();

  const modalMode = useMemo(() => {
    if (productList) {
      return ModalMode.EDIT;
    } else {
      return ModalMode.CREATE;
    }
  }, [productList]);

  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]); //setIsOpened

  const createProductList = useCallback(
    (values: FormType) => {
      if (!values.name || values.name.length === 0) {
        return;
      }

      setIsBusy(true);

      addProductList({ name: values.name })
        .unwrap()
        .then(result => {
          dispatch(setSelectedProductList(result));
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- error is of type ErrorType
        .catch((error: ErrorType) => {
          dispatch(
            addToast({
              message: `Product List was not created: ${error.response.data.Messages.join(', ')}`,
              isError: true,
            }),
          );
        })
        .finally(() => {
          setIsError(false);
          setIsBusy(false);
          closeModal();
        });
    },
    [closeModal, addProductList, dispatch],
  );

  const editProductList = useCallback(
    (values: FormType) => {
      if (productList) {
        setIsBusy(true);

        const request: ProductListUpdateRequest = {
          productListId: productList.productListId,
          name: values.name,
        };

        updateProductList(request)
          .unwrap()
          .then(result => {
            dispatch(setSelectedProductList(result));
          })
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- error is of type ErrorType
          .catch((error: ErrorType) => {
            dispatch(
              addToast({
                message: `Product List was not updated: ${error.response.data.Messages.join(', ')}`,
                isError: true,
              }),
            );
          })
          .finally(() => {
            closeModal();
          });
      }
    },
    [closeModal, dispatch, productList, updateProductList],
  );

  const onSubmit = useCallback(
    (value: string) => {
      if (modalMode === ModalMode.CREATE) {
        createProductList({ name: value });
      } else {
        editProductList({ name: value });
      }
    },
    [createProductList, editProductList, modalMode],
  );

  const validator = useCallback(
    (newValue: string) => {
      if (restrictedNames?.includes(newValue)) {
        return 'Product List with this name already exists, choose different name';
      }
    },
    [restrictedNames],
  );

  return (
    <GenericInputModal
      title={
        modalMode === ModalMode.CREATE
          ? 'Create Product List'
          : `Edit Product List "${productList?.name ?? ''}"`
      }
      placeholder="Name of your Product List"
      onConfirmed={onSubmit}
      onClose={closeModal}
      disabled={isBusy}
      submitButtonLabel={confirmButtonLabel ?? 'OK'}
      requiredMessage="Name of the Product List is required"
      error={isError}
      defaultValue={productList?.name ?? ''}
      data-testid="CreateProductListModal"
      validator={validator}
    />
  );
};
export default CreateProductListModal;
