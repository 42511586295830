import AdministrationItemValidationStats from '../../../common/validation-results/AdministrationItemValidationStats';
import React, { useCallback, useMemo, useState } from 'react';
import { ActionsBar, Column, GenericConfirmationModal, Row, YButton } from '@ydistri/ds';
import ShowOnlyErrorsToggle from '../../../common/validation-results/ShowOnlyErrorsToggle';
import {
  AdministrationItemListHandlingMethod,
  ResultsDisplayMode,
  StatisticsItem,
  StatisticsItemType,
} from '../../../common/administrationItemsTypes';
import {
  clearValidation,
  resetState,
  setActiveScreenType,
  setHandlingMethod,
  setIsLoading,
} from '../../productListAdministrationSlice';
import { useSelector } from 'react-redux';
import {
  IdentificationType,
  ProductListItemsIdentificationResponseDataResponse,
} from '@ydistri/api-sdk';
import { Result } from 'antd';

import {
  ActiveScreenType,
  containsErrors,
  extractValidationStatisticsAsMap,
  prepareValidatedItemsForPresentation,
} from '../../../common/administrationItemsLib';

import { addToast } from '@ydistri/utils';
import { createProductListItemsPayload, getParseableString } from '../../productListsLib';
import { ProductListsCollection } from '../../../../../swagger/collections';
import { AxiosResponse } from 'axios';

import { apiProductLists } from '../../apiProductLists';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import ValidationResultsConfirmUpdateButton from '../../../common/validation-results/ValidationResultsConfirmUpdateButton';
import ProductListValidatedItemsGrid from './ProductListValidatedItemsGrid';
import ProductListItemsTextArea from '../edit/ProductListItemsTextArea';
import ListItemDrawerEditor from '../../../common/edit/ListItemDrawerEditor';
import useValidateItems from '../../../ProductLists/useValidateItems';
import Prompt from '../../../../../components/global/Prompt/Prompt';
import { APITAGS, ErrorType } from '../../../../../apis/api';
import { ReduxState, useAppDispatch } from '../../../../../store';

const ProductListValidationResults: React.FC = () => {
  const selectedProductList = useSelector(
    (state: ReduxState) => state.productListsAdministration.selectedProductList,
  );
  const validation = useSelector(
    (state: ReduxState) => state.productListsAdministration.validation,
  );
  const handlingMethod = useSelector(
    (state: ReduxState) => state.productListsAdministration.handlingMethod,
  );
  const isLoading = useSelector((state: ReduxState) => state.productListsAdministration.isLoading);

  const dispatch = useAppDispatch();

  const [displayMode, setDisplayMode] = useState<ResultsDisplayMode>(ResultsDisplayMode.SUMMARY);
  const [showConfirmReplaceDialog, setShowConfirmReplaceDialog] = useState(false);

  //user can toggle between all items or only errors
  const [showOnlyErrors, setShowOnlyErrors] = useState(true);
  const [showEditor, setShowEditor] = useState(false);

  const toggleShowErrors = useCallback(() => {
    setShowOnlyErrors(prevValue => !prevValue);
  }, []);

  const validateItems = useValidateItems();

  const isBlocked = (validation?.items && validation.items.length > 0) ?? false;

  /**
   * Filter all validated items based on current filters, actions etc.
   */
  const data = useMemo(() => {
    if (!validation) {
      return [];
    }

    if (!handlingMethod) {
      return validation.items;
    }

    return prepareValidatedItemsForPresentation(validation.items, handlingMethod, showOnlyErrors);
  }, [validation, handlingMethod, showOnlyErrors]);

  const toggleEditor = useCallback(() => {
    setShowEditor((prevValue: boolean) => !prevValue);
  }, []);

  const onClearHandler = useCallback(() => {
    dispatch(clearValidation());
    dispatch(setHandlingMethod(undefined));
    dispatch(setActiveScreenType(ActiveScreenType.EDIT));
  }, [dispatch]);

  const copyContentToClipboard = useCallback(() => {
    const errorItems = validation?.items.filter(
      validatedItem => validatedItem.isError && !validatedItem.isDuplicate,
    );
    if (errorItems && errorItems.length > 0) {
      const plainErrorData = getParseableString(
        errorItems,
        validation?.result?.productIdentificationTypeId ?? IdentificationType.CustomerId,
      );

      navigator.clipboard
        .writeText(plainErrorData)
        .then(() => {
          dispatch(
            addToast({
              message: 'Error items copied to clipboard, open Excel and paste',
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              isError: true,
              message: 'There was an error when copying items to clipboard',
            }),
          );
        });
    }
  }, [validation, dispatch]);

  const closeConfirmationDialog = useCallback(() => {
    setShowConfirmReplaceDialog(false);
  }, []);

  const execute = useCallback(() => {
    closeConfirmationDialog();

    if (!(selectedProductList?.productListId || validation)) {
      return;
    }

    dispatch(setIsLoading(true));

    const payload = createProductListItemsPayload(
      validation?.items,
      validation?.result?.productIdentificationTypeId ?? undefined,
    );

    if (!payload) {
      return;
    }

    let request:
      | Promise<AxiosResponse<ProductListItemsIdentificationResponseDataResponse>>
      | undefined = undefined;

    if (handlingMethod && selectedProductList) {
      if (handlingMethod === AdministrationItemListHandlingMethod.ADD) {
        request = ProductListsCollection.patchProductListItems(
          selectedProductList.productListId,
          payload,
        );
      } else {
        request = ProductListsCollection.putProductListItems(
          selectedProductList.productListId,
          payload,
        );
      }

      request
        .then(() => {
          dispatch(clearValidation());
          dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
          dispatch(addToast({ message: `Product List updated successfully` }));

          //reload target lists for the left pane as number of warehouses and products might have changed
          dispatch(apiProductLists.util.invalidateTags([APITAGS.productLists.productLists]));
          dispatch(apiProductLists.util.invalidateTags([APITAGS.productLists.productListItems]));
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we know it's the right type
        .catch((error: ErrorType) => {
          dispatch(
            addToast({
              isError: true,
              message: `There was an error when saving the product list: ${error.response.data.Messages.join(
                ', ',
              )}`,
            }),
          );
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }
  }, [closeConfirmationDialog, selectedProductList, dispatch, validation, handlingMethod]);

  const onSubmitHandler = useCallback(() => {
    if (handlingMethod === AdministrationItemListHandlingMethod.ADD) {
      execute();
    } else {
      setShowConfirmReplaceDialog(true);
    }
  }, [handlingMethod, execute]);

  const showDetails = useCallback(() => {
    setDisplayMode(ResultsDisplayMode.DETAIL);
    if (data.length === 0) {
      setShowOnlyErrors(false);
    }
  }, [data]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we do some computation here
  const disableConfirmButton = useMemo(() => {
    if (validation) {
      const itemsMap = extractValidationStatisticsAsMap(validation.items, handlingMethod);
      const updates = itemsMap.get(StatisticsItemType.SUCCESS)?.observation ?? 0;

      switch (handlingMethod) {
        case AdministrationItemListHandlingMethod.ADD:
          return updates === 0;
        case AdministrationItemListHandlingMethod.REPLACE: {
          const removes = itemsMap.get(StatisticsItemType.REMOVED)?.observation ?? 0;
          return updates === 0 && removes === 0;
        }
        case undefined:
          return true;
      }
    }

    return true;
  }, [validation, handlingMethod]);

  const confirmButton = useMemo(() => {
    return (
      <ValidationResultsConfirmUpdateButton
        label={
          handlingMethod === AdministrationItemListHandlingMethod.ADD
            ? 'Add items to product list'
            : 'Replace product list items...'
        }
        onClick={onSubmitHandler}
        disabled={disableConfirmButton}
        size={displayMode === ResultsDisplayMode.SUMMARY ? 'large' : 'middle'}
      />
    );
  }, [disableConfirmButton, handlingMethod, onSubmitHandler, displayMode]);

  const validationStats = useMemo(() => {
    if (validation?.items) {
      return (
        <AdministrationItemValidationStats
          data={validation.items}
          handlingMethod={handlingMethod}
        />
      );
    }
  }, [handlingMethod, validation]);

  const successExtraItems = useMemo(() => {
    return (
      <Column $gap="3rem" $alignItems="center">
        <Row>{validationStats}</Row>
        <Row $gap="2rem">
          <YButton
            onClick={showDetails}
            data-type="show-detailed-mode"
            size="large"
            tooltip="Show detailed table of items that will be inserted, updated, removed or will have no change."
          >
            Show items
          </YButton>
          {confirmButton}
        </Row>
      </Column>
    );
  }, [confirmButton, showDetails, validationStats]);

  const renderSummaryBanner = useCallback(() => {
    if (validation) {
      const statsMap: Map<StatisticsItemType, StatisticsItem> = extractValidationStatisticsAsMap(
        validation.items,
        handlingMethod,
      );

      const successStats = statsMap.get(StatisticsItemType.SUCCESS);
      const removedStats = statsMap.get(StatisticsItemType.REMOVED);
      const unchangedStats = statsMap.get(StatisticsItemType.UNCHANGED);
      const errorStats = statsMap.get(StatisticsItemType.ERROR);

      const someSuccess = successStats && successStats.observation > 0;
      const someRemoved = removedStats && removedStats.observation > 0;
      const someUnchanged = unchangedStats && unchangedStats.observation > 0;
      const someErrors = errorStats && errorStats.observation > 0;
      const noSuccess = successStats === undefined || successStats.observation === 0;
      const noRemoved = removedStats === undefined || removedStats.observation === 0;

      if (!someSuccess && !someRemoved && someUnchanged) {
        return (
          <Result
            status="info"
            title="No change to the product list!"
            subTitle="All items were validated successfully but there is no change to the product list."
            extra={successExtraItems}
          />
        );
      }

      if (noSuccess && noRemoved) {
        const subTitle = someErrors
          ? 'There are items with errors. Please fix them and try again.'
          : 'No items to be added or updated.';
        return (
          <Result
            status="info"
            title="No change to the product list"
            subTitle={subTitle}
            extra={successExtraItems}
          />
        );
      }

      return (
        <Result
          status="info"
          title="Items validated, changes can be confirmed."
          subTitle={`There ${successStats?.observation === 1 ? 'is' : 'are'} ${
            successStats?.observation ?? ''
          } ${successStats?.observation === 1 ? 'update' : 'updates'}. Click the button below to ${
            handlingMethod === AdministrationItemListHandlingMethod.ADD ? 'add items to' : 'replace'
          } the Product List.`}
          extra={successExtraItems}
        />
      );
    }
  }, [handlingMethod, successExtraItems, validation]);

  const actionsBar = useMemo(() => {
    return (
      <ActionsBar moveToRight data-testid="ProductListValidationResultsActions">
        {displayMode === ResultsDisplayMode.DETAIL && (
          <ShowOnlyErrorsToggle showOnlyErrors={showOnlyErrors} onClick={toggleShowErrors} />
        )}
        {!showEditor && (
          <YButton
            type="default"
            onClick={toggleEditor}
            data-type="show-editor"
            tooltip="Show the editor to paste more items"
          >
            Add more items...
          </YButton>
        )}
        <YButton
          type="default"
          onClick={onClearHandler}
          data-type="clear"
          tooltip="Remove all pasted items and start over"
          tooltipPlacement="topRight"
        >
          Clear
        </YButton>
        {containsErrors(validation?.items) && (
          <YButton
            onClick={copyContentToClipboard}
            tooltip="Copy items in error to clipboard and paste them to Excel"
          >
            Copy errors for Excel
          </YButton>
        )}
        {displayMode === ResultsDisplayMode.DETAIL && confirmButton}
      </ActionsBar>
    );
  }, [
    confirmButton,
    copyContentToClipboard,
    displayMode,
    onClearHandler,
    showEditor,
    showOnlyErrors,
    toggleEditor,
    toggleShowErrors,
    validation?.items,
  ]);

  const processAdditionalItems = useCallback(() => {
    toggleEditor();
    validateItems();
  }, [toggleEditor, validateItems]);

  const onNavigateAway = useCallback(() => {
    dispatch(clearValidation());
    dispatch(resetState());
  }, [dispatch]);

  const body = useMemo(() => {
    switch (displayMode) {
      case ResultsDisplayMode.SUMMARY:
        return renderSummaryBanner();
      case ResultsDisplayMode.DETAIL: {
        return <ProductListValidatedItemsGrid data={data} />;
      }
    }
  }, [data, displayMode, renderSummaryBanner]);

  if (isLoading) {
    return <LoadingIndicator text="Updating..." />;
  }

  return (
    <>
      <Prompt
        message="Your pending changes will be lost. Are you sure you want to leave?"
        blocked={isBlocked}
        cleanup={onNavigateAway}
      />
      <Column $gap="1rem" $flexGrow={1}>
        {selectedProductList && (
          <>
            <Row>
              {displayMode === ResultsDisplayMode.DETAIL && validationStats}
              {actionsBar}
            </Row>
            <Row $alignItems="stretch" $gap="2rem" $flexGrow={1}>
              <Column $flexGrow={1}>{body}</Column>
            </Row>
          </>
        )}

        <ListItemDrawerEditor
          onCancel={toggleEditor}
          onConfirm={processAdditionalItems}
          open={showEditor}
          title="Add more items to the Product List"
        >
          <ProductListItemsTextArea label="Items" />
        </ListItemDrawerEditor>

        {showConfirmReplaceDialog && (
          <GenericConfirmationModal
            title="Replace Product List items"
            message="Current Product List's content will be cleared and new items inserted. Continue?"
            onConfirmed={execute}
            onCanceled={closeConfirmationDialog}
            data-testid="ReplaceProductListConfirmationDialog"
          />
        )}
      </Column>
    </>
  );
};

export default ProductListValidationResults;
