import { ConfigurationFieldType } from '@ydistri/api-sdk';

export interface EntityListConfiguration {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- mandated by API
  EntityListId: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- mandated by API
  IsEnabled: number;
}

export const parseEntityListConfigs = (input: string): EntityListConfiguration[] =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we trust the input
  JSON.parse(input) as EntityListConfiguration[];

export const isEntityListTarget = (fieldType: ConfigurationFieldType): boolean =>
  fieldType === ConfigurationFieldType.PtMinLayerList;

export const createEntityListConfig = (
  entityListId: number,
  isEnabled: number,
): EntityListConfiguration => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention -- mandated by API
  EntityListId: entityListId,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- mandated by API
  IsEnabled: isEnabled,
});
