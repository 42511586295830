import { CalculationMode, CalculationStatus, CalculationType } from '@ydistri/api-sdk';
import React, { useCallback } from 'react';
import { Column, Row, Text, Title } from '@ydistri/ds';
import CalculationDescription from '../../common/CalculationDescription';
import { Divider } from 'antd';
import PrivateCalculationIndicator from './PrivateCalculationIndicator';
import ProductionCalculationIndicator from './ProductionCalculationIndicator';
import { styled } from 'styled-components';
import CalculationActions from '../../actions/CalculationActions';
import OverlapIndicator from './OverlapIndicator';
import PapCalculationIndicator from './PapCalculationIndicator';
import ToggleSubmergedCalculationsAction from '../../actions/ToggleSubmergedCalculationsAction';
import { emptyCalculationsWithOverviewResponse } from './calculationRowLib';
import { CalculationDataType } from '../../calculationsTypes';

const splitter = <Divider type="vertical" />;

const CalculationDecoration = styled(Row)`
  margin-left: auto;
`;

interface CalculationInfoComponentProps {
  calculation: CalculationDataType;
  onDelete?: (calculation: CalculationDataType) => void;
  onToggleSubmergedCalculations?: (calculationId: number) => void;
  onToggleStatistics?: (calculationId: number) => void;
  disabled?: boolean;
  showActions?: boolean;
  manual?: boolean;
  compact?: boolean;
  expandableSubmerged?: boolean;
}

/**
 * Displays calculation's title, source template and description.
 * Icon indicates the calculation's type.
 * @param calculation
 * @param onDelete
 * @param onToggleStatistics
 * @param disabled
 * @param showActions
 * @param manual
 * @param compact
 * @constructor
 */
const CalculationRowTitle: React.FC<CalculationInfoComponentProps> = ({
  calculation,
  onDelete,
  onToggleSubmergedCalculations,
  onToggleStatistics,
  disabled = false,
  showActions = true,
  compact = false,
  expandableSubmerged = true,
}) => {
  const calculationMode = calculation.mode;

  const templateTitle =
    'sourceTemplate' in calculation ? calculation.sourceTemplate?.title : 'Deleted template';
  const overlappingCalculations =
    'overlappingCalculations' in calculation
      ? (calculation.overlappingCalculations ?? emptyCalculationsWithOverviewResponse)
      : emptyCalculationsWithOverviewResponse;
  const submergedCalculations =
    'subordinateCalculations' in calculation
      ? (calculation.subordinateCalculations ?? emptyCalculationsWithOverviewResponse)
      : emptyCalculationsWithOverviewResponse;

  const toggleSubmergedCalculationsHandler = useCallback(() => {
    if (onToggleSubmergedCalculations) {
      onToggleSubmergedCalculations(calculation.id);
    }
  }, [calculation, onToggleSubmergedCalculations]);

  return (
    <Row $flexWrap="nowrap" $gap="1rem" $alignItems="center">
      {expandableSubmerged && submergedCalculations.length > 0 && (
        <ToggleSubmergedCalculationsAction
          onClick={toggleSubmergedCalculationsHandler}
          size="small"
        />
      )}
      <Column>
        <Title $size="small" $semiBold>
          {calculation.title}
        </Title>
        {!compact && (
          <Row $gap="0.5rem" $alignItems="center">
            <Text $type="light">
              {calculationMode !== CalculationMode.Computed
                ? `${calculationMode} calculation`
                : templateTitle}
            </Text>
            {calculation.description && (
              <>
                {splitter}
                <CalculationDescription value={calculation.description} />
              </>
            )}
          </Row>
        )}
      </Column>
      <CalculationDecoration
        $gap="1rem"
        $flexWrap="nowrap"
        $alignItems="center"
        id={`calculation-decoration-${calculation.id}`}
      >
        {overlappingCalculations.length > 0 && calculation.status !== CalculationStatus.Crashed && (
          <OverlapIndicator overlappingCalculations={overlappingCalculations} compact={compact} />
        )}
        {calculation.type === CalculationType.Private && (
          <PrivateCalculationIndicator compact={compact} />
        )}
        {calculation.isPap && <PapCalculationIndicator compact={compact} />}
        {calculation.type === CalculationType.Production && (
          <ProductionCalculationIndicator
            finishExecutionUntil={calculation.finishExecutionUntil}
            compact={compact}
          />
        )}
        {showActions && (
          <CalculationActions
            calculation={calculation}
            onDelete={onDelete}
            onToggleStatistics={onToggleStatistics}
            disabled={disabled}
            id="calculation-row-actions"
          />
        )}
      </CalculationDecoration>
    </Row>
  );
};

export default CalculationRowTitle;
