import React, { useMemo } from 'react';
import { SimpleTable } from '../../CalculationDetail/Statistics/components/SimpleTable';
import { BalancingFieldRow, BalancingFieldRowInfo } from './balancingLib';
import { ColumnsType } from 'antd/es/table';
import { computeRemSize } from '@ydistri/ds';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetBalancingConfigurationQuery } from './apiBalancingConfiguration';
import { CalculationConfigurationExperimentalType } from '@ydistri/api-sdk';
import BalancingField from './BalancingField';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { useUser } from '../../../hooks/useUser';

interface BalancingFieldTableProps {
  fieldKey: CalculationConfigurationExperimentalType;
}

const BalancingFieldTable: React.FC<BalancingFieldTableProps> = ({ fieldKey }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: balancingData, isFetching } =
    useGetBalancingConfigurationQuery(templateOrCalculation);
  const user = useUser();

  const showHeader = !!balancingData?.configuration[fieldKey]?.groupedWith;

  const usedFields = useMemo(
    () => [fieldKey, ...(balancingData?.configuration[fieldKey]?.groupedWith ?? [])],
    [balancingData?.configuration, fieldKey],
  );

  const columns: ColumnsType<BalancingFieldRow> = useMemo(() => {
    if (!balancingData) return [];

    const cols: ColumnsType<BalancingFieldRow> = [];

    usedFields.forEach(f => {
      const field = balancingData.configuration[f];
      cols.push({
        title: field?.columnLabel ?? '',
        dataIndex: ['data', f],
        width: computeRemSize(80),
        render: (value: BalancingFieldRowInfo) => (
          <BalancingField
            disabled={
              templateOrCalculation.type === 'Calculation' ||
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- it may be false
              balancingData.configuration[f]?.alwaysDisabled ||
              !user?.isSuperUser
            }
            dataType={value.type}
            field={f}
            value={value.value}
            itemSequence={value.itemSequence}
            customSuffix={value.customSuffix}
            min={value.min}
            max={value.max}
            replaceWith={value.replaceWith}
          />
        ),
      });
    });

    return cols;
  }, [balancingData, templateOrCalculation.type, usedFields, user?.isSuperUser]);

  const data: BalancingFieldRow[] = useMemo(() => {
    if (!balancingData) return [];
    const vals = balancingData.configuration[fieldKey]?.values;

    return (
      vals?.map((v, i) => {
        const obj: BalancingFieldRow = {
          index: i,
          data: {},
        };

        usedFields.forEach(f => {
          const bd = balancingData.configuration[f];

          if (bd) {
            let replaceWith = undefined;
            if (bd.lastValuePlaceholder && i === vals.length - 1) {
              replaceWith = bd.lastValuePlaceholder;

              //currently, now only {prevValue} is implemented
              if (vals[i - 1]) {
                replaceWith = replaceWith.replace('{prevValue}', vals[i - 1].toString());
              }
            }

            obj.data[f] = {
              type: bd.type,
              value: bd.values[i],
              itemSequence: i,
              customSuffix: bd.customSuffix,
              min: bd.min,
              max: bd.max,
              replaceWith,
            };
          }
        });

        return obj;
      }) ?? []
    );
  }, [balancingData, fieldKey, usedFields]);

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <SimpleTable<BalancingFieldRow>
      id={`balancing-fields-admin-${fieldKey}`}
      rowKey="key"
      dataSource={data}
      columns={columns}
      showHeader={showHeader}
      loading={false}
    />
  );
};

export default BalancingFieldTable;
