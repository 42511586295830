import { styled } from 'styled-components';
import React, { useMemo } from 'react';
import { computeRemSize } from '@ydistri/ds';

interface AdministrationListItemNameProps {
  $width?: string;
}

export const AdministrationListItemName = styled.div<AdministrationListItemNameProps>`
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $width }) => $width && `width: ${$width};`}
`;

export const Wrapper = styled.div`
  color: #878585;
  font-size: 1rem;
  line-height: ${computeRemSize(14)};
`;

export const AdministrationListItemCountWrapper = styled.span`
  display: inline-block;
  margin-right: 0.4rem;
`;

export interface AdministrationListItemCountProps {
  children: React.JSX.Element | React.JSX.Element[];
}

const AdministrationListItemCount: React.FC<AdministrationListItemCountProps> = ({ children }) => {
  const body = useMemo(() => {
    if (Array.isArray(children)) {
      return children.map(item => (
        <AdministrationListItemCountWrapper key={item.key}>
          {item}
        </AdministrationListItemCountWrapper>
      ));
    } else {
      return <AdministrationListItemCountWrapper>{children}</AdministrationListItemCountWrapper>;
    }
  }, [children]);

  return <Wrapper>{body}</Wrapper>;
};

export default AdministrationListItemCount;
