import { CompareType } from '@ydistri/api-sdk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TargetListValidatedItem } from '../../targetListsTypes';
import { AdministrationItemAction } from '../../../common/administrationItemsTypes';
import ValidatedItemAction from '../../../common/validation-results/ValidatedItemAction';
import { styled } from 'styled-components';
import {
  computeRemSize,
  Panel,
  ScrolledTable,
  useInjectLocalFilters,
  YColumnsType,
} from '@ydistri/ds';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

//row colors are specified like this because of specificity
//issues with antd styles for cell borders
const ValidatedItemsTable = styled(ScrolledTable<TargetListValidatedItem>)`
  tr:hover td {
    background: none !important;
  }

  tr.errorItem {
    background-color: rgba(195, 14, 5, 0.3);

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background-color: rgba(179, 13, 5, 0.35);
    }

    td {
      border-bottom: ${computeRemSize(1)} solid rgba(176, 13, 5, 0.3);
    }
  }

  tr.newItem {
    background-color: #dff1df;

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background-color: #d4ecd4;
    }

    td {
      border-bottom: ${computeRemSize(1)} solid #c9d9c9;
    }
  }

  tr.removedItem {
    background-color: rgba(195, 14, 5, 0.15);

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background: rgba(179, 13, 5, 0.2);
    }

    td {
      border-bottom: ${computeRemSize(1)} solid rgba(195, 14, 5, 0.2);
    }
  }

  tr.updatedItem {
    background-color: #fdf0dd;

    &:hover,
    .ant-table-cell.ant-table-column-sort {
      background: #fce6c5;
    }

    td {
      border-bottom: ${computeRemSize(1)} solid #e4d8c7;
    }
  }
`;

const errorSort = (left: TargetListValidatedItem, right: TargetListValidatedItem): number => {
  if (!left.isError && right.isError) {
    return -1;
  }
  if (left.isError && !right.isError) {
    return 1;
  }

  return 0;
};

interface TargetListValidatedItemsGridProps {
  selectedTargetListId: number;
  data: TargetListValidatedItem[];
}

const TargetListValidatedItemsGrid: React.FC<TargetListValidatedItemsGridProps> = ({ data }) => {
  const ref = useRef<HTMLDivElement>(null);
  const handlingMethod = useSelector(
    (state: ReduxState) => state.targetListAdministration.handlingMethod,
  );
  const { productIdColumnWidth } = useColumnWidth();
  const [tableHeight, setTableHeight] = useState(480);
  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const resizeTable = useCallback(() => {
    const TABLE_OFFSET = 16;
    if (ref.current) {
      let tableHeaderSize = 37; //to compensate for the table's header as it is rendered outside the table's container
      const tableHeader = ref.current.querySelector('.ant-table-header');
      if (tableHeader) {
        tableHeaderSize = tableHeader.clientHeight;
      }

      setTableHeight(ref.current.clientHeight - (tableHeaderSize + TABLE_OFFSET));
    }
  }, [ref]);

  useEffect(() => {
    resizeTable();

    window.addEventListener('resize', resizeTable);

    return () => window.removeEventListener('resize', resizeTable);
  }, [resizeTable]);

  const getActionTooltip = useCallback(
    (validatedItem: TargetListValidatedItem) => {
      if (validatedItem.isError) {
        return validatedItem.errors?.join('; ') ?? '';
      } else {
        switch (validatedItem.compareType) {
          case CompareType.Missing:
            return 'New item';
          case CompareType.Contains:
            if (handlingMethod) {
              const action = validatedItem.actions[handlingMethod];
              if (action === AdministrationItemAction.REMOVE) {
                return 'Item was not included in the source data and will be removed';
              }
            }

            return 'The Target List already contains this combination of store and product of the same quantity';
          case CompareType.Updated:
            return 'The quantity will be changed';
          case undefined: {
            throw new Error('Not implemented yet: undefined case');
          }
        }
      }
    },
    [handlingMethod],
  );

  const actionCellRenderer = useCallback(
    (value: string, record: TargetListValidatedItem) => {
      if (handlingMethod) {
        const action = record.actions[handlingMethod];
        const tooltip = getActionTooltip(record);
        return (
          <ValidatedItemAction
            action={action}
            error={record.isError}
            duplicate={record.isDuplicate}
            tooltip={tooltip}
          />
        );
      }
    },
    [getActionTooltip, handlingMethod],
  );

  const columns = useMemo(() => {
    const dataPath = ['data', 'warehouse'];
    const columnsConfig: EntityColumnsConfig<TargetListValidatedItem> = {
      [EntityColumns.ID]: {
        width: '6rem',
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }

          return (
            left.data.warehouse.customerWarehouseId?.localeCompare(
              right.data.warehouse.customerWarehouseId ?? '',
            ) ?? 0
          );
        },
      },
      [EntityColumns.NAME]: {
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }

          return left.data.warehouse.name.localeCompare(right.data.warehouse.name);
        },
      },
      [EntityColumns.CODE]: {
        width: '8rem',
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }
          return left.data.warehouse.code?.localeCompare(right.data.warehouse.code ?? '') ?? 0;
        },
      },
    };

    const storeColumns = storeColumnsProvider(
      columnsConfig,
      dataPath,
      undefined,
      'customerWarehouseId',
    );

    const productColumnsConfig: EntityColumnsConfig<TargetListValidatedItem> = {
      [EntityColumns.ID]: {
        width: productIdColumnWidth,
        render: (value: string | number, record: TargetListValidatedItem) => {
          if (record.data.product.id) {
            return record.data.product.id;
          } else {
            return record.data.product.customerId;
          }
        },
      },
      [EntityColumns.NAME]: {
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }

          return left.data.product.name.localeCompare(right.data.product.name);
        },
      },
      [EntityColumns.CODE]: {
        width: '8rem',
        sorter: (left, right) => {
          const result = errorSort(left, right);
          if (result !== 0) {
            return result;
          }

          return left.data.product.code.localeCompare(right.data.product.code);
        },
      },
    };
    const productColumns = productColumnsProvider<TargetListValidatedItem>(productColumnsConfig, [
      'data',
      'product',
    ]);

    const tmpColumns: YColumnsType<TargetListValidatedItem>[] = [
      ...storeColumns,
      ...productColumns,
      {
        title: 'Receive Quantity',
        key: 'quantity',
        dataIndex: ['data', 'quantity'],
        width: '10rem',
        align: 'right',
        sorter: (left, right) => left.data.quantity - right.data.quantity,
        apiFilterable: true,
        apiFilterType: 'number',
      },
      {
        title: 'Action',
        key: 'action',
        width: '7rem',
        align: 'center',
        dataIndex: ['compareType'],
        render: actionCellRenderer,
      },
    ];

    return tmpColumns;
  }, [storeColumnsProvider, productIdColumnWidth, productColumnsProvider, actionCellRenderer]);
  const filterableColumns = useInjectLocalFilters<TargetListValidatedItem>(columns);

  const rowClassNameProvider = useCallback(
    (validatedItem: TargetListValidatedItem) => {
      if (validatedItem.isError) {
        return 'errorItem';
      }

      if (validatedItem.compareType === CompareType.Missing) {
        return 'newItem';
      }

      if (handlingMethod) {
        if (validatedItem.actions[handlingMethod] === AdministrationItemAction.REMOVE) {
          return 'removedItem';
        }
      }

      if (validatedItem.compareType === CompareType.Updated) {
        return 'updatedItem';
      }

      return '';
    },
    [handlingMethod],
  );

  const rowKey = useCallback(
    (record: TargetListValidatedItem) =>
      `${record.data.product.customerId}-${record.data.warehouse.customerWarehouseId}`,
    [],
  );

  return (
    <Panel ref={ref} id="ValidatedItemsTableWrapper">
      <ValidatedItemsTable
        rowKey={rowKey}
        id="ValidatedItemsTable"
        dataSource={data}
        columns={filterableColumns}
        size="small"
        rowClassName={rowClassNameProvider}
        height={computeRemSize(tableHeight)}
      />
    </Panel>
  );
};

export default TargetListValidatedItemsGrid;
