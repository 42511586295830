import { RoutingPage, RoutingSubpage } from '../../routes/routerSlice';
import { AiOutlineControl, AiOutlineProject, AiOutlineSetting } from 'react-icons/ai';
import React from 'react';
import { MdCalculate } from 'react-icons/md';

interface Subpage {
  subpage: RoutingSubpage;
  label: string;
  shortcut: string;
}

interface MainPage {
  id: RoutingPage;
  label: string;
  to: string;
  icon: React.ReactNode;
  childRoute?: string;
  children: Subpage[];
  showChildrenOn?: RoutingPage[];
}

export const ROUTES = {
  detail: `/:projectShortName/${RoutingPage.DETAIL}/:slug`,
  userSettings: `/:projectShortName/${RoutingPage.USER_SETTINGS}`,
  calculations: `/:projectShortName/${RoutingPage.CALCULATIONS}`,
  calculationDetail: `/:projectShortName/${RoutingPage.CALCULATION_DETAIL}/:calculationId/:subpage/:slug`,
  calculationDetailPickingResults: `/:projectShortName/${RoutingPage.CALCULATION_DETAIL}/:calculationId/:subpage/:pickingResultsPart/:slug`,
  configuration: `/:projectShortName/${RoutingPage.CONFIGURATION}/:templateId/categories/:slug`,
  configurationChildRoute: `/:projectShortName/${RoutingPage.CONFIGURATION}/:templateId/:subpage/:slug`,
  projectAdministration: `/:projectShortName/${RoutingPage.PROJECT_ADMINISTRATION}/productlists`,
  projectAdministrationChildRoute: `/:projectShortName/${RoutingPage.PROJECT_ADMINISTRATION}/:subpage`,
};

export const menuLeftItemTemplate: MainPage[] = [
  {
    id: RoutingPage.DETAIL,
    label: 'Detail',
    to: ROUTES.detail,
    icon: <AiOutlineProject />,
    children: [],
  },
  {
    id: RoutingPage.CONFIGURATION,
    label: 'Configuration',
    to: ROUTES.configuration,
    icon: <AiOutlineControl />,
    childRoute: ROUTES.configurationChildRoute,
    children: [],
  },
  {
    id: RoutingPage.CALCULATIONS,
    label: 'Calculations',
    to: ROUTES.calculations,
    icon: <MdCalculate />,
    childRoute: ROUTES.calculationDetail,
    showChildrenOn: [RoutingPage.CALCULATION_DETAIL],
    children: [],
  },
  {
    id: RoutingPage.PROJECT_ADMINISTRATION,
    label: 'Administration',
    to: ROUTES.projectAdministration,
    icon: <AiOutlineSetting />,
    childRoute: ROUTES.projectAdministrationChildRoute,
    children: [],
  },
];
