/* eslint-disable @typescript-eslint/naming-convention -- mandated by API */

import { APITAGS } from './api';
import { ApiParams } from '@ydistri/ds';
import { RequestParams } from '@ydistri/api-sdk';

export type ApiTagGroup = keyof typeof APITAGS;
export type ApiTag<T extends ApiTagGroup> = (typeof APITAGS)[T][keyof (typeof APITAGS)[T]];

type BottomLevelValues<T> = {
  [K in keyof T]: T[K] extends object ? BottomLevelValues<T[K]> : T[K];
}[keyof T];

export type ApiTags = BottomLevelValues<typeof APITAGS>;

/** Returns the object of tags for a given "tag group"
 *
 * @param group - the tag group name
 *
 * @returns the object of tags for the given tag group
 *
 * @example
 *  {
 *     Projects: 'Projects',
 *     Templates: 'Templates',
 *     ApplicationData: 'ApplicationData',
 *     ApplicationHints: 'ApplicationHints',
 *     CategoryConfigurationDefinitions: 'CategoryConfigurationDefinitions',
 *     CurrentSetup: 'CurrentSetup'
 *   }
 */
export function getTagObject<T extends ApiTagGroup>(group: T): (typeof APITAGS)[T] {
  return APITAGS[group];
}

/** Type guard for ApiTagGroup
 * Returns true if the given data is an ApiTagGroup object
 *
 * @param data
 */
function isApiTagGroupObject<T extends ApiTagGroup>(
  data: T | (typeof APITAGS)[T],
): data is (typeof APITAGS)[T] {
  return typeof data === 'object';
}

/** Returns an array of tags for a given "tag group"
 *
 * @param data - the tag group name OR the object of tags for the given tag group
 *
 * @returns the array of tags for the given tag group
 *
 * @example
 * //
 * const variant1 = getTagArray('application');
 *
 * const variant2_object = getTagObject('application');
 * //...some code with variant2_object
 * const variant2 = getTagArray(variant2_object);
 * //for better type safety, you can use the following instead of variant2:
 * const variant2 = getTagArray<'application'>(variant2_object);
 */
export function getTagArray<T extends ApiTagGroup>(data: T | (typeof APITAGS)[T]): ApiTag<T>[] {
  if (isApiTagGroupObject(data)) {
    // if data is an object, then it's an ApiTagGroup object
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type it like this
    return Object.values(data).flat() as ApiTag<T>[];
  } else {
    // otherwise it's an ApiTagGroup name
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type it like this
    return Object.values(APITAGS[data]) as ApiTag<T>[];
  }
}

/** Returns an object with the array of tags and the object of tags for a given "tag group"
 *  This is a convenience function that returns both the object and the array of tags for a given "tag group"
 * @param group
 *
 * @returns an object with the array of tags and the object of tags for a given "tag group"
 *
 * @example
 * const { TAGS, TAGS_ARRAY } = getTags('application');
 *
 * export const apiApplication = apiSlice
 *   .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
 *   .injectEndpoints({
 *     endpoints: builder => ({
 *       // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
 *       getUserProjects: builder.query<ProjectResponse[], void>({
 *         queryFn: async () => {
 *           const { data } = await ProjectsCollection.projectsList();
 *           return data;
 *         },
 *         providesTags: [TAGS.projects],
 *       }),
 *     }),
 *   });
 *
 *
 *
 */
export function getTags<T extends ApiTagGroup>(
  group: T,
): {
  TAGS: (typeof APITAGS)[T];
  TAGS_ARRAY: ApiTag<T>[];
} {
  return {
    TAGS: getTagObject<T>(group),
    TAGS_ARRAY: getTagArray<T>(group),
  };
}

/**
 * Construct request params for API calls.
 * @param params - API params.
 * @returns object that can be used to perform Axios calls
 */
export const constructRequestParams = (params: ApiParams): RequestParams | undefined => {
  if (params.projectCode) {
    return {
      headers: {
        'Project-Code': params.projectCode,
      },
    };
  }
};
