/**
 * Content of the administration item as entered by the user (pasted or typed in) and parsed by the frontend
 */
export interface ImportedItemRow<T> {
  /** Where in the source content the item was found */
  rowNumber: number;
  /** The content of the item as entered by the user and parsed to specific attributes  */
  inputData: Partial<T>;
  /** True if there is an error in the data (invalid or missing values, duplicates) */
  isError?: boolean;
  /** List of errors in the data (if there are any) */
  errors?: string[];
  /** Specific attribute to indicate that the item is a duplicate of another item in the list. Duplicate item is also an error */
  isDuplicate?: boolean;
}

export type ImportedItemParserFunction<T extends object> = (
  content: string,
  keys: (keyof T)[],
  parser?: DOMParser,
) => ImportedItemRow<T>[];

export interface ImportedItemParser<T extends object> {
  contentType: string;
  parse: ImportedItemParserFunction<T>;
}

/**
 * Parse html table in content string. The table should have three columns with warehouse identification (string), product
 * identification (string) and quantity (number)
 * @param content  HTML containing a table to be parsed
 * @param keys
 * @param parser DOMParser to be used for parsing. If not present a default DOMParser will be used.
 */
export const parseTabularDataFromHtml = <T extends object>(
  content: string,
  keys: (keyof T)[],
  parser?: DOMParser,
): ImportedItemRow<T>[] => {
  if (!parser) {
    parser = new DOMParser();
  }

  const htmlDoc = parser.parseFromString(content, 'text/html');
  const rows = htmlDoc.querySelectorAll('table tr');

  const result: ImportedItemRow<T>[] = [];

  rows.forEach((row, index) => {
    const cellNodes = row.querySelectorAll('td');
    const cells: string[] = [];
    cellNodes.forEach(cellNode => cells.push(cellNode.firstChild?.textContent ?? ''));

    const rowData: T = getItemFromStrings(cells, keys);
    const errors = validateItem(rowData);

    const resultRow: ImportedItemRow<T> = {
      rowNumber: index + 1,
      inputData: rowData,
    };

    if (errors.length > 0) {
      resultRow.isError = true;
      resultRow.errors = errors;
    }

    result.push(resultRow);
  });

  return result;
};

/**
 * Parse the content string and return structured data.
 * The string should contain rows of three columns separated by either a space, comma, pipe, semicolon or tab.
 * @param content  plain text containing data to be parsed
 * @param keys
 */
export const parseTabularDataFromPlainText = <T extends object>(
  content: string,
  keys: (keyof T)[],
): ImportedItemRow<T>[] => {
  const result: ImportedItemRow<T>[] = [];

  const rows = content.split(/\n/).filter(tmpRow => tmpRow.length > 0);
  rows.forEach((row, index) => {
    const cells = row.split(/[(,|;)]+|\t/);
    const rowData = getItemFromStrings<T>(cells, keys);
    const errors = validateItem(rowData);

    const resultRow: ImportedItemRow<T> = {
      rowNumber: index + 1,
      inputData: rowData,
    };

    if (errors.length > 0) {
      resultRow.isError = true;
      resultRow.errors = errors;
    }

    result.push(resultRow);
  });

  return result;
};

/* eslint-disable @typescript-eslint/consistent-type-assertions -- we want to be as much generic as possible */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- we want to be as much generic as possible */
export const getItemFromStrings = <T extends object>(cells: string[], keys: (keyof T)[]): T => {
  const obj = {} as T;

  keys.forEach((key, index) => {
    const value = cells[index];

    // Check if the target type for this property in T is number and convert it
    if (typeof obj[key] === 'number') {
      obj[key] = Number(value) as T[keyof T];
    } else {
      obj[key] = value as T[keyof T];
    }
  });

  return obj;
};

/* eslint-disable @typescript-eslint/no-explicit-any -- we want to be generic */
/* eslint-disable @typescript-eslint/consistent-type-assertions -- we want to be as much generic as possible */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- we want to be as much generic as possible */
/* eslint-disable @typescript-eslint/no-unnecessary-type-parameters -- we want to be as much generic as possible */
export const validateItem = <T extends object>(item: T): string[] => {
  const errors: string[] = [];
  const obj = {} as T;

  const isString = (val: unknown): val is string => typeof val === 'string';

  Object.keys(obj).forEach(key => {
    const typedKey = key as keyof T; // Tell TypeScript that key is a key of T
    const value = item[typedKey];

    // Check if the target type for this property in T is number and convert it
    if (typeof (obj as any)[key] === 'number') {
      const numericValue = Number(value);
      if (Number.isNaN(numericValue)) {
        errors.push(`${key} is not a number`);
      } else {
        if (numericValue <= 0) {
          errors.push(`${key} must be a positive number`);
        }
      }
    } else if (isString(value)) {
      if (value.length === 0) {
        errors.push(`${key} is missing or empty`);
      }
    } else if (value === null || value === undefined) {
      errors.push(`${key} is missing or empty`);
    }
  });

  return errors;
};

export const selectParser = <T extends object>(
  data: DataTransfer,
  parsers: ImportedItemParser<T>[],
): ImportedItemParser<T> | null => {
  const dataTypes = data.types;

  const suitableParser = parsers.find(parser => dataTypes.includes(parser.contentType));
  if (suitableParser) {
    return suitableParser;
  } else {
    return null;
  }
};
