import { useParams } from 'react-router';
import { PickingResultsPart } from '../pickingResultsLib';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- I want to dynamically create the map
const pickingResultsPartMap: Record<string, PickingResultsPart> = Object.fromEntries(
  Object.values(PickingResultsPart).map(value => [value, value]),
) as Record<string, PickingResultsPart>;

/**
 * Translates string parameter from url to enum member in PickingResultsPart
 * @param value
 */
export const getPickingResultsPart = (value: string): PickingResultsPart | undefined => {
  return pickingResultsPartMap[value];
};

/**
 * Returns current picking results part from the URL.
 * Valid only withing picking-results route otherwise returns the EXECUTION part
 */
export const usePickingResultsPart = (): PickingResultsPart => {
  const defaultPart = PickingResultsPart.EXECUTION;
  const { pickingResultsPart } = useParams();
  if (pickingResultsPart) {
    return getPickingResultsPart(pickingResultsPart) ?? defaultPart;
  } else {
    return defaultPart;
  }
};
