import { MainContent, MainContentWrapper } from '@ydistri/ds';
import { Helmet } from 'react-helmet-async';
import CategoryTree from '../../components/global/CategoryTree/CategoryTree';
import React, { useCallback, useEffect } from 'react';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router';
import { UrlParams } from './detailTypes';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import { useAppDispatch } from '../../store';
import DetailProductsListSection from './sections/DetailProductListSection/DetailProductsListSection';
import { ProductResponse } from '@ydistri/api-sdk';
import { useDetail } from './hooks/useDetail';
import { getSkuById } from './detailLib';
import {
  resetSku,
  setIsLoadingSalesData,
  setProductId,
  setRegionId,
  setStoreId,
  skuFound,
  skuNotFound,
} from './detailSlice';
import { NO_VALUE } from '../../lib/utils/utilsTypes';
import { createDebugLog } from '../../lib/utils/logging';
import DetailContent from './sections/components/DetailContent';

const searchParamModifier = (searchString: string) => {
  const searchParams = new URLSearchParams(searchString);
  searchParams.delete(UrlParams.PRODUCT);
  searchParams.delete(UrlParams.STORE);
  searchParams.delete(UrlParams.REGION);
  searchParams.delete(UrlParams.SKU);
  return `?${searchParams.toString()}`;
};

const debugLog = createDebugLog('Detail');

/**
 * Detail screen component.
 * Displays the category tree, product list and detail content.
 * There are event handlers from child components that set search params in the URL
 * and one useEffect that reacts to changes in the URL. This is done so because other parts
 * of the app link to this screen in the form of URL parameters and the screen must read the URL
 * parameters and act accordingly.
 * Also, users are free to bookmark URL for specific product, sku, store or region.
 * @constructor
 */
const Detail: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  // current store, sku and region values from redux store
  const { storeId, skuId, regionId } = useDetail('storeId', 'skuId', 'regionId');

  // URL search params (the part after the ? in the URL)
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get(UrlParams.SEARCH) ?? undefined;

  useEffect(() => {
    dispatch(setPage(RoutingPage.DETAIL));
  }, [dispatch]);

  /**
   * This effect is reads the URL search params and acts accordingly.
   * There are various search params that can be present in the URL -
   * product id, sku id, region, store and search query.
   * The individual id values are stored in the redux store, where they are
   * picked up by individual components to display data.
   */
  useEffect(() => {
    const hasProduct = searchParams.has(UrlParams.PRODUCT);
    const hasSku = searchParams.has(UrlParams.SKU);

    let controller: AbortController | null = null;
    let signal: AbortSignal | null = null;

    if (hasProduct && !hasSku) {
      debugLog('has product and no SKU', searchParams.get(UrlParams.PRODUCT));
      dispatch(setProductId(parseInt(searchParams.get(UrlParams.PRODUCT) ?? '')));
    }

    // if SKU is present in the URL and is different from current skuID,
    // fetch the SKU data and store it in the redux store
    if (hasSku) {
      const querySkuId = parseInt(searchParams.get(UrlParams.SKU) ?? '');
      if (querySkuId !== skuId) {
        debugLog('Fetching SKU for skuId', querySkuId);
        controller = new AbortController();
        signal = controller.signal;
        getSkuById(querySkuId, signal)
          .then(sku => {
            debugLog('Fetch finished');
            if (sku) {
              dispatch(skuFound(sku));
            } else {
              dispatch(skuNotFound(querySkuId));
            }
          })
          .finally(() => {
            dispatch(setIsLoadingSalesData(false));
          });
      }
    }

    if (!hasSku && !hasProduct) {
      dispatch(setProductId(NO_VALUE));
    }

    // read region from URL and set in redux store or reset it if not present in URL
    if (searchParams.has(UrlParams.REGION)) {
      const paramRegion = searchParams.get(UrlParams.REGION);
      if (paramRegion) {
        const regionId = parseInt(paramRegion);
        if (!Number.isNaN(regionId)) {
          dispatch(setRegionId(regionId));
        }
      }
    } else {
      if (regionId !== NO_VALUE) {
        dispatch(setRegionId(NO_VALUE));
      }
    } // end of region reading

    // read store from URL and set in redux store or reset it if not present in URL
    if (searchParams.has(UrlParams.STORE)) {
      const paramStore = searchParams.get(UrlParams.STORE);
      if (paramStore) {
        const storeId = parseInt(paramStore);
        if (!Number.isNaN(storeId)) {
          dispatch(setStoreId(storeId));
        }
      }
    } else {
      if (storeId !== NO_VALUE && !hasSku) {
        dispatch(setStoreId(NO_VALUE));
      }
    } // end of store reading

    // abort the sku fetch request if the component is unmounted
    return () => {
      if (controller !== null) {
        debugLog('Aborting request');
        controller.abort();
      }
    };
  }, [dispatch, regionId, searchParams, skuId, storeId]);

  /**
   * Callback from the Category list component.
   * If the selected category is the same as current category,
   * prevent the default behavior of the link and do not execute it.
   */
  const categoryChangeHandler = useCallback(
    (event: React.MouseEvent, newCategorySlug: string) => {
      const pathParts = location.pathname.split('/');
      const currentSlug = pathParts.reverse()[0];
      // Link by default pushes the same location to the history stack
      // If the category is the same as the current one, prevent that default behavior
      if (currentSlug === newCategorySlug) {
        event.preventDefault();
      }
    },
    [location.pathname],
  );

  /**
   * Callback to search for a product by name.
   * This function modifies the URL search params to reflect the search query.
   * When a search query is empty, the search param is removed from the URL.
   */
  const onProductSearch = useCallback(
    (query: string): void => {
      setSearchParams(prevParams => {
        if (query.length > 0) {
          prevParams.set(UrlParams.SEARCH, query);
        } else {
          prevParams.delete(UrlParams.SEARCH);
        }
        return prevParams;
      });
    },
    [setSearchParams],
  );

  /**
   * Callback to search for a SKU by its ID.
   * This function modifies the URL search params to reflect the search query.
   * When a SKU is set, other search params must be removed from URL (PRODUCT, STORE, SEARCH).
   */
  const onSkuSearch = useCallback(
    (skuId: number): void => {
      dispatch(setProductId(NO_VALUE));

      setSearchParams((prevParams): URLSearchParamsInit => {
        if (prevParams.has(UrlParams.PRODUCT)) {
          prevParams.delete(UrlParams.PRODUCT);
        }
        if (prevParams.has(UrlParams.STORE)) {
          prevParams.delete(UrlParams.STORE);
        }

        //to avoid having both sku and product in the url at once
        //thus causing multiple renders
        if (prevParams.has(UrlParams.SEARCH)) {
          prevParams.delete(UrlParams.SEARCH);
        }

        prevParams.set(UrlParams.SKU, skuId.toString());
        return prevParams;
      });
    },
    [dispatch, setSearchParams],
  );

  /**
   * Called when a product is selected/deselected from the list.
   * This function modifies the URL search parameters to reflect the selected product.
   * When a product is set, other search params must be removed from URL (SKU, REGION).
   */
  const onProductChanged = useCallback(
    (product: ProductResponse | null): void => {
      dispatch(setIsLoadingSalesData(true));

      if (product !== null) {
        // product is selected,
        setSearchParams((prevParams): URLSearchParamsInit => {
          const newProductValue = product.id.toString();
          prevParams.set(UrlParams.PRODUCT, newProductValue);
          if (prevParams.has(UrlParams.SKU)) {
            prevParams.delete(UrlParams.SKU);
            prevParams.set(UrlParams.STORE, storeId.toString());
            dispatch(resetSku());
          }

          //when product is selected, deselect the region as the region selector is hidden
          if (prevParams.has(UrlParams.REGION)) {
            prevParams.delete(UrlParams.REGION);
          }
          return prevParams;
        });
      } else {
        // product is deselected, remove product and SKU from URL
        setSearchParams((prevParams): URLSearchParamsInit => {
          if (prevParams.has(UrlParams.PRODUCT)) {
            prevParams.delete(UrlParams.PRODUCT);
          }
          if (prevParams.has(UrlParams.SKU)) {
            prevParams.delete(UrlParams.SKU);
          }
          return prevParams;
        });
        dispatch(setProductId(NO_VALUE));
      }
    },
    [dispatch, setSearchParams, storeId],
  );

  return (
    <>
      <Helmet title="Detail" />
      <MainContentWrapper>
        <CategoryTree
          displayExceptions={false}
          onCategoryChange={categoryChangeHandler}
          searchParamModifier={searchParamModifier}
        />
        <DetailProductsListSection
          onProductSearch={onProductSearch}
          onSkuSearch={onSkuSearch}
          onProductSelected={onProductChanged}
          searchQuery={searchQuery}
        />
        <MainContent>
          <DetailContent />
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default Detail;
