import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  computeRemSize,
  GenericConfirmationModal,
  getDefaultSorting,
  InfiniteScrollTable,
  Row,
  SearchBox,
  Section,
} from '@ydistri/ds';
import { Spin } from 'antd';
import { styled } from 'styled-components';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import {
  GetProductListConfigurationRequest,
  useGetProductListConfigurationQuery,
  useResetProductListConfigurationMutation,
} from '../apiProductLists';
import { ColumnsType } from 'antd/es/table';
import { ProductListConfigurationResponse } from '@ydistri/api-sdk';
import ProductListConfigurationSwitch from './ProductListConfigurationSwitch';
import { useGetCalculationConfigurationQuery } from '../../../../apis/apiGlobalConfiguration';
import { useSelector } from 'react-redux';
import { setSelectedProductListConfiguration } from '../productListsConfigurationSlice';
import { ReduxState, useAppDispatch } from '../../../../store';
import ProductListTypeOfException from './ProductListTypeOfException';
import isEqual from 'lodash/isEqual';
import { apiSlice, APITAGS } from '../../../../apis/api';
import { formatDate } from '@ydistri/utils';

const ProductListExceptionsSection = styled(Section)`
  width: 55%;
  min-width: 55%;
`;

export const PRODUCT_LIST_CONFIGURATION_PAGE_SIZE = 30;

const InfiniteProductListConfigurationTable = styled(
  InfiniteScrollTable<ProductListConfigurationResponse, GetProductListConfigurationRequest>,
)`
  .ant-table-body {
    tr {
      cursor: pointer;
    }

    tr.selected-product-list {
      background-color: ${p => p.theme.item.selected.backgroundColor};
    }
  }
`;

const ProductCountWrapper = styled.div`
  text-align: right;
`;

const tableScroll = { y: 'auto' };

const ProductListConfigurations: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedProductListConfiguration = useSelector(
    (state: ReduxState) => state.productListConfiguration.selectedProductListConfiguration,
  );
  const [showModal, setShowModal] = useState(false);
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationConfiguration, isFetching } =
    useGetCalculationConfigurationQuery(templateOrCalculation);
  const [searchValue, setSearchValue] = useState<string>();
  const [resetConfiguration, { isLoading: isResetRunning }] =
    useResetProductListConfigurationMutation();

  const getRowClassName = useCallback(
    (productList: ProductListConfigurationResponse) => {
      return productList.productListId === selectedProductListConfiguration?.productListId
        ? 'selected-product-list'
        : '';
    },
    [selectedProductListConfiguration?.productListId],
  );

  const productListConfigurationColumns: ColumnsType<ProductListConfigurationResponse> = useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'productListId',
        key: 'productListId',
        width: computeRemSize(50),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        apiColumnName: 'Name',
        width: computeRemSize(300),
        render: (value: string, record: ProductListConfigurationResponse): string =>
          record.isArchived ? `[archived] ${value}` : value,
      },
      {
        title: 'Product count',
        dataIndex: 'productCount',
        key: 'productCount',
        width: computeRemSize(100),
        render: (productCount: number) => <ProductCountWrapper>{productCount}</ProductCountWrapper>,
      },
      {
        title: calculationConfiguration?.productListsAllEnabled
          ? 'Create exception (disable)'
          : 'Create exception (enable)',
        dataIndex: 'isEnabled',
        key: 'isEnabled',
        render: (isEnabled: boolean | null, record: ProductListConfigurationResponse) => {
          return (
            <ProductListConfigurationSwitch
              disabled={templateOrCalculation.type === 'Calculation'}
              enabled={isEnabled}
              archived={record.isArchived}
              configured={record.isConfigured}
              productListId={record.productListId}
              calculationProductListConfiguration={
                calculationConfiguration?.productListsAllEnabled ?? true
              }
            />
          );
        },
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        // sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        apiColumnName: 'CreatedAt',
        width: computeRemSize(100),
        render: (createdAt: string) => formatDate(new Date(createdAt)),
      },
    ],
    [calculationConfiguration?.productListsAllEnabled, templateOrCalculation.type],
  );

  const [params, setParams] = useState<GetProductListConfigurationRequest>({
    templateOrCalculation,
    skip: 0,
    top: PRODUCT_LIST_CONFIGURATION_PAGE_SIZE,
    sortings: getDefaultSorting(productListConfigurationColumns),
  });

  const { data: productListConfigurations } = useGetProductListConfigurationQuery(params);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we don't want to filter on every render
  const hasExceptions = useMemo(
    () => (productListConfigurations?.filter(b => b.isConfigured).length ?? 0) > 0,
    [productListConfigurations],
  );

  //if the selected product list has been deleted by someone else, it was removed from the list and needs to be
  //deselected too
  useEffect(() => {
    if (productListConfigurations && selectedProductListConfiguration) {
      const selectedProductListConfigurationExists = productListConfigurations.find(
        b => b.productListId === selectedProductListConfiguration.productListId,
      );
      if (!selectedProductListConfigurationExists) {
        dispatch(setSelectedProductListConfiguration(undefined));
      } else {
        //if the selected product list has been updated by someone else, we need to update the selected product list
        if (!isEqual(selectedProductListConfiguration, selectedProductListConfigurationExists)) {
          dispatch(setSelectedProductListConfiguration(selectedProductListConfigurationExists));
          if (
            selectedProductListConfiguration.productCount !==
            selectedProductListConfigurationExists.productCount
          ) {
            dispatch(
              apiSlice.util.invalidateTags([
                {
                  type: APITAGS.productListConfiguration.productListConfigurationProducts,
                  id: `plp-${selectedProductListConfigurationExists.productListId}`,
                },
              ]),
            );
          }
        }
      }
    }
  }, [dispatch, productListConfigurations, selectedProductListConfiguration]);

  const toggleModal = useCallback(() => setShowModal(p => !p), []);

  const resetExceptionsCallback = useCallback(() => {
    resetConfiguration(templateOrCalculation);
    toggleModal();
  }, [resetConfiguration, templateOrCalculation, toggleModal]);

  const resetExceptionsButton = useMemo(() => {
    if (templateOrCalculation.type === 'Calculation' || !hasExceptions) return null;
    return (
      <Row $alignSelf="flex-end">
        <Button
          loading={isResetRunning}
          $colorVariant="ghostDanger"
          onClick={toggleModal}
          disabled={isResetRunning}
        >
          Reset exceptions
        </Button>
      </Row>
    );
  }, [hasExceptions, toggleModal, templateOrCalculation.type, isResetRunning]);

  const headerActions = useMemo(
    () => (
      <Row $gap="1rem" $justifyContent="space-between" $width="100%">
        <Row $gap="1rem">
          <ProductListTypeOfException hasException={hasExceptions} />
        </Row>
        <Row $gap="1rem">
          {resetExceptionsButton}
          <SearchBox onSearch={setSearchValue} />
        </Row>
      </Row>
    ),
    [hasExceptions, resetExceptionsButton],
  );

  const tableOnRow = useCallback(
    (record: ProductListConfigurationResponse) => {
      return {
        onClick: () => {
          dispatch(setSelectedProductListConfiguration(record));
        },
      };
    },
    [dispatch],
  );

  return (
    <>
      <ProductListExceptionsSection
        header="Product lists"
        headerActions={headerActions}
        headerActionsIncludeMarginBottom={false}
        $ratio={1}
        data-id="ProductListExceptionsSection"
      >
        <Spin spinning={isFetching}>
          <InfiniteProductListConfigurationTable
            id="product-list-configuration"
            setParams={setParams}
            searchValue={searchValue}
            onRow={tableOnRow}
            height={`calc(100vh - ${computeRemSize(350)})`}
            rowKey="productListId"
            dataSource={productListConfigurations}
            columns={productListConfigurationColumns}
            rowClassName={getRowClassName}
            size="small"
            pagination={false}
            scroll={tableScroll}
          />
        </Spin>
      </ProductListExceptionsSection>
      {showModal && (
        <GenericConfirmationModal
          data-testid="ResetBrandExceptionsConfirmationModal"
          title="Reset all exceptions"
          message={`All exceptions ${
            searchValue ? `(not only filtered)` : ''
          } will be reset to the default configuration.`}
          onConfirmed={resetExceptionsCallback}
          onCanceled={toggleModal}
          running={isResetRunning}
        />
      )}
    </>
  );
};

export default ProductListConfigurations;
