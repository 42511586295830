import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import {
  setIsDeletingTemplate,
  setSelectedProjectCode,
  setSelectedTemplateId,
} from '../store/userSlice';
import { useSelector, useStore } from 'react-redux';
import { useGetTemplatesQuery, useGetUserProjectsQuery } from '../apis/apiApplication';
import { useSelectedProjectCode } from '../hooks/useSelectedProjectCode';
import { API } from '../swagger/collections';
import { useSelectedTemplateId } from '../hooks/useSelectedTemplateId';
import { skipToken } from '@reduxjs/toolkit/query';
import { getApiHeader } from '../apis/getApiHeader';
import { apiSlice, APITAGS, getAllTagsExcept } from '../apis/api';
import { signalrClient as hubConnection } from '../signalr/client';
import { HubConnectionState } from '@microsoft/signalr';
import { setSelectedCategory } from '../components/global/CategoryTree/categoryTreeSlice';
import { generatePartialPath } from './routerSlice';
import { ROUTES } from '../components/menu/menuLeftItemTemplate';
import { ConfigurationSubpage } from '../screens/Configuration/Configuration';
import { addToast } from '@ydistri/utils';
import { ReduxState, resetAllSlices, useAppDispatch } from '../store';
import { TemplateResponse } from '@ydistri/api-sdk';
import { Dispatch } from '@reduxjs/toolkit';
import { createDebugLog } from '../lib/utils/logging';
import { setSignalrConnected } from '../store/appSlice';
import LoadingFullPage from '../components/global/LoadingFullPage/LoadingFullPage';
import { getItem, getSelectedTemplateForProject, LSKey } from '../store/localStore';
import ModalDisplay from '../screens/Modals/ModalDisplay';

const debugLog = createDebugLog('ProjectRoute');

const ProjectRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const store = useStore();
  const { projectShortName, templateId, slug } = useParams();
  const appStatus = useSelector((state: ReduxState) => state.appReducer.status);

  const selectedProjectCode = useSelectedProjectCode();
  const selectedTemplateId = useSelectedTemplateId();

  const selectedProjectCodeFromApiHeaders = getApiHeader(API, 'Project-Code');

  //in case of user login, it can already be initialized => sometimes, that resulted in "Loading.." screen
  const [connectionInitialized, setConnectionInitialized] = useState<boolean>(
    hubConnection.state !== HubConnectionState.Disconnected,
  );
  const { data: projects, isLoading: isProjectsLoading } = useGetUserProjectsQuery();

  const { data: templates, isLoading: isTemplatesLoading } = useGetTemplatesQuery(
    selectedProjectCode && selectedProjectCodeFromApiHeaders === selectedProjectCode
      ? selectedProjectCode
      : skipToken,
  );

  const generatePartialPathWithTemplateId = useCallback(
    (templateId: number) =>
      generatePartialPath(
        ROUTES.configurationChildRoute,
        { projectShortName, slug: '1', templateId: templateId.toString() },
        ConfigurationSubpage.CATEGORIES,
      ),
    [projectShortName],
  );

  const setCorrectTemplate = useCallback(
    (
      templates: TemplateResponse[],
      templateIdUrl: string | undefined,
      templateIdStore: number | undefined,
      dispatch: Dispatch,
    ): void => {
      debugLog('======================== template change stuff =====================');
      debugLog('Templates: ', templates);
      const selectedTemplateId = templateIdStore;
      debugLog('templateIdFromUrl', templateIdUrl, 'selectedTemplateId', selectedTemplateId);
      if (templates.length > 0) {
        if (templateIdUrl) {
          //Template id is in url, so it has priority over the one in the store
          debugLog('Template id is in url, so it has priority over the one in the store');
          const templateIdFromUrl = parseInt(templateIdUrl);
          const template = templates.find(t => t.id === templateIdFromUrl);
          if (template) {
            debugLog(
              `Template ${templateIdFromUrl} exists and is different from the one in the store`,
            );
            if (templateIdFromUrl !== selectedTemplateId)
              dispatch(setSelectedTemplateId(templateIdFromUrl));
          } else {
            //we have wrong template id in the store!
            // => we select the first one and redirect (because the url is wrong)
            debugLog(
              `Template ${templateIdFromUrl} not found, setting to first and redirecting => probably deleted!`,
            );
            const newSelectedTemplateId = templates[0].id;

            dispatch(setSelectedTemplateId(newSelectedTemplateId));

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know that store is ReduxState
            const isDeletingTemplate = (store.getState() as ReduxState).userReducer
              .isDeletingTemplate;

            if (!isDeletingTemplate) {
              dispatch(
                addToast({
                  message: 'Template you had opened was probably deleted. Template was changed!',
                  isError: true,
                }),
              );
              dispatch(setIsDeletingTemplate(undefined));
            }

            navigate(generatePartialPathWithTemplateId(newSelectedTemplateId));
          }
        } else if (selectedTemplateId) {
          debugLog('Template id is not in URL, but it is in the store');
          const template = templates.find(t => t.id === selectedTemplateId);

          //if found, everything is ok
          //if not found, we select the first one, but we don't redirect
          if (!template) dispatch(setSelectedTemplateId(templates[0].id));
        } else {
          //template id is not in URL, and it is not in the store
          //we select the first one, but we don't redirect
          debugLog(
            "Template id is not in URL, and it is not in the store - we select the first one, but we don't redirect",
          );
          dispatch(setSelectedTemplateId(templates[0].id));
        }
      } else {
        debugLog('Unsetting template id!');
        dispatch(setSelectedTemplateId(undefined));
      }
      debugLog('=========================== template change stuff end =====================');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't need to add all dependencies
    [generatePartialPathWithTemplateId],
  );

  useEffect(() => {
    if (slug) {
      dispatch(setSelectedCategory(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (selectedTemplateId) {
      debugLog(`selectedTemplateId changed! Setting Template-Id header to `, selectedTemplateId);
      API.instance.defaults.headers.common['Template-Id'] = selectedTemplateId;
      dispatch(
        apiSlice.util.invalidateTags(
          getAllTagsExcept([
            APITAGS.application.projects,
            APITAGS.application.templates,
            APITAGS.calculations.calculations,
          ]),
        ),
      );
    }
  }, [dispatch, selectedTemplateId]);

  useEffect(() => {
    if (
      !isProjectsLoading &&
      projects &&
      (selectedProjectCode !== projectShortName || !projectShortName)
    ) {
      debugLog('++++++++++++++++++++++++++++');
      if (projects.length > 0) {
        if (projectShortName) {
          debugLog(
            'Project short name is in the url, so it has priority over the one in the store',
          );
          const foundProject = projects.find(t => t.shortName === projectShortName);
          if (foundProject) {
            if (foundProject.id !== selectedProjectCode) {
              debugLog('Project exists and is different from the one in the store');
              dispatch(setSelectedProjectCode(foundProject.id));
            } else {
              debugLog('This project is already selected, all good');
            }
            // dispatch(patchRouterParams({ params: { projectShortName: foundProject.shortName } }));
          } else {
            dispatch(setSelectedProjectCode(projects[0].id));
            // dispatch(patchRouterParams({ params: { projectShortName: projects[0].shortName } }));
            navigate(`/${projects[0].shortName}/calculations`);
          }
        } else if (selectedProjectCode) {
          debugLog('Project short name is not in URL, but code is in the store');
          const foundProject = projects.find(t => t.id === selectedProjectCode);
          if (!foundProject) {
            dispatch(setSelectedProjectCode(projects[0].id));
            navigate(`/${projects[0].shortName}/calculations`);
          } else {
            navigate(`/${foundProject.shortName}/calculations`);
          }
        } else {
          dispatch(setSelectedProjectCode(projects[0].id));
          navigate(`/${projects[0].shortName}/calculations`);
        }
      } else {
        debugLog('== ! projects');
        dispatch(setSelectedProjectCode(undefined));
      }
      debugLog('-----------------------------');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't need to add all dependencies
  }, [projectShortName, selectedProjectCode, projects, isProjectsLoading, dispatch]); //"navigate" is not needed here

  useEffect(() => {
    if (projectShortName) {
      debugLog('projectShortName changed: resetAllSlices()');
      resetAllSlices();
      debugLog('projectShortName changed: setSelectedTemplateId(undefined)');
      const templateIdForProject = getSelectedTemplateForProject(
        getItem(LSKey.PROJECTS_BY_SHORT_NAME)?.[projectShortName]?.id,
      );
      dispatch(setSelectedTemplateId(templateIdForProject));
    }
  }, [dispatch, projectShortName]);

  useEffect(() => {
    if (selectedProjectCode) {
      debugLog(`selectedProjectCode changed! Setting Project-Code header to `, selectedProjectCode);
      API.instance.defaults.headers.common['Project-Code'] = selectedProjectCode;
    }
  }, [dispatch, selectedProjectCode]);

  useEffect(() => {
    if (!isTemplatesLoading && templates) {
      setCorrectTemplate(templates, templateId, selectedTemplateId, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't need to add all dependencies
  }, [templates, templates?.length, isTemplatesLoading, templateId, selectedTemplateId, dispatch]); //"navigate" is not needed here

  useEffect(() => {
    (async function connect() {
      if (hubConnection.state === HubConnectionState.Disconnected) {
        debugLog('SignalR: hubConnection.state === HubConnectionState.Disconnected');

        await hubConnection
          .start()
          .then(() => {
            debugLog(`SignalR: ConnectionId: ${hubConnection.connectionId ?? ''}`);
            setConnectionInitialized(true);
          })
          .catch(debugLog);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't need to add all dependencies
  }, [selectedProjectCode]);

  useEffect(() => {
    if (selectedProjectCode && connectionInitialized) {
      debugLog(
        'SignalR: selectedProjectCode changed => Invoking connection... ',
        selectedProjectCode,
      );
      hubConnection
        .invoke('JoinProject', selectedProjectCode)
        .then(() => {
          debugLog('SignalR: JoinProject successful!');
          dispatch(setSignalrConnected(true));
        })
        .catch((err: unknown) => {
          debugLog(err);
          dispatch(setSignalrConnected(false));
        });
    }
  }, [selectedProjectCode, connectionInitialized, dispatch]);

  if (!appStatus) return <LoadingFullPage text="Loading." />;
  if (!connectionInitialized) return <LoadingFullPage text="Loading.." />;
  if (!API.instance.defaults.headers.common['Project-Code'])
    return <LoadingFullPage text="Loading..." />;
  if (!API.instance.defaults.headers.common['Template-Id'])
    return <LoadingFullPage text="Loading...." />;

  return (
    <>
      <Outlet />
      <ModalDisplay />
    </>
  );
};

export default ProjectRoute;
