import { useApplicationConfiguration } from './useApplicationConfiguration';
import { useCallback, useMemo } from 'react';
import { formatStoreLabel } from '../lib/display/displayLib';

/**
 * Create label for store based on application configuration and store details
 * @param storeName Name of the store
 * @param storeCustomerId Customer id of the store
 * @param storeCode Code of the store (if codes are used in the project)
 */
const useDisplayStoreLabel = (
  storeName: string,
  storeCustomerId?: string | null,
  storeCode?: string | null,
): string => {
  const { displayStoreId } = useApplicationConfiguration();

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- depends on few other parameters, let's keep it
  return useMemo(() => {
    return formatStoreLabel(displayStoreId, storeName, storeCustomerId, storeCode);
  }, [displayStoreId, storeCode, storeCustomerId, storeName]);
};

export type StoreLabelFormatter = (
  storeName: string,
  storeCustomerId?: string | null,
  storeCode?: string | null,
) => string;

/**
 * Return a function that creates label for store based on application configuration and store details.
 */
export const useDisplayStoreLabelFormatter = (): StoreLabelFormatter => {
  const { displayStoreId } = useApplicationConfiguration();

  return useCallback(
    (storeName: string, storeCustomerId?: string | null, storeCode?: string | null) => {
      return formatStoreLabel(displayStoreId, storeName, storeCustomerId, storeCode);
    },
    [displayStoreId],
  );
};

export default useDisplayStoreLabel;
