import { apiSlice, APITAGS, TemplateOrCalculation } from './api';
import { ProjectResponse } from '@ydistri/identity-sdk';
import { MeCollection } from '../swagger/collections-identity';
import {
  ApplicationConfigurationResponse,
  ApplicationDataResponse,
  CalculationStatus,
  CategoryConfigurationDefinitionResponse,
  ConfigurationResponse,
  HintResponse,
  TemplateResponse,
} from '@ydistri/api-sdk';
import {
  API,
  ApplicationConfigurationsCollection,
  ApplicationDataCollection,
  CategoryConfigurationDefinitionsCollection,
  CurrentSetupCollection,
  HintsCollection,
  TemplatesCollection,
} from '../swagger/collections';
import {
  SignalApplicationStatusChanged,
  SignalTemplateStatusChanged,
} from '../signalr/signalrInterfaces';
import { signalrClient } from '../signalr/client';
import { store } from '../store';
import { constructRequestParams, getTags } from './apiLib';
import { apiCategoryInsights } from '../components/global/CategoryInsights/apiCategoryInsights';
import { setProjectInfo } from '../store/localStore';

const { TAGS, TAGS_ARRAY } = getTags('application');

export const apiApplication = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no request params
      getUserProjects: builder.query<ProjectResponse[], void>({
        queryFn: async () => {
          const { data } = await MeCollection.projectsList();
          setProjectInfo(data.data);
          return data;
        },
        providesTags: [TAGS.projects],
      }),
      getTemplates: builder.query<TemplateResponse[], string>({
        queryFn: async selectedProjectCode => {
          // eslint-disable-next-line no-console -- we want the output here
          console.log(
            'getTemplates API header',
            API.instance.defaults.headers.common['Project-Code'],
          );
          const { data } = await TemplatesCollection.getTemplates(
            {},
            constructRequestParams({ projectCode: selectedProjectCode }),
          );
          return data;
        },
        providesTags: (result, error, arg) => [{ type: TAGS.templates, id: arg }],
        async onCacheEntryAdded(selectedProjectCode, api) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalTemplateStatusChanged) => {
              // eslint-disable-next-line no-console -- we want the output here
              console.log('listener! signal: ', signal);

              /* =============== NEW TEMPLATE ================ */
              const templates = api.getCacheEntry().data ?? [];
              if (!templates.find(t => t.id === signal.templateId)) {
                TemplatesCollection.getTemplate(signal.templateId).then(({ data }) => {
                  api.updateCachedData(draft => {
                    if (!draft.find(t => t.id === signal.templateId)) draft.push(data.data);
                  });
                });
              }

              /* =============== DELETED TEMPLATE ================ */
              if (signal.status === CalculationStatus.SoftDeleted) {
                api.updateCachedData(draft => {
                  // eslint-disable-next-line no-console -- we want the output here
                  console.log('Updating cached data...', signal.templateId);
                  const iToDelete = draft.findIndex(template => template.id === signal.templateId);
                  if (iToDelete >= 0) {
                    draft.splice(iToDelete, 1);
                  }
                });
              }
            };

            signalrClient.on('TemplateStatusChanged', listener);
            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateStatusChanged', listener);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),
      getApplicationData: builder.query<ApplicationDataResponse[], undefined>({
        queryFn: async () => {
          const { data } = await ApplicationDataCollection.applicationDataList();
          return data;
        },
        providesTags: () => [TAGS.applicationData],
      }),
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no request params
      getApplicationConfiguration: builder.query<ApplicationConfigurationResponse, void>({
        queryFn: async () => {
          const { data } =
            await ApplicationConfigurationsCollection.applicationConfigurationsList();
          return data;
        },
        providesTags: () => [TAGS.applicationData],
      }),
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no request params
      getApplicationHints: builder.query<HintResponse[], void>({
        queryFn: async () => {
          const { data } = await HintsCollection.hintsList();
          return data;
        },
        providesTags: () => [TAGS.applicationHints],
      }),
      getCategoryConfigurationDefinitions: builder.query<
        CategoryConfigurationDefinitionResponse[],
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no request params
        void
      >({
        queryFn: async () => {
          const { data } =
            await CategoryConfigurationDefinitionsCollection.categoryConfigurationDefinitionsList();
          return data;
        },
        providesTags: () => [TAGS.categoryConfigurationDefinitions],
      }),
      getStatuses: builder.query<ConfigurationResponse, TemplateOrCalculation>({
        queryFn: async () => {
          const { data } = await CurrentSetupCollection.getCurrentSetup();
          return data;
        },
        providesTags: () => [TAGS.currentSetup],
        async onCacheEntryAdded({ type: _, id: selectedTemplateId }, api) {
          try {
            await api.cacheDataLoaded;

            const listener1 = (signal: SignalTemplateStatusChanged) => {
              if (selectedTemplateId !== signal.templateId) return;
              api.updateCachedData(draft => {
                const { status } = signal;
                draft.templateStatus = status;
                if (status === CalculationStatus.Completed) {
                  store.dispatch(
                    apiCategoryInsights.util.invalidateTags([
                      APITAGS.categoryInsights.categoryInsights,
                    ]),
                  );
                }
                // eslint-disable-next-line no-console -- we want the output here
                console.log('SIGNAL TemplateStatusChanged', signal);
              });
            };

            const listener2 = (signal: SignalApplicationStatusChanged) => {
              api.updateCachedData(draft => {
                const { applicationStatus, statusInfo } = signal;
                draft.applicationStatus = applicationStatus;
                draft.statusInfo = statusInfo;
                // eslint-disable-next-line no-console -- we want the output here
                console.log('SIGNAL ApplicationStatusChanged', signal);
              });
            };

            signalrClient.on('TemplateStatusChanged', listener1);
            signalrClient.on('ApplicationStatusChanged', listener2);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateStatusChanged', listener1);
              signalrClient.off('ApplicationStatusChanged', listener2);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),
    }),
  });

export const {
  useGetUserProjectsQuery,
  useGetTemplatesQuery,
  useGetApplicationDataQuery,
  useGetApplicationConfigurationQuery,
  useGetApplicationHintsQuery,
  useGetCategoryConfigurationDefinitionsQuery,
  useGetStatusesQuery,
} = apiApplication;
