import { useEffect, useMemo, useState } from 'react';
import { useGetCalculationQuery } from '../../CalculationDetail/apiCalculationDetail';
import { CalculationsCollection } from '../../../swagger/collections';
import { CalculationDetailResponse, CalculationMode } from '@ydistri/api-sdk';
import { CalculationDataType, CalculationInfo } from '../calculationsTypes';
import { AxiosError } from 'axios';

export interface CalculationSourcesData {
  isPending: boolean;
  sources: CalculationSources;
}

export interface CalculationSources {
  manualCalculation?: CalculationInfo;
  templates?: CalculationInfo[];
  overlappingCalculations?: CalculationDataType[];
}

/**
 * Returns a list of calculations that were used when creating the calculation.
 * Merged calculation may have been created using a manual calculation and/or one or more templates.
 * When a calculation is created, these sources are used to create new calculations. These are then
 * listed in subordinatedCalculations property of the calculation.
 * This hooks returns a list of either OriginalCalculationId or SourceTemplateId of these submerged calculations.
 */
const useMergedCalculationSources = (calculationId: number): CalculationSourcesData => {
  const { data: calculation, isFetching } = useGetCalculationQuery(calculationId);
  const [isPending, setIsPending] = useState(isFetching);

  const [result, setResult] = useState<CalculationSources>({
    manualCalculation: undefined,
    templates: [],
  });

  useEffect(() => {
    setIsPending(true);

    if (isFetching || !calculation) {
      return;
    }

    if (calculation.subordinateCalculations) {
      const subOrdinateCalculationsPromise = Promise.all<CalculationDetailResponse>(
        calculation.subordinateCalculations.map(subOrdinateCalculation => {
          return new Promise((resolve, reject) => {
            CalculationsCollection.getCalculation(subOrdinateCalculation.id)
              .then(response => {
                const result = response.data;
                resolve(result.data);
              })
              // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we want to reject with error
              .catch((error: AxiosError) => {
                reject(error);
              });
          });
        }),
      );
      subOrdinateCalculationsPromise.then(subOrdinateCalculations => {
        const manualCalculation = subOrdinateCalculations.find(
          subOrdinateCalculation => subOrdinateCalculation.mode === CalculationMode.Manual,
        );

        const templates: CalculationInfo[] = [];
        subOrdinateCalculations.forEach(subOrdinateCalculation => {
          if (
            subOrdinateCalculation.mode === CalculationMode.Computed &&
            subOrdinateCalculation.sourceTemplate
          ) {
            templates.push({
              id: subOrdinateCalculation.sourceTemplate.id,
              title: subOrdinateCalculation.sourceTemplate.title,
            });
          }
        });

        setResult(prevValue => {
          // eslint-disable-next-line no-console -- we want the output
          console.log(
            'setting result: with manual calculation and templates',
            manualCalculation,
            templates,
          );
          return {
            ...prevValue,
            manualCalculation: manualCalculation?.originalCalculation
              ? {
                  id: manualCalculation.originalCalculation.id,
                  title: manualCalculation.originalCalculation.title,
                }
              : undefined,
            templates,
          };
        });

        setIsPending(false);
      });
    }

    //include overlapping calculations if any
    if (calculation.overlappingCalculations && calculation.overlappingCalculations.length > 0) {
      const overlappingCalculations: CalculationDataType[] = [
        ...calculation.overlappingCalculations,
      ];

      setResult(prevValue => {
        return {
          ...prevValue,
          overlappingCalculations,
        };
      });
    }
  }, [calculation, isFetching]);

  return useMemo(() => {
    return {
      isPending,
      sources: result,
    };
  }, [isPending, result]);
};

export default useMergedCalculationSources;
