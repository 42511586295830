import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { SimpleTable } from '../../../CalculationDetail/Statistics/components/SimpleTable';
import { StoreDrillDownData } from '../drillDownLib';
import { useCurrency } from '../../../../hooks/useCurrency';
import { getStoreDrillDownColumns } from './storeDrillDownColumns';
import { computeRemSize } from '@ydistri/ds';
import { useStoreTableColumnsProvider } from '../../../../hooks/useEntityTableColumnsProvider';

const StoreDrillDown: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency();

  const storeColumnsProvider = useStoreTableColumnsProvider();

  const columns = useMemo(() => {
    const storeColumns = storeColumnsProvider<StoreDrillDownData>();
    return getStoreDrillDownColumns(currencyId, storeColumns);
  }, [currencyId, storeColumnsProvider]);

  const rowKeySelector = useCallback((c: StoreDrillDownData) => c.customerStoreId, []);

  return (
    <SimpleTable<StoreDrillDownData>
      id={`drill-down-stores-${templateOrCalculation.id}-${templateOrCalculation.type}`}
      loading={false /*isFetching*/}
      height={`calc(100vh - ${computeRemSize(270)})`}
      showBorder={true}
      showHeader={true}
      columns={columns}
      dataSource={undefined /*drillDownData*/}
      size="small"
      rowKey={rowKeySelector}
    />
  );
};

export default StoreDrillDown;
