import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCalculationConfigurationQuery } from '../../../apis/apiGlobalConfiguration';
import { useEffect } from 'react';
import { useGetConfigurationRulesQuery } from './apiScopes';
import {
  useGetDepartmentsQuery,
  useGetRegionsQuery,
  useGetStoresQuery,
  useGetStoreTypesQuery,
} from '../../../apis/apiLists';
import { transformExceptionOverlapData } from './scopeLib2';
import { setExceptionOverlapData } from './scopeConfigurationSlice';
import { useCalculationConfiguration } from './useCalculationConfiguration';
import { useAppDispatch } from '../../../store';

export function useConfigurationRuleOverlap(): boolean {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();

  const { useClosing, useSuperTarget } = useCalculationConfiguration();
  const { data: configurationRules, isFetching: isFetchingConfigurationRules } =
    useGetConfigurationRulesQuery(templateOrCalculation);

  const { data: availableRegions, isFetching: isFetchingAvailableRegions } =
    useGetRegionsQuery(templateOrCalculation);
  const { data: availableStores, isFetching: isFetchingAvailableStores } =
    useGetStoresQuery(templateOrCalculation);
  const { data: availableStoreTypes, isFetching: isFetchingAvailableStoreTypes } =
    useGetStoreTypesQuery(templateOrCalculation);
  const { data: availableDepartments, isFetching: isFetchingAvailableDepartments } =
    useGetDepartmentsQuery(templateOrCalculation);

  const { data: templateConfiguration, isFetching: isLoadingCalculationConfig } =
    useGetCalculationConfigurationQuery(templateOrCalculation);

  // const { data: availableStores } = useGetStoresQuery(templateOrCalculation);

  const isLoading =
    isFetchingConfigurationRules ||
    isFetchingAvailableRegions ||
    isFetchingAvailableStores ||
    isFetchingAvailableStoreTypes ||
    isFetchingAvailableDepartments ||
    isLoadingCalculationConfig;

  useEffect(() => {
    if (
      configurationRules &&
      availableRegions &&
      availableStores &&
      availableStoreTypes &&
      availableDepartments &&
      templateConfiguration
    ) {
      const transformedData = transformExceptionOverlapData({
        scopes: configurationRules,
        regionList: availableRegions,
        storeList: availableStores,
        storeTypeList: availableStoreTypes,
        departmentList: availableDepartments,
        useClosing,
        useSuperTarget,
      });

      dispatch(setExceptionOverlapData(transformedData));
      // eslint-disable-next-line no-console -- we need to log it
      console.log('TRANSFORMED DATA: ', transformedData);
    }
  }, [
    dispatch,
    availableRegions,
    availableDepartments,
    availableStoreTypes,
    availableStores,
    configurationRules,
    templateConfiguration,
    useClosing,
    useSuperTarget,
  ]);

  return isLoading;
}
