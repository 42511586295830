import React, { useEffect } from 'react';
import { Column, computeRemSize, Section } from '@ydistri/ds';
import {
  useGetApplicationHintsQuery,
  useGetCategoryConfigurationDefinitionsQuery,
} from '../../../../apis/apiApplication';
import { Spin } from 'antd';
import {
  ApplicationHintType,
  CategoryConfigurationDefinitionResponse,
  ConfigurationSectionType,
} from '@ydistri/api-sdk';
import CategoryConfigurationGroup from './CategoryConfigurationGroup';
import { styled } from 'styled-components';
import CategoryInventoryDefinitionSection from '../SegmentInventoryDefinition/CategoryInventoryDefinitionSection';
import SegmentCategorySummary from '../SegmentCategorySummary/SegmentCategorySummary';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { useGetCategoryTreeQuery } from '../../../../components/global/CategoryTree/apiCategoryTree';
import { useGetRootCategoryConfigurationQuery } from '../../apiTemplate';

const PotentialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${computeRemSize(700)};
  flex-grow: 1;
`;

export type CategoryConfigurationDefinitionGroup = Record<
  ConfigurationSectionType,
  CategoryConfigurationDefinitionResponse[]
>;

const SegmentPotentialDefinition: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: configurationDefinitions, isFetching: isFetchingDefinitions } =
    useGetCategoryConfigurationDefinitionsQuery();
  const { data: hints, isFetching: isFetchingHints } = useGetApplicationHintsQuery();
  const { isFetching: isFetchingCategoryTree } = useGetCategoryTreeQuery(templateOrCalculation);
  const { isFetching: isFetchingRootCategoryConfiguration } =
    useGetRootCategoryConfigurationQuery(templateOrCalculation);

  const [definitionGroups, setDefinitionGroups] =
    React.useState<CategoryConfigurationDefinitionGroup>();

  const isFetching =
    isFetchingDefinitions ||
    isFetchingHints ||
    isFetchingCategoryTree ||
    isFetchingRootCategoryConfiguration;

  useEffect(() => {
    const groups: CategoryConfigurationDefinitionGroup = {
      [ConfigurationSectionType.General]: [],
      [ConfigurationSectionType.PotentialSource]: [],
      [ConfigurationSectionType.PotentialSourceStandardSubsection]: [],
      [ConfigurationSectionType.PotentialTarget]: [],
    };

    if (configurationDefinitions) {
      [...configurationDefinitions]
        .sort((a, b) => a.position - b.position)
        .forEach(definition => {
          const sectionType = definition.sectionType;

          const applicableHints = hints?.filter(
            hint => hint.key === definition.fieldType.toString(),
          );

          const description = applicableHints?.find(
            hint =>
              hint.applicationHintType ===
              ApplicationHintType.CategoryConfigurationDefinitionDescription,
          )?.value;

          const title = applicableHints?.find(
            hint =>
              hint.applicationHintType === ApplicationHintType.CategoryConfigurationDefinitionTitle,
          )?.value;

          groups[sectionType].push({
            ...definition,
            title: title ?? definition.title,
            description: description ?? definition.description,
          });
        });

      setDefinitionGroups(groups);
    }
  }, [configurationDefinitions, hints]);

  return (
    <PotentialWrapper data-type-id="PotentialWrapper">
      <Column $flexGrow={1} $flexWrap="nowrap">
        <CategoryInventoryDefinitionSection fetching={isFetching} />
        <Section header="Source potential definition" $ratio={0} $contentMarginTop={false}>
          <Spin spinning={isFetching}>
            <CategoryConfigurationGroup
              sectionType={ConfigurationSectionType.PotentialSource}
              definitionGroups={definitionGroups}
            />
            <CategoryConfigurationGroup
              sectionType={ConfigurationSectionType.PotentialSourceStandardSubsection}
              definitionGroups={definitionGroups}
            />
          </Spin>
        </Section>
      </Column>
      <Column $flexGrow={1} $flexWrap="nowrap">
        <SegmentCategorySummary />
        <Section header="Target potential definition" $contentMarginTop={false} $ratio={0}>
          <Spin spinning={isFetching}>
            <CategoryConfigurationGroup
              sectionType={ConfigurationSectionType.PotentialTarget}
              definitionGroups={definitionGroups}
              displayTitle={false}
            />
          </Spin>
        </Section>
      </Column>
    </PotentialWrapper>
  );
};

export default SegmentPotentialDefinition;
