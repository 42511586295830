import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CalculationCategoryPairingRequest,
  useGetCalculationCategoryPairingsQuery,
} from '../../../../../Calculations/apiCalculationsCategories';
import {
  computeRemSize,
  getDefaultSorting,
  InfiniteScrollTable,
  Panel,
  ReadOnlyItem,
  Row,
  Text,
  YColumnsType,
} from '@ydistri/ds';
import {
  Condition,
  DisplayEntityIdType,
  Operation,
  SkuRedistributionResponse,
} from '@ydistri/api-sdk';
import { formatNumber } from '@ydistri/utils';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import {
  selectSKURedistribution,
  setRedistributionPairingCount,
} from '../../../../../Calculations/calculationsSlice';
import { useSearchParams } from 'react-router';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';
import { createDebugLog } from '../../../../../../lib/utils/logging';
import { useColumnWidth } from '../../../../../../hooks/useColumnWidth';
import { useApplicationData } from '../../../../../../hooks/useApplicationData';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../../hooks/useEntityTableColumnsProvider';
import { useApplicationConfiguration } from '../../../../../../hooks/useApplicationConfiguration';
import { InfoIconTooltip } from '../../../../../../components/icons/InfoIconTooltip';
import { useAppDispatch } from '../../../../../../store';
import { useSelectedPickingPositionFromUrl } from '../useSelectedPickingPositionFromUrl';

const debugLog = createDebugLog('CalculationRedistributionBody');

const TABLE_ID = 'SkuRedistribution';
const TABLE_OFFSET = 170; //just a default value, will be recomputed right away - to compensate for the table's header as it is rendered outside the table's container

const emptyData: SkuRedistributionResponse[] = [];

const SkuRedistributionTable = styled(
  InfiniteScrollTable<SkuRedistributionResponse, CalculationCategoryPairingRequest>,
)`
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-small .ant-table-tbody > tr:not(:first-child) > td {
    padding-top: ${computeRemSize(2)} !important;
    padding-bottom: ${computeRemSize(2)} !important;
  }

  .selected-row > td.ant-table-cell-row-hover,
  .selected-row > td.ant-table-cell {
    background-color: #f29718 !important;
    color: white;
  }
`;

export interface CalculationRedistributionBodyProps {
  calculationId: number;
  categoryId: number;
  height?: number;
}

const computeTableSize = (newSize: number) => Math.max(newSize, 190);

const removeCondition = (conditions: Condition[], fieldName: string) => {
  //remove previous brand condition if any
  const conditionIndex = conditions.findIndex(condition => condition.fieldName === fieldName);
  if (conditionIndex !== -1) {
    debugLog('field %s is present in conditions, removing it', fieldName);
    conditions.splice(conditionIndex, 1);
    debugLog('new conditions: %s', JSON.stringify(conditions));
  }
};

const removeBrandCondition = (conditions: Condition[]) => {
  removeCondition(conditions, 'Brand/Id');
};

const removeOutOfStockFilter = (conditions: Condition[]) => {
  removeCondition(conditions, 'TotalAvailableSupplyOfTargetSku');
};

const removeForcedRedistributionFilter = (conditions: Condition[]) => {
  removeCondition(conditions, 'IsSourceForced');
};

export const CALCULATION_REDISTRIBUTION_PAGE_SIZE = 100;

const CalculationRedistributionBody: React.FC<CalculationRedistributionBodyProps> = ({
  calculationId,
  categoryId,
}) => {
  const dispatch = useAppDispatch();
  const { productIdColumnWidth } = useColumnWidth();
  const { departments: hasDepartments, brands: hasBrands } = useApplicationData();
  const { displayStoreId } = useApplicationConfiguration();

  const selectedItem = useSelectedPickingPositionFromUrl();

  const storeColumnProvider = useStoreTableColumnsProvider();
  const productColumnProvider = useProductTableColumnsProvider();

  const columns = useMemo(() => {
    //store name is wider if neither Id nor Code are displayed
    const storeNameWidth = displayStoreId === DisplayEntityIdType.None ? '22rem' : '17rem';

    const sourceStoreColumnsConfig: EntityColumnsConfig<SkuRedistributionResponse> = {
      [EntityColumns.NAME]: {
        title: 'Source Store [from]',
        width: storeNameWidth,
        apiFilterable: true,
      },
      [EntityColumns.ID]: {
        width: '8rem',
        apiFilterable: true,
      },
      [EntityColumns.CODE]: {
        apiFilterable: true,
        width: '8rem',
      },
    };
    const sourceStoreColumns = storeColumnProvider<SkuRedistributionResponse>(
      sourceStoreColumnsConfig,
      'sourceStore',
      'Source Store',
    );

    const targetStoreColumnsConfig: EntityColumnsConfig<SkuRedistributionResponse> = {
      [EntityColumns.NAME]: {
        title: 'Target Store [to]',
        width: storeNameWidth,
        apiFilterable: true,
      },
      [EntityColumns.ID]: {
        width: '8rem',
        apiFilterable: true,
      },
      [EntityColumns.CODE]: {
        apiFilterable: true,
        width: '8rem',
      },
    };
    const targetStoreColumns = storeColumnProvider<SkuRedistributionResponse>(
      targetStoreColumnsConfig,
      'targetStore',
      'Target Store',
    );

    const productColumnsConfig: EntityColumnsConfig<SkuRedistributionResponse> = {
      [EntityColumns.ID]: {
        apiFilterable: true,
        width: productIdColumnWidth,
      },
      [EntityColumns.CODE]: {
        apiFilterable: true,
        width: productIdColumnWidth,
      },
      [EntityColumns.NAME]: {
        apiFilterable: true,
      },
    };
    const productColumns = productColumnProvider(productColumnsConfig);

    const result: YColumnsType<SkuRedistributionResponse>[] = [...productColumns];

    if (hasBrands) {
      result.push({
        title: 'Brand',
        key: 'brandName',
        dataIndex: ['brand', 'name'],
        sorter: true,
        width: '10rem',
        apiColumnName: 'Brand/Name',
        apiFilterable: true,
        apiFilterType: 'text',
      });
    }

    if (hasDepartments) {
      result.push({
        title: 'Department',
        key: 'departmentName',
        dataIndex: ['department', 'name'],
        sorter: true,
        // width: '8rem',
        apiColumnName: 'Department/Name',
        apiFilterable: true,
        apiFilterType: 'text',
      });
    }
    result.push(...sourceStoreColumns);
    result.push(...targetStoreColumns);

    result.push({
      title: 'Qty',
      key: 'quantity',
      dataIndex: 'quantity',
      align: 'right',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: '6rem',
      apiColumnName: 'Quantity',
      apiFilterable: true,
      apiFilterType: 'number',
      render: (value: number, record) =>
        record.description && record.description.length > 0 ? (
          <Row $gap="8px" $justifyContent="flex-end">
            <Text>{formatNumber(value, 0)}</Text>
            <InfoIconTooltip
              content={<ReadOnlyItem label="Picking instruction" value={record.description} />}
            />
          </Row>
        ) : (
          formatNumber(value, 0)
        ),
    });

    result.push({
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      align: 'right',
      width: '8rem',
      render: (value: number) => formatNumber(value, 2),
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      apiColumnName: 'Value',
      apiFilterable: true,
      apiFilterType: 'number',
    });

    result.push({
      title: 'Source SKU Type',
      key: 'sourceSkuType',
      dataIndex: 'sourceSkuTypeId',
      width: '10rem',
      sorter: true,
      apiColumnName: 'SourceSkuTypeId',
      apiFilterable: true,
      apiFilterType: 'options',
      filters: [
        {
          text: 'DeadStock',
          value: 'DeadStock',
        },
        {
          text: 'SlowMover',
          value: 'SlowMover',
        },
        {
          text: 'FastMover',
          value: 'FastMover',
        },
      ],
    });

    return result;
  }, [
    displayStoreId,
    storeColumnProvider,
    productIdColumnWidth,
    productColumnProvider,
    hasBrands,
    hasDepartments,
  ]);

  const redistributionFilterBrandId = useSelector(
    state => state.calculations.redistributionFilterBrandId,
  );
  const redistributionFilterOutOfStock = useSelector(
    state => state.calculations.redistributionFilterOutOfStock,
  );
  const redistributionFilterForced = useSelector(
    state => state.calculations.redistributionFilterForced,
  );

  const [params, setParams] = useState<CalculationCategoryPairingRequest>({
    calculationId,
    categoryId,
    skip: 0,
    top: CALCULATION_REDISTRIBUTION_PAGE_SIZE,
    sortings: getDefaultSorting(columns),
  });

  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(400);
  const [tableHeightOffset, setTableHeightOffset] = useState<number>(TABLE_OFFSET);
  const [resizeObserver, setResizeObserver] = useState<ResizeObserver | null>(null);
  const [, setSearchParams] = useSearchParams();

  const searchQuery = useSelector(state => state.calculations.redistributionSearchQuery);

  const resizeCallback = useCallback(() => {
    const newHeight = Math.floor(ref.current?.clientHeight ?? 400);
    debugLog('new height', newHeight);
    setTableHeight(newHeight);
  }, []);

  const changeTableHeightOffset = useCallback(() => {
    const tableHeader = document.getElementById(TABLE_ID)?.querySelector('.ant-table-header');
    if (tableHeader) {
      // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- needed
      setTableHeightOffset(tableHeader.clientHeight + 40);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      if (resizeObserver === null) {
        debugLog('setting up resize observer');
        const initialHeight = Math.floor(ref.current.clientHeight);
        debugLog('initial height', initialHeight);
        changeTableHeightOffset();

        // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- needed
        setTableHeight(initialHeight);

        window.addEventListener('resize', resizeCallback);

        //when the grandparent element is resized (the section), we need to recompute the table's height
        const tmpObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            const elementSize = entry.contentBoxSize[0].blockSize;
            const newSize = Math.floor(elementSize);

            if (newSize !== tableHeight) {
              const newComputedHeight = computeTableSize(newSize);
              // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- needed
              setTableHeight(newComputedHeight);
              changeTableHeightOffset();
            }
          }
        });

        const parent = ref.current.parentElement;
        if (parent !== null) {
          const grandParent = parent.parentElement;
          if (grandParent !== null) {
            tmpObserver.observe(grandParent);
          }
        }

        // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- needed
        setResizeObserver(tmpObserver);
      }
    }
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, [resizeCallback, ref, resizeObserver, tableHeight, changeTableHeightOffset]);

  const { data: redistributionData, isFetching } = useGetCalculationCategoryPairingsQuery(params);

  useEffect(() => {
    //resetting paging after category change
    setParams(prevValue => {
      const newParams = {
        ...prevValue,
        categoryId,
        skip: 0,
        top: CALCULATION_REDISTRIBUTION_PAGE_SIZE,
      };

      const conditions: Condition[] = prevValue.conditions ? Array.from(prevValue.conditions) : [];
      if (redistributionFilterBrandId) {
        if (redistributionFilterBrandId !== NO_VALUE) {
          removeBrandCondition(conditions);

          //add new brand condition
          conditions.push({
            fieldName: 'Brand/Id',
            operation: Operation.Eq,
            value: redistributionFilterBrandId,
          });
        }
      } else {
        removeBrandCondition(conditions);
      }

      removeOutOfStockFilter(conditions);
      if (redistributionFilterOutOfStock) {
        conditions.push({
          fieldName: 'TotalAvailableSupplyOfTargetSku',
          operation: Operation.Eq,
          value: 0,
        });
      } else {
        debugLog('Out of stock filter is off, removing from conditions');
        removeOutOfStockFilter(conditions);
      }

      removeForcedRedistributionFilter(conditions);
      if (redistributionFilterForced) {
        conditions.push({
          fieldName: 'IsSourceForced',
          operation: Operation.Eq,
          value: true,
        });
      } else {
        removeForcedRedistributionFilter(conditions);
      }

      if (conditions.length > 0) {
        newParams.conditions = conditions;
      } else {
        delete newParams.conditions;
      }

      debugLog('setting params %s', JSON.stringify(newParams));
      return newParams;
    });
  }, [
    categoryId,
    redistributionFilterBrandId,
    redistributionFilterForced,
    redistributionFilterOutOfStock,
  ]);

  useEffect(() => {
    debugLog(`setting pairing count to ${redistributionData?.totalCount ?? 0}`);
    dispatch(setRedistributionPairingCount(redistributionData?.totalCount ?? 0));
  }, [dispatch, redistributionData]);

  const getRowKey = useCallback(
    (row: SkuRedistributionResponse) => `${row.sourceSkuId}#${row.targetSkuId}`,
    [],
  );

  const selectRow = useCallback(
    (record: SkuRedistributionResponse) => {
      dispatch(selectSKURedistribution(record));
      setSearchParams({ p: `${record.sourceSkuId}-${record.targetSkuId}` });
    },
    [dispatch, setSearchParams],
  );

  const rowConfig = useCallback(
    (record: SkuRedistributionResponse) => {
      return {
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          selectRow(record);
        },
      };
    },
    [selectRow],
  );

  const rowClassProvider = useCallback(
    (record: SkuRedistributionResponse) => {
      let result = '';

      if (selectedItem && getRowKey(selectedItem) === getRowKey(record)) {
        result = 'selected-row';
      }

      return result;
    },
    [getRowKey, selectedItem],
  );

  return (
    <Panel $height="100%" ref={ref}>
      <SkuRedistributionTable
        id={TABLE_ID}
        columns={columns}
        height={`${tableHeight - tableHeightOffset}px`}
        setParams={setParams}
        //eslint-disable-next-line @ydistri/react/dont-prop-drill-store-state -- prop drilling not possible, its generic component
        searchValue={searchQuery}
        dataSource={redistributionData?.data ?? emptyData}
        loading={isFetching}
        rowKey={getRowKey}
        onRow={rowConfig}
        selectedRow={selectedItem}
        onSelectRow={selectRow}
        rowClassName={rowClassProvider}
      />
    </Panel>
  );
};

export default CalculationRedistributionBody;
