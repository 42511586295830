import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState, useAppDispatch } from '../../store';
import TargetListSkuGrid from '../ProjectAdministration/TargetLists/content/detail/TargetListSkuGrid';
import { styled } from 'styled-components';
import { Link, useParams } from 'react-router';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import SectionTargetList from '../CalculationDetail/Statistics/SectionTargetList';

const TableWrapper = styled.div`
  height: 70vh;
`;

const modalId = AvailableModals.TARGET_LIST_CONTENTS;

const TargetListContentsModal: React.FC = () => {
  const { projectShortName } = useParams();
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();
  const targetList = useSelector((state: ReduxState) => state.modal.modalData[modalId]?.targetList);

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const footer = useMemo(
    () => (
      <>
        {projectShortName && (
          <Button onClick={handleClose}>
            <Link
              to={`/${projectShortName}/project-administration/targetlists/${targetList?.targetListId}`}
            >
              Open in administration
            </Link>
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </>
    ),
    [projectShortName, targetList?.targetListId, handleClose],
  );

  if (targetList) {
    return (
      <Modal
        title={`Target list - ${targetList.name ?? 'undefined'}`}
        centered
        open={true}
        width="80%"
        onCancel={handleClose}
        footer={footer}
      >
        {templateOrCalculation.type === 'Template' && (
          <TableWrapper>
            <TargetListSkuGrid selectedTargetListId={targetList.targetListId} />
          </TableWrapper>
        )}
        {templateOrCalculation.type === 'Calculation' && <SectionTargetList />}
      </Modal>
    );
  }
};

export default TargetListContentsModal;
