import React, { useCallback, useMemo } from 'react';
import { ConfigurationSelect, Section } from '@ydistri/ds';
import { SelectProps } from 'antd';
import { RegionFlowType } from '@ydistri/api-sdk';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import {
  UpdateCalculationConfigurationPayload,
  useGetCalculationConfigurationQuery,
  useUpdateCalculationConfigurationMutation,
} from '../../../../apis/apiGlobalConfiguration';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';

const RegionFlowTypeSection: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculationConfiguration, isFetching } =
    useGetCalculationConfigurationQuery(templateOrCalculation);
  const [updateConfiguration, updateConfigurationStatus] =
    useUpdateCalculationConfigurationMutation();

  const options: SelectProps<RegionFlowType>['options'] = useMemo(() => {
    return [
      {
        value: RegionFlowType.AllEnabled,
        label: 'Redistribute among all enabled regions',
      },
      {
        value: RegionFlowType.WithinRegion,
        label: 'Redistribute within each region individually',
      },
      {
        value: RegionFlowType.CustomRedistribution,
        label: 'Custom setup of redistribution flow',
      },
    ];
  }, []);

  const onChangeHandler = useCallback(
    (value: RegionFlowType) => {
      const payload: UpdateCalculationConfigurationPayload = {
        templateOrCalculation,
        request: {
          regionFlowTypeId: value,
        },
      };

      updateConfiguration(payload);
    },
    [templateOrCalculation, updateConfiguration],
  );

  const status = useMemo(() => {
    // eslint-disable-next-line no-console -- we want this output
    console.log('STATUS: ', isFetching, updateConfigurationStatus);

    return isFetching
      ? ConfigurationFieldStatus.LOADING
      : rtkQueryPatchStatusToConfigurationFieldStatus(updateConfigurationStatus);
  }, [updateConfigurationStatus, isFetching]);

  const headerActions = useMemo(
    () => (
      <ConfigurationSelect
        disabled={templateOrCalculation.type === 'Calculation'}
        value={calculationConfiguration?.regionFlowTypeId}
        options={options}
        onChange={onChangeHandler}
        status={status}
        showArrow={true}
        width="400px"
        size="large"
      />
    ),
    [
      calculationConfiguration?.regionFlowTypeId,
      onChangeHandler,
      options,
      status,
      templateOrCalculation.type,
    ],
  );

  return (
    <Section
      header="Region flow type"
      headerActions={headerActions}
      headerActionsJustifyContent="flex-start"
      $ratio={0}
    />
  );
};

export default RegionFlowTypeSection;
