import {
  CalculationDetailResponse,
  CalculationMode,
  CalculationRequest,
  CalculationType,
  CalculationUpdateRequest,
  CalculationWithOverviewResponseCollectionResponse,
  CalculationWithRelatedEntitiesResponseCollectionResponse,
  Condition,
  LogicalOperator,
  Operation,
} from '@ydistri/api-sdk';
import { apiSlice, errorTransform, ErrorType } from '../../apis/api';
import { CalculationsCollection, CurrentSetupCollection } from '../../swagger/collections';
import {
  CalculationTableParams,
  PrivateFilterType,
  SignalCalculationStatusChanged,
} from './calculationsTypes';
import { signalrClient } from '../../signalr/client';
import {
  isCalculationCreated,
  isCalculationDeleted,
  isCalculationFinished,
  isCalculationInTemporaryStatus,
} from './calculationsLib';
import { apiCalculationDetail } from '../CalculationDetail/apiCalculationDetail';
import {
  ApiParams,
  forceRefetchForInfiniteScroll,
  OtherArgs,
  serializeFullApiBasedQueryArgs,
} from '@ydistri/ds';
import { constructRequestParams, getTags } from '../../apis/apiLib';
import { createDebugLog } from '../../lib/utils/logging';
import { SignalConfigurationChanged } from '../../signalr/signalrInterfaces';
import { removeSelectedRowKey } from './calculationsSlice';

const debugLog = createDebugLog('Calculations:api');

const { TAGS, TAGS_ARRAY } = getTags('calculations');

interface LoadCalculationToTemplateParams {
  fromCalculationId: number;
  toTemplateId: number;
}

export interface PatchCalculationPayload extends ApiParams {
  calculationId: number;
  data: CalculationUpdateRequest;
}

const getConditions = (
  params: CalculationTableParams,
  calculationOwnerId?: string,
): Condition[] => {
  const conditions: Condition[] = [];

  if (params.productionCalculationsOnly) {
    conditions.push({
      fieldName: 'Type',
      operation: Operation.Eq,
      value: 'Production',
      logicalOperator: LogicalOperator.And,
    });
    if (params.showOwnersCalculationsOnly) {
      conditions.push({
        fieldName: 'IdentityUserId',
        operation: Operation.Eq,
        value: calculationOwnerId,
        logicalOperator: LogicalOperator.And,
      });
    }
  } else if (params.showOwnersCalculationsOnly) {
    conditions.push({
      fieldName: 'IdentityUserId',
      operation: Operation.Eq,
      value: calculationOwnerId,
      logicalOperator: LogicalOperator.And,
    });
    if (params.includePrivateCalculations === PrivateFilterType.NONE) {
      conditions.push({
        fieldName: 'Type',
        operation: Operation.Ne,
        value: 'Private',
        logicalOperator: LogicalOperator.And,
      });
    }
  } else {
    if (params.includePrivateCalculations === PrivateFilterType.NONE) {
      conditions.push({
        fieldName: 'Type',
        operation: Operation.Ne,
        value: 'Private',
        logicalOperator: LogicalOperator.And,
      });
    } else if (params.includePrivateCalculations === PrivateFilterType.MINE) {
      conditions.push({
        fieldName: 'Type',
        operation: Operation.Ne,
        value: 'Private',
        logicalOperator: LogicalOperator.Or,
      });
      conditions.push({
        fieldName: 'IdentityUserId',
        operation: Operation.Eq,
        value: calculationOwnerId,
        logicalOperator: LogicalOperator.Or,
      });
    }
  }
  return conditions;
};

export const apiCalculations = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getCalculations: builder.query<
        CalculationWithRelatedEntitiesResponseCollectionResponse,
        CalculationTableParams
      >({
        queryFn: async args => {
          debugLog('getCalculations API starting, queryArgs: ', args);
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
          };

          const conditions = getConditions(args, args.calculationOwner);
          if (conditions.length > 0) {
            if (query.conditions) {
              query.conditions = [...query.conditions, ...conditions];
            } else {
              query.conditions = conditions;
            }
          }

          debugLog('getCalculations: calling API with query ', query);
          const response = await CalculationsCollection.getCalculations(
            query,
            constructRequestParams(args),
          );
          debugLog('getCalculations: response received');
          return { data: response.data };
        },
        providesTags: (result, error, arg) => [{ type: TAGS.calculations, id: arg.projectCode }],
        async onCacheEntryAdded(arg, api) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalCalculationStatusChanged) => {
              // eslint-disable-next-line no-console -- we want the output here
              console.log('SIGNAL SignalCalculationStatusChanged', signal);
              //if a new calculation is created or a calculation is deleted, reload the whole list
              //as it may affect the pagination
              if (isCalculationCreated(signal.status)) {
                api.dispatch(
                  apiCalculations.util.invalidateTags([
                    { type: TAGS.calculations, id: signal.projectCode },
                  ]),
                );
                return;
              }

              if (isCalculationDeleted(signal.status)) {
                api.dispatch(removeSelectedRowKey({ tableId: 'all', data: signal.calculationId }));
                debugLog('Signalr: Calculation id: %d deleted', signal.calculationId);
                api.updateCachedData(draft => {
                  debugLog('Updating cached data...', signal.calculationId);
                  const indexToDelete = draft.data.findIndex(
                    calculation => calculation.id === signal.calculationId,
                  );
                  if (indexToDelete >= 0) {
                    debugLog('Deleting calculation from cache', signal.calculationId);
                    draft.data.splice(indexToDelete, 1);
                  } else {
                    debugLog('Calculation not found in cache, good', signal.calculationId);
                  }
                });
                return;
              }

              //if a calculation is queued or pending we can safely update the status in cache as no
              //function depends on queued or pending calculations
              if (isCalculationInTemporaryStatus(signal.status)) {
                api.updateCachedData(draft => {
                  const calculation = draft.data.find(
                    calculation => calculation.id === signal.calculationId,
                  );
                  if (calculation) {
                    calculation.status = signal.status;
                  } else {
                    //when computing merged calculations, submerged calculation status can change as well
                    //in that case, we need to find it in the list of all calculations and update its status
                    draft.data.find(
                      calculation =>
                        calculation.mode === CalculationMode.Merged &&
                        (calculation.subordinateCalculations ?? []).find(sc => {
                          if (sc.id === signal.calculationId) {
                            sc.status = signal.status;
                          }
                          return sc.id === signal.calculationId;
                        }),
                    );
                  }
                });
                return;
              }

              //if a calculation is completed or crashed we need to reload the calculation from the server
              //as there are functions that depend on the calculation being in final state
              if (isCalculationFinished(signal.status)) {
                //First find out if the calculation is in cache, so we do not call the backend unnecessarily.
                //We can't call backend from within updateCacheData as the callback cannot perform asynchronous operations
                //because the draft proxy will be gone by the time the backend call resolves.
                const cacheEntry = api.getCacheEntry();
                if (cacheEntry.data) {
                  const calculationIndex = cacheEntry.data.data.findIndex(
                    calculation =>
                      calculation.id === signal.calculationId ||
                      (calculation.mode === CalculationMode.Merged &&
                        (calculation.subordinateCalculations ?? []).find(
                          sub => sub.id === signal.calculationId,
                        )),
                  );
                  if (calculationIndex !== -1) {
                    // it can be different than the one in signal in case of submerged calculation
                    const calculationIdToReload = cacheEntry.data.data[calculationIndex].id;

                    //calculation is in cache, reload it from the backend
                    CalculationsCollection.getCalculations({
                      conditions: [
                        { fieldName: 'Id', operation: Operation.Eq, value: calculationIdToReload },
                      ],
                    }).then(response => {
                      const data = response.data.data;
                      if (data.length > 0) {
                        api.updateCachedData(draft => {
                          draft.data[calculationIndex] = data[0];
                        });
                      }
                    });
                  }
                }
              }
            };

            //TODO - currently, we get calculation updates in TemplateConfigurationChanged endpoint, what is a bit weird
            const listener2 = (signal: SignalConfigurationChanged) => {
              // eslint-disable-next-line no-console -- we want the output here
              console.log('SIGNAL! SignalConfigurationChanged', signal);
              api.updateCachedData(draft => {
                //if it is change in normal calculation, we select it right away
                //if it is change in submerged calculation, we select its parent first
                const correctOrParentCalculation = draft.data.find(
                  calculation =>
                    calculation.id === signal.templateId ||
                    (calculation.subordinateCalculations ?? []).find(
                      c => c.id === signal.templateId,
                    ),
                );

                if (correctOrParentCalculation) {
                  //if id is not the same as the one from signal, it is submerged calculation - in that case, we find correct submerged "calculation"
                  const calculation =
                    correctOrParentCalculation.id !== signal.templateId
                      ? (correctOrParentCalculation.subordinateCalculations ?? []).find(
                          c => c.id === signal.templateId,
                        )
                      : correctOrParentCalculation;

                  if (!calculation) {
                    throw new Error(
                      "Didn't find correct submerged calculation, this should not happen!",
                    );
                  }

                  signal.updates.forEach(u => {
                    if (u.value) {
                      switch (u.fieldName) {
                        case 'name':
                          calculation.title = u.value;
                          break;
                        case 'description':
                          calculation.description = u.value;
                          break;
                        case 'isPap':
                          calculation.isPap = u.value === '1';
                          break;
                        case 'calculationTypeId':
                          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
                          calculation.type = u.value as CalculationType;

                          if (calculation.type === CalculationType.Production) {
                            api.dispatch(
                              removeSelectedRowKey({ tableId: 'all', data: calculation.id }),
                            );
                          }

                          break;
                      }
                    }
                  });
                }
              });
            };

            signalrClient.on('CalculationStatusChanged', listener);
            signalrClient.on('TemplateConfigurationChanged', listener2);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('CalculationStatusChanged', listener);
              signalrClient.off('TemplateConfigurationChanged', listener2);
            });

            await api.cacheEntryRemoved;
          } catch (error) {
            // eslint-disable-next-line no-console -- we want the output here
            console.log(error);
          }
        },
        serializeQueryArgs: serializeFullApiBasedQueryArgs<CalculationTableParams>(),
        merge: (
          currentCache: CalculationWithOverviewResponseCollectionResponse,
          newItems: CalculationWithOverviewResponseCollectionResponse,
          otherArgs: OtherArgs<CalculationTableParams>,
        ) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.data.splice(0, currentCache.data.length);
          }
          currentCache.data.push(...newItems.data);
          currentCache.totalCount = newItems.totalCount;
        },
        forceRefetch: forceRefetchForInfiniteScroll<CalculationTableParams | undefined>(),
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no response here
      createCalculation: builder.mutation<void, CalculationRequest>({
        queryFn: async request => {
          try {
            const result = await CalculationsCollection.calculationsCreate(request);
            return { data: result.data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            const err = error as ErrorType;
            return { error: errorTransform(err) };
          }
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no response here
      runManualCalculation: builder.mutation<void, number>({
        queryFn: async calculationId => {
          try {
            await CalculationsCollection.patchCalculationPairings(calculationId);
            return { data: undefined };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            const err = error as ErrorType;
            return { error: errorTransform(err) };
          }
        },
      }),

      deleteCalculation: builder.mutation<number, number>({
        queryFn: async (calculationId: number) => {
          try {
            await CalculationsCollection.deleteCalculation(calculationId);
            return { data: calculationId };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            const err = error as ErrorType;
            return { error: errorTransform(err) };
          }
        },
      }),
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- no response here
      loadCalculationToTemplate: builder.mutation<void, LoadCalculationToTemplateParams>({
        queryFn: async ({ fromCalculationId, toTemplateId }) => {
          try {
            const { data } = await CurrentSetupCollection.loadFromCalculationCurrentConfiguration(
              fromCalculationId,
              {
                // eslint-disable-next-line @typescript-eslint/naming-convention -- mandated by HTTP
                headers: { 'Template-Id': toTemplateId },
              },
            );

            return { data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            const err = error as ErrorType;
            return { error: err };
          }
        },
      }),

      patchCalculation: builder.mutation<CalculationDetailResponse, PatchCalculationPayload>({
        queryFn: async ({ calculationId, data }) => {
          const response = await CalculationsCollection.patchCalculation(calculationId, data);
          return { data: response.data.data };
        },
        onQueryStarted(payload, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiCalculationDetail.util.updateQueryData(
              'getCalculation',
              payload.calculationId,
              draft => {
                if (payload.data.calculationType) {
                  draft.type = payload.data.calculationType;
                } else {
                  Object.assign(draft, payload.data);
                }
              },
            ),
          );

          //update the cache for both the list and single calculation so it is
          //reflected in UI
          queryFulfilled
            .then(({ data }) => {
              dispatch(
                apiCalculationDetail.util.updateQueryData('getCalculation', data.id, draft => {
                  draft.isDeletable = data.isDeletable;
                }),
              );
              dispatch(
                apiCalculations.util.updateQueryData(
                  'getCalculations',
                  { projectCode: payload.projectCode },
                  oldData => {
                    const calculationIndex = oldData.data.findIndex(
                      calculation => calculation.id === data.id,
                    );

                    //update the calculation in the redux store
                    //the returned object from edit is different type than the one in the redux store,
                    //so we need to update the relevant properties only

                    //we update only those properties that were changed through the patch payload

                    if (calculationIndex !== -1) {
                      const calculation = oldData.data[calculationIndex];

                      if (payload.data.calculationType) {
                        calculation.type = data.type;
                        calculation.isDeletable = data.isDeletable;
                      }
                      if (payload.data.isPap) {
                        calculation.isPap = data.isPap;
                      }

                      if (payload.data.title) {
                        calculation.title = data.title;
                      }

                      if (payload.data.description) {
                        calculation.description = data.description;
                      }
                    }
                  },
                ),
              );
            })
            .catch(patchResult.undo);
        },
      }),
    }),
  });

export const {
  useGetCalculationsQuery,
  useCreateCalculationMutation,
  useDeleteCalculationMutation,
  useLoadCalculationToTemplateMutation,
  usePatchCalculationMutation,
  useRunManualCalculationMutation,
} = apiCalculations;
