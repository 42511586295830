import React from 'react';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import {
  useGetPickingInsightsSummaryQuery,
  useGetPickingResultsSummaryQuery,
} from '../../../apiCalculationPickingResults';
import { useCurrency } from '../../../../../../hooks/useCurrency';
import StatisticsItem from '../../../components/StatisticsItem';
import { computePercentage, FormatTimeDurationFormats } from '@ydistri/utils';
import DurationStatisticsItem from './DurationStatisticsItem';

const getDurationFormat = (seconds: number): FormatTimeDurationFormats[] => {
  if (seconds < 60) {
    return ['seconds'];
  } else if (seconds > 60 && seconds < 3600) {
    return ['minutes', 'seconds'];
  } else {
    return ['hours', 'minutes', 'seconds'];
  }
};

/**
 * Set of statistics for the overall picking insights (based on logs).
 */
const CalculationOverallPickingStatistics: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency();

  const { data: pickingResults, isFetching: isFetchingPickingResultsSummaryData } =
    useGetPickingResultsSummaryQuery({
      calculationId: templateOrCalculation.id,
    });

  const { data: pickingInsights, isFetching: isFetchingPickingInsightsSummaryData } =
    useGetPickingInsightsSummaryQuery({
      calculationId: templateOrCalculation.id,
    });

  const allReady =
    !isFetchingPickingResultsSummaryData &&
    !isFetchingPickingInsightsSummaryData &&
    pickingResults &&
    pickingInsights;

  //compute picking statistics for all source stores
  if (allReady) {
    return (
      <>
        <StatisticsItem
          title="Executed quanity [MU]"
          unit="MU"
          value={pickingInsights.executedQuantity}
          totalValue={pickingResults.pickingResults.totalQuantity ?? 0}
          percentage={computePercentage(
            pickingInsights.executedQuantity,
            pickingResults.pickingResults.totalQuantity ?? 0,
          )}
        />
        <StatisticsItem
          title={`Executed value [${currencyId}]`}
          unit={currencyId}
          value={pickingInsights.executedValue}
          totalValue={pickingResults.pickingResults.totalValue ?? 0}
          percentage={computePercentage(
            pickingInsights.executedValue,
            pickingResults.pickingResults.totalValue ?? 0,
          )}
        />
        <StatisticsItem
          title="Processed Picking Positions"
          unit=""
          value={pickingInsights.processedPickingPositionsCount}
          totalValue={pickingResults.pickingResults.totalSkuCount ?? 0}
          percentage={computePercentage(
            pickingInsights.processedPickingPositionsCount,
            pickingResults.pickingResults.totalSkuCount ?? 0,
          )}
        />
        <DurationStatisticsItem
          title="Total time (trimmed)"
          value={pickingInsights.trimmedPickingTime}
          tooltip="Total time spent on picking with outliers removed"
        />
        <DurationStatisticsItem
          title="Average time (trimmed)"
          value={pickingInsights.trimmedMeanPickingTime}
          format={getDurationFormat(pickingInsights.trimmedMeanPickingTime)}
        />
        <DurationStatisticsItem
          title="Median time (trimmed)"
          value={pickingInsights.trimmedMedianPickingTime}
          format={getDurationFormat(pickingInsights.trimmedMedianPickingTime)}
        />
      </>
    );
  }
};

export default CalculationOverallPickingStatistics;
