import {
  CategoryConfigurationDefinitionResponse,
  ConfigurationFieldType,
  ConfigurationSectionType,
} from '@ydistri/api-sdk';
import { iterableConfigurationSectionTypes } from '../helpers/types/iterableEnums';

export const sortConfigurationFieldTypes = (
  configurationDefinitions: CategoryConfigurationDefinitionResponse[],
): { arr: ConfigurationFieldType[]; obj: Record<ConfigurationFieldType, number> } => {
  const resultArr: ConfigurationFieldType[] = [];
  const resultObj: Partial<Record<ConfigurationFieldType, number>> = {};

  const sections: Record<ConfigurationSectionType, CategoryConfigurationDefinitionResponse[]> = {
    [ConfigurationSectionType.General]: [],
    [ConfigurationSectionType.PotentialSource]: [],
    [ConfigurationSectionType.PotentialSourceStandardSubsection]: [],
    [ConfigurationSectionType.PotentialTarget]: [],
  };

  configurationDefinitions.forEach(configurationDefinition => {
    sections[configurationDefinition.sectionType].push(configurationDefinition);
  });

  iterableConfigurationSectionTypes.forEach(sectionType => {
    sections[sectionType].sort((a, b) => a.position - b.position);
    resultArr.push(
      ...sections[sectionType].map(configurationDefinition => configurationDefinition.fieldType),
    );
  });

  let i = 0;
  Object.keys(sections).forEach(sectionType => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needed here
    const st = sectionType as ConfigurationSectionType;
    sections[st].forEach(configurationDefinition => {
      resultObj[configurationDefinition.fieldType] = i;
      i++;
    });
  });

  // eslint-disable-next-line no-console -- debug
  console.log('SORTED: ', {
    arr: resultArr,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needed here
    obj: resultObj as Record<ConfigurationFieldType, number>,
  });
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needed here
  return { arr: resultArr, obj: resultObj as Record<ConfigurationFieldType, number> };
};
