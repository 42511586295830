import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionsBar,
  Button,
  GenericConfirmationModal,
  IconButton_IconSizes,
  Row,
  Section,
} from '@ydistri/ds';
import ScopeDefinition from './ScopeDefinition';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../store';
import { setDraggingInfo } from './scopeConfigurationSlice';
import { MdDelete, MdDragIndicator, MdEdit } from 'react-icons/md';
import { isScopeCorrect, ScopeConfigurationErrorType, ViewMode } from './scopeLib';
import { styled } from 'styled-components';
import { Reorder, useDragControls } from 'framer-motion';
import { useDeleteConfigurationRuleMutation } from './apiScopes';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useScope } from './useScope';
import ScopeMissingEntityView from './ScopeMissingEntityView';
import { useOpenScopeModal } from './useOpenScopeModal';
import { useCalculationConfiguration } from './useCalculationConfiguration';
import { ScopeErrorType } from './scopeLib2';
import { generateScopeTitle } from './scopeTitle';
import { DragHandleBase } from '../../../components/global/DragHandleBase/DragHandleBase';

export const DragHandle = styled(DragHandleBase)<{ $grabbing?: boolean }>`
  cursor: ${p => (p.$grabbing ? 'grabbing' : 'grab')};
`;

interface ExceptionDefinitionProps {
  scopeId: number;
}

const ExceptionDefinition: React.FC<ExceptionDefinitionProps> = ({ scopeId }) => {
  const dispatch = useAppDispatch();

  const templateOrCalculation = useTemplateOrCalculation();
  const isCalculation = templateOrCalculation.type === 'Calculation';
  const scope = useScope(scopeId);
  const openModalHandler = useOpenScopeModal(scope);
  const { useClosing, useSuperTarget } = useCalculationConfiguration();

  const [deleteConfigurationRuleMutation] = useDeleteConfigurationRuleMutation();
  const scopeOverlapErrors = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.scopesWithErrors[scopeId],
  );

  const rearrangeMode = useSelector((state: ReduxState) => state.scope.rearrangeMode);
  const controls = useDragControls();
  const [grabbing, setGrabbing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showConfirmationModal = useCallback(() => setShowConfirmation(true), []);
  const hideConfirmationModal = useCallback(() => setShowConfirmation(false), []);

  const deleteExceptionHandler = useCallback(() => {
    deleteConfigurationRuleMutation({
      templateId: templateOrCalculation.id,
      id: scopeId,
    });
    hideConfirmationModal();
  }, [deleteConfigurationRuleMutation, hideConfirmationModal, scopeId, templateOrCalculation.id]);

  const headerActions = useMemo(() => {
    if (isCalculation) return null;
    return (
      <ActionsBar moveToRight={true} wrap={false}>
        <ScopeMissingEntityView scopeId={scopeId} viewMode={ViewMode.EXCEPTION} />
        <Button $colorVariant="ghost" onClick={openModalHandler} data-testid="edit_exception">
          <MdEdit size={IconButton_IconSizes.small} />
          Adjust scope
        </Button>
        <Button
          $colorVariant="ghostDanger"
          onClick={showConfirmationModal}
          data-testid="create_new_exception"
        >
          <MdDelete size={IconButton_IconSizes.small} />
        </Button>
      </ActionsBar>
    );
  }, [isCalculation, scopeId, openModalHandler, showConfirmationModal]);

  const onDragStart = useCallback(() => {
    setGrabbing(true);
    dispatch(
      setDraggingInfo({
        scopeId,
        finished: false,
        startingPriority: scope?.priority,
        endingPriority: scope?.priority,
      }),
    );
  }, [dispatch, scopeId, scope?.priority]);

  const onDragEnd = useCallback(() => {
    setGrabbing(false);
    dispatch(setDraggingInfo({ finished: true }));
  }, [dispatch]);

  const onPointerDownHandler = useCallback(
    (e: PointerEvent | React.PointerEvent) => controls.start(e),
    [controls],
  );

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- there is a complex computation here
  const exceptionName = useMemo(() => {
    if (!scope) return `Exception ${scopeId}`;
    return scope.name && scope.name !== ''
      ? scope.name
      : generateScopeTitle(scope, useClosing, useSuperTarget);
  }, [scope, scopeId, useClosing, useSuperTarget]);

  if (scope?.creating) {
    return null;
  }

  const configurationError = scope
    ? isScopeCorrect(scope, useClosing, useSuperTarget) !== ScopeConfigurationErrorType.CORRECT ||
      (scopeOverlapErrors?.[ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING] ?? []).length > 0 ||
      (scopeOverlapErrors?.[ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET] ?? []).length > 0
    : true;

  return (
    <>
      <Reorder.Item
        as="div"
        value={scopeId}
        dragListener={false}
        dragControls={controls}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <Row $gap="0.5rem" $width="100%" $flexWrap="nowrap">
          {rearrangeMode && (
            <DragHandle $grabbing={grabbing} onPointerDown={onPointerDownHandler}>
              <MdDragIndicator size={20} />
            </DragHandle>
          )}
          <Section
            collapsible={true}
            open={!rearrangeMode}
            $contentMarginTop={false}
            header={exceptionName}
            headerActions={headerActions}
            $ratio={1}
            $backgroundColor={configurationError ? '#ffecef' : undefined}
          >
            <ScopeDefinition
              scopeId={scopeId}
              viewMode={ViewMode.EXCEPTION}
              disabled={isCalculation}
            />
          </Section>
        </Row>
      </Reorder.Item>
      {showConfirmation && (
        <GenericConfirmationModal
          title="Delete exception"
          message={`You are about to delete "${exceptionName}"`}
          confirmationButtonLabel="Delete"
          onConfirmed={deleteExceptionHandler}
          onCanceled={hideConfirmationModal}
        />
      )}
    </>
  );
};

export default ExceptionDefinition;
