import React, { useCallback, useMemo } from 'react';
import { List, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState, useAppDispatch } from '../../store';
import {
  forcedTitleAsCategory,
  PartialExceptionExpanded,
  renderer,
} from './CategoryExceptionsModal/categoryExceptionsModalLib';
import { useExceptions } from './CategoryExceptionsModal/useExceptions';
import { ColumnScroll } from '@ydistri/ds';
import { parseEntityListConfigs } from '../Configuration/ConfigurationCategories/SegmentPotentialDefinition/ConfigurationEntityLists/entityListLib';
import { useDeleteEntityListConfigurationsMutation } from '../../components/global/CategoryTree/apiCategoryTree';

export const MODAL_HEIGHT = '20rem)';
const modalId = AvailableModals.ENTITY_LIST_CONFIG_DELETE;

const EntityListConfigDeleteModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const [deleteEntityList, { isLoading: clearing }] = useDeleteEntityListConfigurationsMutation();
  const entityList = useSelector((state: ReduxState) => state.modal.modalData[modalId]?.entityList);
  const configurationDefinition = useSelector(
    (state: ReduxState) => state.modal.modalData[modalId]?.configurationDefinition,
  );
  const fieldType = configurationDefinition?.fieldType;

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const exceptions = useExceptions();

  const categorySorter = (a: PartialExceptionExpanded, b: PartialExceptionExpanded) => {
    return a.titleFull.localeCompare(b.titleFull);
  };

  const deleteEntityListFromConfiguration = useCallback(() => {
    if (entityList && configurationDefinition) {
      deleteEntityList({
        entityListId: entityList.entityListId,
        categoryId: 1,
        configurationFieldType: configurationDefinition.fieldType,
      }).then(() => {
        handleClose();
      });
    }
  }, [handleClose, deleteEntityList, entityList, configurationDefinition]);

  const finalExceptions = useMemo(() => {
    if (!fieldType) return [];
    const hasCategory: Record<number, true | undefined> = {};
    const exc = exceptions
      .filter(e => {
        // eslint-disable-next-line no-console -- we want the output here
        console.log('EXC: ', e);
        if (e.fieldType !== fieldType || hasCategory[e.category.id]) return false;
        if (
          parseEntityListConfigs(e.value).find(el => el.EntityListId === entityList?.entityListId)
        ) {
          hasCategory[e.category.id] = true;
          return true;
        }
        return false;
      })
      .sort(categorySorter);

    // eslint-disable-next-line no-console -- we want the output here
    console.log('EXCS', exc);

    return exc;
  }, [fieldType, exceptions, entityList?.entityListId]);

  const renderDefinitionByField = useCallback(
    (exceptionExpanded: PartialExceptionExpanded) => {
      if (!configurationDefinition) return null;
      return renderer(
        exceptionExpanded,
        forcedTitleAsCategory,
        [configurationDefinition],
        entityList?.entityListId,
      );
    },
    [configurationDefinition, entityList?.entityListId],
  );

  const content = useMemo(() => {
    if (!configurationDefinition) return null;
    if (finalExceptions.length === 0) return null;

    return (
      <Spin spinning={false}>
        <List
          dataSource={finalExceptions}
          loading={false}
          size="small"
          renderItem={renderDefinitionByField}
          data-testid={`ConfigurationGroupExceptions-${fieldType}`}
        />
      </Spin>
    );
  }, [configurationDefinition, fieldType, finalExceptions, renderDefinitionByField]);

  if (entityList) {
    return (
      <Modal
        title={`Remove list - ${entityList.name ?? 'undefined'}`}
        centered
        open={true}
        onOk={deleteEntityListFromConfiguration}
        okText="Remove"
        okButtonProps={{ loading: clearing }}
        onCancel={handleClose}
        width="50%"
      >
        {finalExceptions.length > 0
          ? 'There are exceptions set for this entity list. They will be cleared after you remove this entity list from configuration.'
          : 'This entity list will be removed from configuration'}
        <ColumnScroll $height={MODAL_HEIGHT} $gap="2rem">
          {content}
        </ColumnScroll>
      </Modal>
    );
  }
};

export default EntityListConfigDeleteModal;
