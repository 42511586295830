import React, { useCallback, useEffect } from 'react';
import { Checkbox, Radio } from 'antd';
import { styled } from 'styled-components';
import { setSelectedRowKeys, toggleSelectedRowKey } from '../../../calculationsSlice';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../../../store';
import { CalculationCheckboxMode } from './calculationCheckboxLib';

export const CheckboxWrapper = styled.div<{ $compact?: boolean }>`
  padding: ${p => (p.$compact ? '0.25rem' : '0.75rem')};
  height: 100%;
`;

interface CalculationCheckboxProps {
  calculationId: number;
  calculationsTableId: string;
  disabled?: boolean;
  compact?: boolean;
  mode?: CalculationCheckboxMode;
}

export const CALCULATION_CHECKBOX_CLASS_NAME = 'calculation-checkbox';

const CalculationCheckbox: React.FC<CalculationCheckboxProps> = ({
  calculationId,
  calculationsTableId,
  disabled = false,
  compact = false,
  mode = CalculationCheckboxMode.MULTIPLE,
}) => {
  const dispatch = useAppDispatch();
  const checked = useSelector(
    (state: ReduxState) =>
      state.calculations.selectedRowKeys[calculationsTableId]?.obj[calculationId] ?? false,
  );

  useEffect(() => {
    const tableRow = document.querySelector<HTMLTableRowElement>(
      `#${calculationsTableId} tr[data-row-key="${calculationId}"]`,
    );
    if (tableRow) {
      if (checked) {
        tableRow.classList.add('calculationRow', 'selectedRow');
      } else {
        tableRow.classList.remove('selectedRow');
      }
    }
  }, [calculationId, calculationsTableId, checked]);

  const onWrapperClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      e.preventDefault();
      if (!disabled) {
        if (mode === CalculationCheckboxMode.MULTIPLE) {
          dispatch(toggleSelectedRowKey({ tableId: calculationsTableId, data: calculationId }));
        } else {
          dispatch(setSelectedRowKeys({ tableId: calculationsTableId, data: [calculationId] }));
        }
      }
    },
    [disabled, mode, dispatch, calculationsTableId, calculationId],
  );

  return (
    <CheckboxWrapper
      onClick={onWrapperClick}
      className={CALCULATION_CHECKBOX_CLASS_NAME}
      $compact={compact}
    >
      {mode === CalculationCheckboxMode.MULTIPLE ? (
        <Checkbox disabled={disabled} checked={checked} />
      ) : (
        <Radio disabled={disabled} checked={checked} />
      )}
    </CheckboxWrapper>
  );
};

export default CalculationCheckbox;
