import React from 'react';
import { Column, Row, Text, Title } from '@ydistri/ds';
import { useCurrency } from '../../../../hooks/useCurrency';
import {
  computePercentage,
  formatMonetaryValue,
  formatNumber,
  formatPercentage,
} from '@ydistri/utils';
import { Space } from 'antd';
import { CalculationStatisticSummaryResponse } from '@ydistri/api-sdk';

interface CalculationStatisticsDataProps {
  label: string;
  value: string;
}

const CalculationStatisticsData: React.FC<CalculationStatisticsDataProps> = ({ label, value }) => {
  return (
    <Column>
      <Text $size="small">{label}</Text>
      <Title $semiBold $size="large">
        {value}
      </Title>
    </Column>
  );
};

interface ComponentStatisticsProps {
  statistics: CalculationStatisticSummaryResponse;
  showMinimalLayerStats?: boolean;
  showForcedProductsStats?: boolean;
}

/**
 * Pure presentation component for CalculationStatistics data
 * @param statistics
 * @param showForcedProductsStats
 * @param showMinimalLayerStats
 * @constructor
 */
const CalculationStatistics: React.FC<ComponentStatisticsProps> = ({
  statistics,
  showForcedProductsStats,
  showMinimalLayerStats,
}) => {
  const currency = useCurrency();

  return (
    <Row>
      <Space size="large">
        <CalculationStatisticsData label="Routes" value={formatNumber(statistics.routesCount)} />
        <CalculationStatisticsData
          label="Distinct products"
          value={formatNumber(statistics.productCount)}
        />
        <CalculationStatisticsData
          label="Picking positions"
          value={formatNumber(statistics.pickingPositionCount)}
        />
        <CalculationStatisticsData
          label="Value of replenished out-of-stocks"
          value={`${formatMonetaryValue(
            currency,
            statistics.filledStockoutValue,
          )} (${formatPercentage(
            computePercentage(statistics.filledStockoutValue, statistics.value),
          )})`}
        />
        {showMinimalLayerStats && (
          <CalculationStatisticsData
            label="Value of replenished minimum layers"
            value={`${formatMonetaryValue(
              currency,
              statistics.targetMinimalLayerValue,
            )} (${formatPercentage(
              computePercentage(statistics.targetMinimalLayerValue, statistics.value),
            )})`}
          />
        )}
        {showForcedProductsStats && (
          <CalculationStatisticsData
            label="Value of forced products"
            value={`${formatMonetaryValue(
              currency,
              statistics.sourceForcedProductValue,
            )} (${formatPercentage(
              computePercentage(statistics.sourceForcedProductValue, statistics.value),
            )})`}
          />
        )}
      </Space>
    </Row>
  );
};

export default CalculationStatistics;
