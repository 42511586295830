import {
  CompareType,
  IdentificationType,
  SkuCombinationResponse,
  TargetListItemComparisonInformationResponse,
  TargetListItemIdentificationResponse,
  TargetListItemQuantityRequest,
  TargetListItemsRequest,
  TargetListResponse,
} from '@ydistri/api-sdk';
import {
  AdministrationItemAction,
  AdministrationItemListHandlingMethod,
  AdministrationItemOrigin,
  AdministrationItemParser,
  AdministrationItemParserFunction,
  AdministrationItemRow,
} from '../common/administrationItemsTypes';
import {
  SKUData,
  TargetListInputData,
  TargetListItemsCompared,
  TargetListRow,
  TargetListValidatedItem,
} from './targetListsTypes';
import { TargetListsCollections } from '../../../swagger/collections';
import { selectParser } from '../common/administrationItemsLib';

const getTargetListItem = (
  cells: NodeListOf<HTMLTableCellElement>,
): Partial<TargetListInputData> => {
  const result: Partial<TargetListInputData> = {};

  cells.forEach((cell, index) => {
    const currentValue = cell.firstChild?.textContent ?? '';

    switch (index) {
      case 0:
        result.storeId = currentValue;
        break;
      case 1:
        result.productId = currentValue;
        break;
      case 2:
        {
          result.count = 0;
          if (currentValue.length > 0 && isDigitsOnly(currentValue)) {
            const tmpValue = parseInt(currentValue);
            if (!isNaN(tmpValue)) {
              result.count = tmpValue;
            }
          }
        }

        break;
    }
  });

  return result;
};

const getTargetListItemFromStrings = (cells: string[]): Partial<TargetListInputData> => {
  const result: Partial<TargetListInputData> = {};
  cells.forEach((cell, index) => {
    const currentValue = cell;
    switch (index) {
      case 0:
        result.storeId = currentValue;
        break;
      case 1:
        result.productId = currentValue;
        break;
      case 2:
        {
          const tmpValue = parseInt(currentValue);
          if (!isNaN(tmpValue)) {
            result.count = tmpValue;
          } else {
            result.count = 0;
          }
        }
        break;
    }
  });

  return result;
};

const digitsOnlyRegex = new RegExp('^-?\\d+$');
const isDigitsOnly = (content: string) => {
  return digitsOnlyRegex.test(content);
};

const validateTargetListItem = (item: Partial<TargetListInputData>): string[] => {
  const errors: string[] = [];

  if (item.storeId?.length === 0) {
    errors.push('StoreId cannot be empty');
  }

  if (!item.productId || item.productId.length === 0) {
    errors.push('ProductId cannot be empty');
  }

  if (item.count === undefined || item.count === 0) {
    errors.push('Product quantity cannot be empty');
  }

  if (item.count && item.count < 0) {
    errors.push('Product quantity cannot be a negative number');
  }

  return errors;
};

/**
 * Parse html table in content string. The table should have three columns with warehouse identification (string), product
 * identification (string) and quantity (number)
 * @param content  HTML containing a table to be parsed
 * @param parser DOMParser to be used for parsing. If not present a default DOMParser will be used.
 */
export const parseTabularDataFromHtml: AdministrationItemParserFunction<TargetListRow> = (
  content: string,
  parser?: DOMParser,
): TargetListRow[] => {
  if (!parser) {
    parser = new DOMParser();
  }

  const htmlDoc = parser.parseFromString(content, 'text/html');
  const rows = htmlDoc.querySelectorAll('table tr');

  const result: TargetListRow[] = [];

  rows.forEach((row, index) => {
    const cells = row.querySelectorAll('td');

    const rowData: Partial<TargetListInputData> = getTargetListItem(cells);
    const errors = validateTargetListItem(rowData);

    const resultRow: TargetListRow = {
      rowNumber: index + 1,
      inputData: rowData,
    };

    if (errors.length > 0) {
      resultRow.isError = true;
      resultRow.errors = errors;
    }

    result.push(resultRow);
  });

  return result;
};

/**
 * Parse the content string and return structured data.
 * The string should contain rows of three columns separated by either a space, comma, pipe, semicolon or tab.
 * @param content  plain text containing data to be parsed
 */
export const parseTabularDataFromPlainText: AdministrationItemParserFunction<TargetListRow> = (
  content,
): TargetListRow[] => {
  const result: TargetListRow[] = [];

  const rows = content.split(/\n/).filter(tmpRow => tmpRow.length > 0);
  rows.forEach((row, index) => {
    const cells = row.split(/[(,|;)]+|\t/);
    const rowData = getTargetListItemFromStrings(cells);
    const errors = validateTargetListItem(rowData);

    const resultRow: TargetListRow = {
      rowNumber: index + 1,
      inputData: rowData,
    };

    if (errors.length > 0) {
      resultRow.isError = true;
      resultRow.errors = errors;
    }

    result.push(resultRow);
  });

  return result;
};

/**
 * Parsers available for different content types.
 * text/html can be copied from Excel or a web page
 * text/plain can be typed directly or copied from a third party system's export
 */
const targetListParsers: AdministrationItemParser<TargetListRow>[] = [
  {
    contentType: 'text/html',
    parse: parseTabularDataFromHtml,
  },
  {
    contentType: 'text/plain',
    parse: parseTabularDataFromPlainText,
  },
];

export const selectTargetListParser = (
  data: DataTransfer,
): AdministrationItemParser<AdministrationItemRow<TargetListInputData>> | null => {
  return selectParser(data, targetListParsers);
};

export const getParserForContentType = (
  contentType: string,
): AdministrationItemParser<TargetListRow> | undefined => {
  return targetListParsers.find(parser => parser.contentType === contentType);
};

export const getParseableString = (
  data: TargetListValidatedItem[],
  warehouseIdentificationTypeId: IdentificationType,
  productIdentificationTypeId: IdentificationType,
  separator = '\t',
): string => {
  const translated = data.map(item => {
    const warehouseId =
      warehouseIdentificationTypeId === IdentificationType.CustomerId
        ? item.data.warehouse.customerWarehouseId
        : item.data.warehouse.code;
    const productId =
      productIdentificationTypeId === IdentificationType.CustomerId
        ? item.data.product.customerId
        : item.data.product.code;

    return `${warehouseId ?? ''}${separator}${productId ?? ''}${separator}${item.data.quantity}`;
  });
  return translated.join('\n');
};

export const getParseableStringFromPastedData = (
  data: AdministrationItemRow<TargetListInputData>[],
  separator = '\t',
): string => {
  const translated = data.map(row => {
    const rowData = row.inputData;
    return `${rowData.storeId ?? ''}${separator}${rowData.productId ?? ''}${separator}${
      rowData.count ?? ''
    }`;
  });
  return translated.join('\n');
};

/**
 * Filter out items in error
 * @param items
 */
export const filterValidItems = <T extends TargetListValidatedItem | TargetListRow>(
  items: T[],
): T[] => items.filter(item => !item.isError);

const filterNoActionItems = <T extends TargetListValidatedItem>(
  items: T[],
  handlingMethod: AdministrationItemListHandlingMethod,
): T[] => items.filter(item => item.actions[handlingMethod] !== AdministrationItemAction.NONE);

/**
 * Map items parsed from pasted data to items to be sent to
 * the compare backend service
 * @param rows
 */
export const mapParsedItemsToCompareRequest = (
  rows: TargetListRow[],
): TargetListItemQuantityRequest[] => {
  return filterValidItems(rows).map(row => ({
    warehouseIdentification: row.inputData.storeId ?? '',
    productIdentification: row.inputData.productId ?? '',
    quantity: row.inputData.count ?? 0,
  }));
};

/**
 * Combine warehouse id and product id with hash in between
 * to be used as unique key
 * @param warehouseId
 * @param productId
 */
const getWarehouseProductKey = (warehouseId: string, productId: string): string =>
  `${warehouseId}#${productId}`;

/**
 * Return a map where the key is a combination of warehouse and product identifications separated by hash.
 * @param responses
 */
const convertIdentificationResponseToMap = (
  responses: TargetListItemIdentificationResponse[],
): Map<string, TargetListItemIdentificationResponse> => {
  const result = new Map<string, TargetListItemIdentificationResponse>();
  for (const response of responses) {
    const key = getWarehouseProductKey(
      response.warehouseIdentification,
      response.productIdentification,
    );
    result.set(key, response);
  }

  return result;
};

const convertParsedItemToValidatedItem = (
  parsedItem: TargetListRow,
  warehouseIdentificationTypeId: IdentificationType,
  productIdentificationTypeId: IdentificationType,
): TargetListValidatedItem => {
  const sku: SKUData = {
    quantity: parsedItem.inputData.count ?? 0,
    warehouse: {
      name: '',
      customerWarehouseId: '',
      code: '',
    },
    product: {
      name: '',
      customerId: '',
      code: '',
      id: 0,
    },
  };

  if (warehouseIdentificationTypeId === IdentificationType.CustomerId) {
    sku.warehouse = {
      customerWarehouseId: parsedItem.inputData.storeId ?? '',
      code: '',
      name: '',
    };
  } else {
    sku.warehouse = {
      customerWarehouseId: '',
      code: parsedItem.inputData.storeId ?? '',
      name: '',
    };
  }

  if (productIdentificationTypeId === IdentificationType.CustomerId) {
    sku.product = {
      customerId: parsedItem.inputData.productId ?? '',
      name: '',
      code: '',
      id: 0,
    };
  } else {
    sku.product = {
      customerId: '',
      name: '',
      code: parsedItem.inputData.productId ?? '',
      id: 0,
    };
  }

  return {
    rowNumber: parsedItem.rowNumber,
    inputData: parsedItem.inputData,
    isError: parsedItem.isError,
    errors: parsedItem.errors ?? [],
    isDuplicate: parsedItem.isDuplicate,
    compareType: CompareType.Contains, //default compare result, will be set in parseComparisonResult()
    data: sku,
    origin: AdministrationItemOrigin.USER,
    actions: {
      [AdministrationItemListHandlingMethod.ADD]: AdministrationItemAction.NONE,
      [AdministrationItemListHandlingMethod.REPLACE]: AdministrationItemAction.NONE,
    },
  };
};

const convertSKUCombinationToMap = (
  skus: SkuCombinationResponse[],
  warehouseIdentificationTypeId: IdentificationType,
  productIdentificationTypeId: IdentificationType,
) => {
  const result = new Map<string, SkuCombinationResponse>();
  for (const sku of skus) {
    const warehouseId =
      warehouseIdentificationTypeId === IdentificationType.CustomerId
        ? sku.warehouse?.customerWarehouseId
        : sku.warehouse?.code;
    const productId =
      productIdentificationTypeId === IdentificationType.CustomerId
        ? sku.product?.customerId
        : sku.product?.code;
    const key = getWarehouseProductKey(warehouseId ?? '', productId ?? '');
    result.set(key, sku);
  }

  return result;
};

/**
 * Parse the result of comparison and extract items
 * to be inserted as new, existing items where the quantity changed (updated)
 * and items that have not changed.
 * @param result
 * @param parsedTargetListItems items parsed from pasted data
 */
export const parseComparisonResult = (
  result: TargetListItemComparisonInformationResponse,
  parsedTargetListItems: TargetListRow[],
): TargetListItemsCompared => {
  const unidentifiedItems = convertIdentificationResponseToMap(result.unidentifiedItems);
  const identifiedItems = convertSKUCombinationToMap(
    result.skuCombination,
    result.warehouseIdentificationTypeId,
    result.productIdentificationTypeId,
  );

  const itemsValidated: TargetListValidatedItem[] = [];

  for (const parsedItem of parsedTargetListItems) {
    const validatedItem = convertParsedItemToValidatedItem(
      parsedItem,
      result.warehouseIdentificationTypeId,
      result.productIdentificationTypeId,
    );

    if (!validatedItem.isError) {
      const key = getWarehouseProductKey(
        parsedItem.inputData.storeId ?? '',
        parsedItem.inputData.productId ?? '',
      );
      if (unidentifiedItems.has(key)) {
        validatedItem.isError = true;

        if (parsedItem.inputData.storeId) {
          if (result.unidentifiedWarehouses.includes(parsedItem.inputData.storeId)) {
            validatedItem.errors?.push(`Store ${parsedItem.inputData.storeId} was not found`);
          }
        }

        if (parsedItem.inputData.productId) {
          if (result.unidentifiedProducts.includes(parsedItem.inputData.productId)) {
            validatedItem.errors?.push(`Product ${parsedItem.inputData.productId} was not found`);
          }
        }
      } else {
        //was identified and returned
        if (identifiedItems.has(key)) {
          const sku = identifiedItems.get(key);
          if (sku) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- may be null after all
            validatedItem.compareType = sku.compareType ?? undefined;
            validatedItem.data.warehouse.name = sku.warehouse?.name ?? '';
            validatedItem.data.warehouse.code = sku.warehouse?.code ?? '';
            validatedItem.data.warehouse.customerWarehouseId = sku.warehouse?.customerWarehouseId;

            validatedItem.data.product.name = sku.product?.name ?? '';
            validatedItem.data.product.code = sku.product?.code ?? '';
            validatedItem.data.product.customerId = sku.product?.customerId;

            switch (sku.compareType) {
              case CompareType.Missing:
                validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
                  AdministrationItemAction.INSERT;
                validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
                  AdministrationItemAction.INSERT;
                break;
              case CompareType.Contains:
                validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
                  AdministrationItemAction.NONE;
                validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
                  AdministrationItemAction.NONE;
                break;
              case CompareType.Updated:
                validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
                  AdministrationItemAction.UPDATE;
                validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
                  AdministrationItemAction.UPDATE;
                break;
              default:
                //when null or unknown, we will treat it as missing
                validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
                  AdministrationItemAction.INSERT;
                validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
                  AdministrationItemAction.INSERT;
                break;
            }
            identifiedItems.delete(key);
          }
        }
      }
    }

    itemsValidated.push(validatedItem);
  }

  //now identifiedItems should only contain data retrieved from backend that were not present in pasted data
  //and should have CompareType.Contains
  //When handling method is REPLACE, these will be deleted and to display it to the user, we need to convert
  //this data to TargetListValidatedItem and add it to itemsValidated
  let rowNumber = itemsValidated[itemsValidated.length - 1].rowNumber + 1;

  identifiedItems.forEach(identifiedItem => {
    const validatedItem: TargetListValidatedItem = {
      rowNumber: rowNumber,
      inputData: {},
      compareType: identifiedItem.compareType,
      data: {
        quantity: identifiedItem.quantity,
        product: {
          name: identifiedItem.product?.name ?? '',
          code: identifiedItem.product?.code ?? '',
          customerId: identifiedItem.product?.customerId,
          id: identifiedItem.product?.id ?? 0,
        },
        warehouse: {
          name: identifiedItem.warehouse?.name ?? '',
          code: identifiedItem.warehouse?.code ?? '',
          customerWarehouseId: identifiedItem.warehouse?.customerWarehouseId,
        },
      },
      origin: AdministrationItemOrigin.BACKEND,
      actions: {
        [AdministrationItemListHandlingMethod.ADD]: AdministrationItemAction.NONE,
        [AdministrationItemListHandlingMethod.REPLACE]: AdministrationItemAction.REMOVE,
      },
    };

    itemsValidated.push(validatedItem);

    rowNumber++;
  });

  itemsValidated.sort((left, right) => {
    if (left.rowNumber > right.rowNumber) {
      return -1;
    } else {
      return 1;
    }
  });

  return {
    result,
    items: itemsValidated,
  };
};

/**
 * Create a request object that can be passed either to TargetListsCollections.patchTargetListItems() or TargetListsCollections.putTargetListItems() methods
 * @param items Validated items
 * @param warehouseIdentificationTypeId is warehouse identified by customer id or code
 * @param productIdentificationTypeId is product identified by customer id or code
 * @param handlingMethod
 */
export const createTargetListItemsPayload = (
  items: TargetListValidatedItem[],
  warehouseIdentificationTypeId: IdentificationType,
  productIdentificationTypeId: IdentificationType,
  handlingMethod?: AdministrationItemListHandlingMethod,
): TargetListItemsRequest => {
  let tmpPayload: TargetListValidatedItem[] = filterValidItems(items).filter(
    item => item.origin === AdministrationItemOrigin.USER,
  );

  //when adding items, do not send those that are already in target list and did not change
  if (handlingMethod === AdministrationItemListHandlingMethod.ADD) {
    tmpPayload = filterNoActionItems(tmpPayload, handlingMethod);
  }

  const payload = tmpPayload.map(item => ({
    quantity: item.data.quantity,
    warehouseIdentification:
      warehouseIdentificationTypeId === IdentificationType.CustomerId
        ? (item.data.warehouse.customerWarehouseId ?? '')
        : (item.data.warehouse.code ?? ''),
    productIdentification:
      productIdentificationTypeId === IdentificationType.CustomerId
        ? (item.data.product.customerId ?? '')
        : item.data.product.code,
  }));

  return {
    warehouseIdentificationTypeId,
    productIdentificationTypeId,
    targetIdentifications: payload,
  };
};

/**
 * Create a title suitable for a section in the Target List Administration page
 * @param targetList
 * @returns string with name, customer target list id and archived status
 */
export const getSectionTitle = (targetList: TargetListResponse): string => {
  return `${targetList.name ?? '-no title-'} ${targetList.isArchived ? ' - [archived]' : ''}`;
};

/**
 * Check if the list contains any errors
 * @param items List of validated items
 */
export const containsErrors = (items?: TargetListValidatedItem[]): boolean | undefined => {
  return items?.some(item => item.isError);
};

export const convertParsedItemsArrayToValidatedItem = (
  parsedItems: TargetListRow[],
  productIdentificationTypeId: IdentificationType,
  warehouseIdentificationTypeId: IdentificationType,
): TargetListValidatedItem[] => {
  const itemsValidated: TargetListValidatedItem[] = [];

  for (const parsedItem of parsedItems) {
    const validatedItem = convertParsedItemToValidatedItem(
      parsedItem,
      warehouseIdentificationTypeId,
      productIdentificationTypeId,
    );
    itemsValidated.push(validatedItem);
  }

  return itemsValidated;
};

export const validateTargetListContent = async (
  selectedTargetList: TargetListResponse,
  parsedTargetListItems: TargetListRow[],
  productIdentificationType: IdentificationType,
  warehouseIdentificationType: IdentificationType,
): Promise<TargetListItemsCompared> => {
  const payloadIdentification: TargetListItemsRequest = {
    productIdentificationTypeId: productIdentificationType,
    warehouseIdentificationTypeId: warehouseIdentificationType,
    targetIdentifications: mapParsedItemsToCompareRequest(parsedTargetListItems),
  };

  if (
    payloadIdentification.targetIdentifications &&
    payloadIdentification.targetIdentifications.length > 0
  ) {
    const response = await TargetListsCollections.skusCompareCreate(
      selectedTargetList.targetListId,
      payloadIdentification,
    );

    const itemsCompared = response.data.data;
    return parseComparisonResult(itemsCompared, parsedTargetListItems);
  } else {
    const items = convertParsedItemsArrayToValidatedItem(
      parsedTargetListItems,
      warehouseIdentificationType,
      productIdentificationType,
    );

    return {
      items,
    };
  }
};
