import React from 'react';
import { useGetStatusesQuery } from '../../../apis/apiApplication';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { ApplicationStatus } from '@ydistri/api-sdk';
import OverlayMessage, { OverlayCoverDiv, OverlayMessageIcon } from './OverlayComponents';

/**
 * Overlay that is displayed when the application is closed
 * Must be placed inside div with "position: relative"
 * @constructor
 */
const ApplicationClosedOverlay: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: statuses } = useGetStatusesQuery(templateOrCalculation);

  const showOverlay = statuses?.applicationStatus === ApplicationStatus.Closed;

  if (showOverlay) {
    return (
      <OverlayCoverDiv $topOffset={60}>
        <OverlayMessage
          icon={OverlayMessageIcon.LOCKED}
          title="Computations temporarily unavailable"
          content={statuses.statusInfo ?? ''}
        />
      </OverlayCoverDiv>
    );
  }
};

export default ApplicationClosedOverlay;
