import AdministrationListItemCount, {
  AdministrationListItemName,
} from './AdministrationListComponents';
import React, { useMemo } from 'react';
import { EntityListResponse, ProductListResponse, TargetListResponse } from '@ydistri/api-sdk';
import CountableText from '../../../components/global/CountableText/CountableText';
import { styled } from 'styled-components';
import ListItemPrimary from '../../../components/global/typography/ListItemPrimary';
import { Text, Row, ReadOnlyItem } from '@ydistri/ds';
import { CountableDetail } from './administrationItemsTypes';
import { formatDate, formatDateTime } from '@ydistri/utils';
import { Popover } from 'antd';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const IconWrapper = styled.div`
  margin-left: auto;
`;

interface AdministrationItemCardProps {
  item: ProductListResponse | TargetListResponse | EntityListResponse;
  countable?: CountableDetail[];
  icon?: React.ReactNode;
  $nameWidth?: string;
}

const AdministrationItemCard: React.FC<AdministrationItemCardProps> = ({
  item,
  countable,
  icon,
  $nameWidth,
  ...rest
}) => {
  const countableText = useMemo(() => {
    if ('entityListImport' in item && item.entityListImport) {
      return <Text>- user import -</Text>;
    }

    if (countable?.every(countableItem => countableItem.count === 0)) {
      return <Text>empty</Text>;
    }

    return countable?.map(countable => (
      <CountableText
        key={countable.description}
        value={countable.count}
        word={countable.description}
        highlighting="none"
      />
    ));
  }, [item, countable]);

  const popoverContent = useMemo(
    () => (
      <>
        <ReadOnlyItem label="Created at" value={formatDateTime(item.createdAt)} />
        <ReadOnlyItem label="Updated at" value={formatDateTime(item.lastUpdatedAt)} />
      </>
    ),
    [item.createdAt],
  );

  return (
    <Row $flexGrow={1} $alignItems="center">
      <CardWrapper data-type="AdministrationItemCard" {...rest}>
        <AdministrationListItemName data-type="AdministrationItem-Name" $width={$nameWidth}>
          <ListItemPrimary>
            {`${item.name ?? ''}${item.isArchived ? ' [archived]' : ''}`}
          </ListItemPrimary>
        </AdministrationListItemName>
        <Row $justifyContent="space-between" $alignItems="center" $width="100%">
          {countableText && (
            <AdministrationListItemCount>{countableText}</AdministrationListItemCount>
          )}
          <Popover content={popoverContent}>
            <Text>{formatDate(item.lastUpdatedAt)}</Text>
          </Popover>
        </Row>
      </CardWrapper>
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </Row>
  );
};

export default AdministrationItemCard;
