import React, { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router';
import { useUser } from '../hooks/useUser';
import { MeCollection } from '../swagger/collections-identity';
import { setUser } from '../store/userSlice';
import { auth } from '../app';
import { Unauthorized } from '@ydistri/ds';
import { useAppDispatch } from '../store';

enum ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
}

interface PrivateRouteProps {
  component: React.ComponentType;
  path?: string;
  roles?: ROLE[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, roles = [] }) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- no sure why this is a problem
  const isAuthenticated = useMemo(() => auth.isAuthenticated(), []);

  useEffect(() => {
    MeCollection.getMe()
      .then(({ data }) => {
        dispatch(setUser(data.data));
      })
      // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we know the type
      .catch((error: Error) => {
        // eslint-disable-next-line no-console -- We want the output
        console.log(`Error getting information about current user: ${error.message}`);
      });
  }, [dispatch]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we do not want to filter the roles every time
  const userHasRequiredRole = useMemo(
    () =>
      roles.length === 0 ||
      roles.filter(requiredRole => {
        switch (requiredRole) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- I know there is only one case
          case ROLE.SUPER_ADMIN:
            return user?.isSuperUser;
        }
      }).length > 0,
    [roles, user?.isSuperUser],
  );

  if (isAuthenticated && userHasRequiredRole) {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- We want to use the component as a component
    const RouteComponent = component;
    return <RouteComponent />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Unauthorized />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
