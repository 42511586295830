import { styled } from 'styled-components';
import { Link } from 'react-router';

const AdministrationItemLink = styled(Link)`
  display: flex;
  flex-grow: 1;

  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: black;
  }
`;

export default AdministrationItemLink;
