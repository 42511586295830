import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  RedistributionDisplayMode,
  resetImportState,
  setRedistributionDisplayMode,
} from '../../../../../../Calculations/calculationsSlice';
import { ReduxState, useAppDispatch } from '../../../../../../../store';
import { useDeleteCalculationImportMutation } from '../../apiCalculationRedistributionImport';
import { useTemplateOrCalculation } from '../../../../../../../hooks/useTemplateOrCalculation';
import { ErrorType } from '../../../../../../../apis/api';
import { addToast } from '@ydistri/utils';
import { ProcessingStep } from '../../redistributionImportTypes';

export type ResetImport = () => void;

const useResetImport = (): ResetImport => {
  const dispatch = useAppDispatch();
  const calculation = useTemplateOrCalculation();

  const currentStepIndex = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.currentStepIndex,
  );

  const verificationTimeStamp = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.verificationTimeStamp,
  );

  const [deleteCalculationImport] = useDeleteCalculationImportMutation();

  return useCallback(() => {
    const resetState = () => {
      dispatch(resetImportState());
      dispatch(setRedistributionDisplayMode(RedistributionDisplayMode.LIST));
    };

    // if import has already been created we need to delete the import
    if (currentStepIndex === ProcessingStep.VALIDATION) {
      deleteCalculationImport({
        calculationId: calculation.id,
        verificationTimeStamp,
      })
        .unwrap()
        .then(() => {
          resetState();
        })
        .catch((err: unknown) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
          const error = err as ErrorType;
          dispatch(
            addToast({
              message: `Removing import failed with message: ${error.response.data.Messages.join(', ')}`,
              isError: true,
            }),
          );
        });
    } else {
      resetState();
    }
  }, [calculation.id, currentStepIndex, deleteCalculationImport, dispatch, verificationTimeStamp]);
};

export default useResetImport;
