import { computeRemSize } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../../../store';
import { EntityListType } from '@ydistri/api-sdk';
import { Steps } from 'antd';
import { COLORS } from '../../../../../styles/style';
import EntityListStepImport from './EntityListStepImport';
import { StepProps } from 'antd/es/steps';
import {
  EntityListImportStep,
  EntityListImportStepRunning,
  getNumericStep,
  resetEntityListItems,
  setActiveScreenType,
  setProductItemsContent,
} from '../../entityListAdministrationSlice';
import EntityListStepValidation from './EntityListStepValidation';
import EntityListStepFinalize from './EntityListStepFinalize';
import { IMPORT_STEPS } from '../../entityListsLib';
import { styled } from 'styled-components';
import ExitEditModeButton from '../../../../../components/buttons/ExitEditModeButton';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import { LoadingOutlined } from '@ant-design/icons';

export type YStepProps = { stepId: EntityListImportStep; content: React.ReactNode } & StepProps;

export const getSteps = (
  entityListType: EntityListType,
  currentStepId: EntityListImportStep,
  runningData: EntityListImportStepRunning,
): YStepProps[] => {
  const getStepStatus = (stepId: EntityListImportStep): StepProps['status'] => {
    const currentStep = getNumericStep(currentStepId);
    const step = getNumericStep(stepId);

    if (runningData[stepId]) return 'process';
    if (currentStep > step) return 'finish';
    return undefined;
  };

  const statuses: Record<EntityListImportStep, StepProps['status']> = {
    [IMPORT_STEPS.ITEM_IMPORT]: getStepStatus(IMPORT_STEPS.ITEM_IMPORT),
    [IMPORT_STEPS.ITEM_VALIDATION]: getStepStatus(IMPORT_STEPS.ITEM_VALIDATION),
    [IMPORT_STEPS.FINALIZATION]: getStepStatus(IMPORT_STEPS.FINALIZATION),
  };

  return [
    {
      stepId: IMPORT_STEPS.ITEM_IMPORT,
      title: 'Import items',
      icon: statuses[IMPORT_STEPS.ITEM_IMPORT] === 'process' ? <LoadingOutlined /> : undefined,
      content: <EntityListStepImport entityListType={entityListType} />,
      status: statuses[IMPORT_STEPS.ITEM_IMPORT],
    },
    {
      stepId: IMPORT_STEPS.ITEM_VALIDATION,
      title: 'Validate items',
      icon: statuses[IMPORT_STEPS.ITEM_VALIDATION] === 'process' ? <LoadingOutlined /> : undefined,
      content: <EntityListStepValidation entityListType={entityListType} />,
      status: statuses[IMPORT_STEPS.ITEM_VALIDATION],
    },
    {
      stepId: IMPORT_STEPS.FINALIZATION,
      title: 'Finalize',
      icon: statuses[IMPORT_STEPS.FINALIZATION] === 'process' ? <LoadingOutlined /> : undefined,
      content: <EntityListStepFinalize entityListType={entityListType} />,
      status: statuses[IMPORT_STEPS.FINALIZATION],
    },
  ];
};

const ContentWrapper = styled.div`
  padding: 1rem;
  background-color: ${COLORS.GREY_LIGHT};
  border-radius: 1rem;
  border: 1px dashed ${COLORS.GREY_BORDER};
  margin-top: 16px;
`;

const StepsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: ${computeRemSize(50)};
`;

const StepsWrapper = styled.div`
  display: flex;
  min-width: ${computeRemSize(600)};
  max-width: ${computeRemSize(800)};
  align-items: center;
  align-self: center;
  gap: 4rem;
`;

interface EntityListStepsProps {
  entityListType: EntityListType;
}

const EntityListSteps: React.FC<EntityListStepsProps> = ({ entityListType }) => {
  const importStep = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].importStep,
  );

  const importStepRunning = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].importStepRunning,
  );

  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  const dispatch = useAppDispatch();

  const onExitEditMode = useCallback(() => {
    dispatch(setActiveScreenType({ entityListType, data: ActiveScreenType.DETAIL, forced: true }));
    dispatch(setProductItemsContent({ entityListType, data: '' }));
    dispatch(resetEntityListItems({ entityListType }));
  }, [dispatch, entityListType]);

  const steps = useMemo(
    () => getSteps(entityListType, importStep, importStepRunning),
    [entityListType, importStep, importStepRunning],
  );

  if (!selectedEntityList) {
    return 'No list selected';
  } else {
    return (
      <>
        <StepsRow>
          <StepsWrapper>
            <Steps current={getNumericStep(importStep) - 1} items={steps} />
          </StepsWrapper>
          <ButtonWrapper>
            <ExitEditModeButton onClick={onExitEditMode} />
          </ButtonWrapper>
        </StepsRow>
        <ContentWrapper>{steps.find(s => s.stepId === importStep)?.content}</ContentWrapper>
      </>
    );
  }
};

export default EntityListSteps;
