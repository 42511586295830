import { useParams } from 'react-router';
import { useMemo } from 'react';

const useIsRootCategory = (): boolean => {
  const { slug } = useParams();

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- I guess we do not want to recompute this value on every render
  return useMemo(() => slug === '1', [slug]);
};

export default useIsRootCategory;
