import React, { useMemo } from 'react';
import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import { transformToPascalCase } from '@ydistri/utils';
import { MdArchive, MdUnarchive } from 'react-icons/md';

interface ToggleArchivedAdministrationItemButtonProps {
  itemName: string;
  archived?: boolean;
  disabled?: boolean;
  onClick: () => void;
  size?: IconButtonSize;
}

const ToggleArchivedAdministrationItemButton: React.FC<
  ToggleArchivedAdministrationItemButtonProps
> = ({
  archived,
  disabled,
  onClick,
  itemName,
  size = 'small',
}: ToggleArchivedAdministrationItemButtonProps) => {
  const buttonIcon = useMemo(() => {
    if (archived) {
      return <MdUnarchive size={IconButton_IconSizes[size]} />;
    } else {
      return <MdArchive size={IconButton_IconSizes[size]} />;
    }
  }, [size, archived]);

  let buttonTooltip = '';
  if (disabled) {
    if (!archived) {
      buttonTooltip = `Cannot archive the ${itemName} because it is currently used in a configuration`;
    } else {
      buttonTooltip = `Cannot unarchive the ${itemName}`;
    }
  } else {
    if (!archived) {
      buttonTooltip = `Archive ${itemName}`;
    } else {
      buttonTooltip = `Unarchive ${itemName}`;
    }
  }

  return (
    <IconButton
      size={size}
      icon={buttonIcon}
      tooltip={buttonTooltip}
      tooltipPosition="left"
      onClick={onClick}
      disabled={disabled}
      data-testid={transformToPascalCase(`Toggle Archived ${itemName}`)}
    />
  );
};

export default ToggleArchivedAdministrationItemButton;
