import React, { useMemo } from 'react';
import {
  getScopeConfigurationDefinitions,
  isScopeCorrect,
  scopeConfigurationErrorStrings,
  ScopeConfigurationErrorType,
  ViewMode,
} from './scopeLib';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { useScope } from './useScope';
import { Column, Row, Text } from '@ydistri/ds';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import ScopeConfigurationDisplay from './ScopeConfigurationDisplay';
import { ConfigurationRuleSetupType } from '@ydistri/api-sdk';
import { useCalculationConfiguration } from './useCalculationConfiguration';

interface ScopeConfigurationViewProps {
  scopeId: number;
  viewMode: ViewMode;
  // highlightError?: boolean;
}

const ScopeConfigurationView: React.FC<ScopeConfigurationViewProps> = ({
  scopeId,
  viewMode,
  // highlightError,
}) => {
  const scopeById = useScope(scopeId);
  const modalScope = useSelector((state: ReduxState) => state.scope.modalScope);
  const modalChanged = useSelector((state: ReduxState) => state.scope.modalChanged);

  const templateOrCalculation = useTemplateOrCalculation();
  const { useClosing, useSuperTarget } = useCalculationConfiguration();

  const isModal =
    [ViewMode.ADJUST_SCOPE_MODAL, ViewMode.NEW_EXCEPTION_MODAL].includes(viewMode) &&
    scopeId === modalScope?.id;

  const scope = useMemo(() => (isModal ? modalScope : scopeById), [isModal, modalScope, scopeById]);

  const scopeConfiguration = useMemo(() => scope?.configuration, [scope]);

  const configDefinitions = useMemo(
    () =>
      getScopeConfigurationDefinitions({
        scopeId,
        viewMode,
        useClosing,
        useAsSuperTarget: useSuperTarget,
        disabled: templateOrCalculation.type === 'Calculation',
      }),
    [scopeId, useSuperTarget, useClosing, viewMode, templateOrCalculation.type],
  );

  const [configurationErrorType, configurationError] = useMemo((): [
    ScopeConfigurationErrorType,
    boolean,
  ] => {
    if (scopeConfiguration && scope?.entities) {
      const errorType = isScopeCorrect(
        scope,
        useClosing,
        useSuperTarget,
        true,
        viewMode !== ViewMode.GENERAL_CONFIGURATION,
      );
      return [errorType, errorType !== ScopeConfigurationErrorType.CORRECT];
    }
    return [ScopeConfigurationErrorType.ERROR, true];
  }, [scopeConfiguration, scope, viewMode, useClosing, useSuperTarget]);

  if (!scopeConfiguration) {
    return null;
  }

  const displayError =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- we want the or here
    ((isModal && modalChanged) || !isModal) &&
    configurationError &&
    viewMode !== ViewMode.GENERAL_CONFIGURATION;

  return (
    <Row $gap="0.5rem" $justifyContent="space-between">
      <Column $gap="0.5rem">
        <ScopeConfigurationDisplay
          definition={configDefinitions[ConfigurationRuleSetupType.UseAsSource]}
          scopeConfiguration={scopeConfiguration}
        />
        <ScopeConfigurationDisplay
          definition={configDefinitions[ConfigurationRuleSetupType.UseAsTarget]}
          scopeConfiguration={scopeConfiguration}
        />
        <ScopeConfigurationDisplay
          definition={configDefinitions[ConfigurationRuleSetupType.IsEnabled]}
          scopeConfiguration={scopeConfiguration}
        />
        {(viewMode === ViewMode.EXCEPTION || viewMode === ViewMode.NEW_EXCEPTION_MODAL) && (
          <>
            {useClosing && (
              <ScopeConfigurationDisplay
                definition={configDefinitions[ConfigurationRuleSetupType.IsClosing]}
                scopeConfiguration={scopeConfiguration}
              />
            )}
            {useSuperTarget && (
              <ScopeConfigurationDisplay
                definition={configDefinitions[ConfigurationRuleSetupType.IsSuperTarget]}
                scopeConfiguration={scopeConfiguration}
              />
            )}
          </>
        )}
      </Column>
      <Column $gap="0.5rem" $alignItems="end">
        <ScopeConfigurationDisplay
          definition={
            configDefinitions[ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions]
          }
          scopeConfiguration={scopeConfiguration}
        />
        <ScopeConfigurationDisplay
          definition={configDefinitions[ConfigurationRuleSetupType.MaxNumberOfTargetStores]}
          scopeConfiguration={scopeConfiguration}
        />
        <ScopeConfigurationDisplay
          definition={configDefinitions[ConfigurationRuleSetupType.MaxNumberOfSourceStores]}
          scopeConfiguration={scopeConfiguration}
        />
        <ScopeConfigurationDisplay
          definition={configDefinitions[ConfigurationRuleSetupType.MinimumRouteValue]}
          scopeConfiguration={scopeConfiguration}
        />
        {displayError && (
          <Text $type="danger">{scopeConfigurationErrorStrings[configurationErrorType]}</Text>
        )}
      </Column>
    </Row>
  );
};

export default ScopeConfigurationView;
