import { RedistributionImportInputData } from './redistributionImportTypes';
import {
  ImportedItemParser,
  ImportedItemRow,
  parseTabularDataFromHtml,
  parseTabularDataFromPlainText,
  selectParser,
} from './importLib';

export const getParseableStringFromPastedData = (
  data: ImportedItemRow<RedistributionImportInputData>[],
  separator = '\t',
  keys?: (keyof RedistributionImportInputData)[],
): string => {
  const translated = data.map(row => {
    const rowData = row.inputData;

    const result: string[] = [];
    //use only data based on keys that are provided or everything if keys are not provided
    if (keys) {
      keys.forEach(key => {
        result.push(String(rowData[key]));
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know that rowData is of type RedistributionImportInputData
      const objectKeys = Object.keys(rowData) as (keyof RedistributionImportInputData)[];
      objectKeys.forEach(key => {
        result.push(String(rowData[key]));
      });
    }

    return result.join(separator);
  });
  return translated.join('\n');
};

/**
 * Parsers available for different content types.
 * text/html can be copied from Excel or a web page
 * text/plain can be typed directly or copied from a third party system's export
 */
const redistributionParsers: ImportedItemParser<RedistributionImportInputData>[] = [
  {
    contentType: 'text/html',
    parse: parseTabularDataFromHtml,
  },
  {
    contentType: 'text/plain',
    parse: parseTabularDataFromPlainText,
  },
];

export const selectRedistributionImportParser = (
  data: DataTransfer,
): ImportedItemParser<RedistributionImportInputData> | null => {
  return selectParser(data, redistributionParsers);
};

export const getParserForContentType = (
  contentType: string,
): ImportedItemParser<RedistributionImportInputData> | undefined => {
  return redistributionParsers.find(parser => parser.contentType === contentType);
};

/**
 * Generates a row key based on the provided identification keys.
 * To be used with the markDuplicates function.
 * @param row
 * @param identificationKeys keys that should be used to identify the row
 */
const getRowKey = <T>(row: Partial<T>, identificationKeys: (keyof T)[]): string => {
  return identificationKeys.map(key => row[key]).join('-');
};

/**
 * Rows may contain duplicate data which is rows with the same product, source and target store identifiers.
 * This function marks rows that are such duplicates.
 * @param rows
 * @param identificationKeys keys that should be used to identify the row
 */
export const markDuplicates = <T>(
  rows: ImportedItemRow<T>[],
  identificationKeys: (keyof T)[],
): void => {
  const keyMap: Record<string, number | undefined> = {};

  rows.forEach((row, index) => {
    const rowKey = getRowKey(row.inputData, identificationKeys);
    if (keyMap[rowKey]) {
      row.isError = true;
      row.isDuplicate = true;

      const duplicateMessage = `Is duplicate to row ${keyMap[rowKey]} from original data`;
      if (!row.errors) {
        row.errors = [duplicateMessage];
      } else {
        row.errors.push(duplicateMessage);
      }
    } else {
      keyMap[rowKey] = index + 1;
    }
  });
};
