import React, { useMemo } from 'react';
import { ActionsBar } from '@ydistri/ds';
import ToggleArchivedAdministrationItemButton from '../../../common/buttons/ToggleArchivedAdministrationItemButton';
import DeleteIconButton from '../../../../../components/buttons/DeleteIconButton';
import { transformToPascalCase } from '@ydistri/utils';
import EditIconButton from '../../../../../components/buttons/EditIconButton';
import EditContentIconButton from '../../../../../components/buttons/EditContentIconButton';
import { EntityListSourceType } from '@ydistri/api-sdk';

interface AdministrationItemActionsProps {
  entityListSourceType?: EntityListSourceType;
  itemName: string;
  disabled?: boolean;
  deletable?: boolean;
  archivable?: boolean;
  archived?: boolean;
  onDelete?: () => void;
  onToggleArchived?: () => void;
  onEdit?: () => void;
  onContentEdit?: () => void;
  deleteTooltip?: string;
}

const AdministrationItemActions: React.FC<AdministrationItemActionsProps> = ({
  entityListSourceType,
  itemName,
  archivable,
  archived,
  onToggleArchived,
  disabled,
  deletable,
  onDelete,
  onEdit,
  onContentEdit,
  deleteTooltip,
}) => {
  const toggleArchiveButton = useMemo(() => {
    if (onToggleArchived) {
      return (
        <ToggleArchivedAdministrationItemButton
          itemName={itemName}
          onClick={onToggleArchived}
          //eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- disabled may be false here, we need to check other variables
          disabled={disabled || !archivable || entityListSourceType !== EntityListSourceType.User}
          archived={archived}
        />
      );
    }
    return null;
  }, [entityListSourceType, onToggleArchived, itemName, disabled, archivable, archived]);

  const deletableButton = useMemo(() => {
    if (onDelete) {
      const title = `Delete ${itemName}`;
      const tooltip = deleteTooltip ? deleteTooltip : `${title}...`;
      return (
        <DeleteIconButton
          size="small"
          tooltip={tooltip}
          onClick={onDelete}
          //eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- disabled may be false here, we need to check other variables
          disabled={disabled || !deletable}
          data-testid={transformToPascalCase(title)}
        />
      );
    }
  }, [onDelete, itemName, deleteTooltip, disabled, deletable]);

  const editButton = useMemo(() => {
    if (!archived && onEdit) {
      const title = `Edit ${itemName} Attributes`;
      return (
        <EditIconButton
          size="small"
          tooltip={title}
          onClick={onEdit}
          disabled={disabled}
          data-testid={transformToPascalCase(title)}
        />
      );
    }
  }, [archived, onEdit, itemName, disabled]);

  const editContentButton = useMemo(() => {
    if (!archived && onContentEdit) {
      const title = `Edit ${itemName} Content`;
      return (
        <EditContentIconButton
          size="small"
          tooltip={title}
          onClick={onContentEdit}
          disabled={disabled}
          data-testid={transformToPascalCase(title)}
        />
      );
    }
  }, [archived, disabled, itemName, onContentEdit]);

  if (entityListSourceType && entityListSourceType !== EntityListSourceType.User) {
    return <ActionsBar>No actions for type {entityListSourceType}</ActionsBar>;
  }

  return (
    <ActionsBar>
      {editContentButton}
      {editButton}
      {deletableButton}
      {toggleArchiveButton}
    </ActionsBar>
  );
};

export default AdministrationItemActions;
