import { LabeledToggle, LabelPlacement, ToggleProps } from '@ydistri/ds';
import React from 'react';

interface ShowArchivedItemsToggleProps extends ToggleProps {
  archivedItemsCount?: number;
}

const ShowArchivedItemsToggle: React.FC<ShowArchivedItemsToggleProps> = ({
  archivedItemsCount,
  ...props
}) => {
  let label = 'Show archived';
  if (archivedItemsCount) {
    label = `${label} (${archivedItemsCount})`;
  }

  return (
    <LabeledToggle
      label={label}
      {...props}
      id="ShowArchivedItemsToggle"
      labelPlacement={LabelPlacement.RIGHT}
    />
  );
};

export default ShowArchivedItemsToggle;
