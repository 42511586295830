import {
  ForecastMonthlyOverviewResponse,
  ForecastValueResponse,
  NonSaleDailyOverviewResponse,
  NonSaleOverviewResponse,
  OverviewValueType,
  SaleDailyOverviewResponse,
  SaleMonthlyOverviewResponse,
  SaleQuantityResponse,
  TransactionOverviewType,
  TransactionValueResponse,
} from '@ydistri/api-sdk';

import {
  LineTypesTuple,
  TChartBarInfo,
  TChartLine,
  TChartLineInfo,
  TCumulativeLine,
  TLineType,
  TParsedDailyTransactions,
  TParsedMonthlyTransactionsData,
  TParsedTransactions,
} from './saleChartsTypes';

import cloneDeep from 'lodash.clonedeep';
import { ChartData, ChartDataset } from 'chart.js';
import { getForecastApproachLabel } from '@ydistri/utils';

export const defaultLine: TChartLineInfo = {
  type: 'line',
  data: [],
  tension: 0,
  fill: false,
  backgroundColor: 'rgba(0,0,0,0.4)',
  borderColor: 'rgba(0,0,0,1)',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  borderWidth: 2,
  pointBorderColor: 'rgba(0,0,0,1)',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
  pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
  pointHoverBorderWidth: 2,
  pointRadius: 2,
  pointHitRadius: 3,
  hidden: false,
};

export const defaultBar: TChartBarInfo = {
  type: 'bar',
  data: [],
  backgroundColor: 'rgba(0,0,0,0.4)',
  borderColor: 'rgba(0,0,0,1)',
  borderWidth: 2,
  hidden: false,
  barThickness: 5,
};

export const saleUsedForForecast: TLineType = {
  priority: 0,
  type: 'Sale',
  supplyMultiplier: 0,
  chartDatasetType: 'line',
  name: 'SaleUsedForForecast',
  label: 'Sale used for forecast',
  lineInfo: {
    ...defaultLine,
    backgroundColor: 'rgba(255, 204, 153,0.4)',
    borderColor: 'rgba(255, 204, 153,1)',
    borderWidth: 7,
    pointBorderColor: 'rgba(255, 204, 153,1)',
    pointHoverBackgroundColor: 'rgba(255, 204, 153,1)',
    pointHoverBorderColor: 'rgba(255, 204, 153,1)',
  },
};

export const saleTypesDefault: TLineType[] = [
  {
    priority: 1,
    type: 'Sale',
    supplyMultiplier: -1,
    chartDatasetType: 'line',
    name: 'SaleRegular',
    label: 'Sale Regular',
    lineInfo: {
      ...defaultLine,
    },
  },
  {
    priority: 2,
    type: 'Sale',
    supplyMultiplier: -1,
    chartDatasetType: 'line',
    name: 'SalePromo',
    label: 'Sale Promo',
    lineInfo: {
      ...defaultLine,
      backgroundColor: 'rgba(159, 159, 159, .13)',
      borderColor: 'rgba(59, 59, 59, 1)',
      borderDash: [6],
      pointHoverBorderColor: 'rgba(59, 59, 59, 1)',
    },
  },
  {
    priority: 3,
    type: 'Sale',
    supplyMultiplier: -1,
    chartDatasetType: 'line',
    name: 'SaleSellout',
    label: 'Sale Sellout',
    lineInfo: {
      ...defaultLine,
      fill: '-1',
      backgroundColor: 'rgba(159, 159, 159, .13)',
      borderColor: 'rgba(0, 0, 0, .7)',
      borderDash: [6],
      pointHoverBorderColor: 'rgba(0, 0, 0, .7)',
    },
  },
];

export const supplyLineDefault: TLineType = {
  priority: 1,
  type: 'Supply',
  supplyMultiplier: 0,
  chartDatasetType: 'line',
  name: 'AvailableSupply',
  label: 'Available supply',
  lineInfo: {
    type: 'line',
    data: [],
    tension: 0,
    fill: '-5',
    backgroundColor: 'rgba(187,229,255,0.1)',
    borderColor: 'rgba(163,207,232,1)',
    pointRadius: 1,
    stepped: 'middle',
  },
};

export const inboundOutboundTypesDefault: TLineType[] = [
  {
    priority: 1,
    type: 'InboundOutbound',
    supplyMultiplier: 1,
    displayLegend: true,
    chartDatasetType: 'bar',
    name: 'Inbound',
    label: 'Inbounds',
    // stack: 'inbound-outbound-stack',
    barInfo: {
      ...defaultBar,
      // backgroundColor: 'rgba(148,255,166,0.7)',
      // borderColor: 'rgba(148,255,166, .9)',
      backgroundColor: 'rgba(102,177,115,0.7)',
      borderColor: 'rgba(102,177,115, .9)',
    },
  },
  {
    priority: 2,
    type: 'InboundOutbound',
    supplyMultiplier: -1,
    displayLegend: true,
    chartDatasetType: 'bar',
    name: 'Outbound',
    label: 'Outbounds',
    // stack: 'inbound-outbound-stack',
    barInfo: {
      ...defaultBar,
      // backgroundColor: 'rgba(255,168,148,0.7)',
      // borderColor: 'rgba(255,168,148,0.9)',
      backgroundColor: 'rgba(255,122,93,0.7)',
      borderColor: 'rgba(255,122,93,0.9)',
    },
  },
];

export const FORECAST_CONFIDENCES = [50, 75, 80] as const;

export const forecastTypesDefault: TLineType[] = [
  {
    priority: 1,
    type: 'Forecast',
    supplyMultiplier: 0,
    chartDatasetType: 'line',
    name: 'ForecastMin',
    label: 'Forecast min',
    lineInfo: {
      ...defaultLine,
      backgroundColor: 'rgba(97, 225, 0, 0.4)',
      borderColor: 'rgba(97, 225, 0, 1)',
      borderWidth: 3,
      pointBorderColor: 'rgba(97, 225, 0, 1)',
      pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
      pointHoverBorderColor: 'rgba(97, 225, 0, 1)',
    },
  },
  {
    priority: 2,
    type: 'Forecast',
    supplyMultiplier: 0,
    chartDatasetType: 'line',
    name: 'ForecastMax',
    label: 'Forecast max',
    lineInfo: {
      ...defaultLine,
      backgroundColor: 'rgba(155, 255, 0, .2)',
      borderColor: 'rgba(255, 0, 0, 1)',
      borderWidth: 4,
      pointBorderColor: 'rgba(255, 0, 0, 1)',
      pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
      pointHoverBorderColor: 'rgba(255, 0, 0, 1)',
    },
  },
] as const;

export type ForecastName =
  | 'ForecastMin'
  | 'ForecastMax'
  | `ForecastMax${(typeof FORECAST_CONFIDENCES)[number]}`
  | `ForecastMin${(typeof FORECAST_CONFIDENCES)[number]}`;

export const getForecastTypes = (confidences: number[]): TLineType[] => {
  const forecastLines: TLineType[] = [];
  const opacity = ['.2', '.45', '.7', '1'];

  FORECAST_CONFIDENCES.forEach((confidence, index) => {
    if (confidences.includes(confidence)) {
      forecastLines.push({
        priority: FORECAST_CONFIDENCES.length + (index + 1),
        type: 'Forecast',
        chartDatasetType: 'line',
        supplyMultiplier: 0,
        name: `ForecastMin${confidence}`,
        label: `Forecast min ${getForecastApproachLabel(confidence)}`,
        lineInfo: {
          ...defaultLine,
          borderColor: `rgba(97, 225, 0, ${opacity[index]})`,
          borderWidth: 3,
          pointBorderColor: `rgba(97, 225, 0, ${opacity[index]})`,
          pointHoverBackgroundColor: `rgba(255, 255, 255, ${opacity[index]})`,
          pointHoverBorderColor: `rgba(97, 225, 0, ${opacity[index]})`,
        },
      });

      forecastLines.unshift({
        priority: FORECAST_CONFIDENCES.length - index,
        type: 'Forecast',
        chartDatasetType: 'line',
        supplyMultiplier: 0,
        name: `ForecastMax${confidence}`,
        label: `Forecast max ${getForecastApproachLabel(confidence)}`,
        lineInfo: {
          ...defaultLine,
          borderColor: `rgba(255, 0, 0, ${opacity[index]})`,
          borderWidth: 4,
          pointBorderColor: `rgba(255, 0, 0, ${opacity[index]})`,
          pointHoverBackgroundColor: `rgba(255, 255, 255, ${opacity[index]})`,
          pointHoverBorderColor: `rgba(255, 0, 0, ${opacity[index]})`,
        },
      });
    }
  });

  return forecastLines;
};

const getTransactionOverviewType = (
  overview: TransactionValueResponse | ForecastValueResponse,
): string => {
  return `${overview.transactionOverviewType}${
    'confidence' in overview ? overview.confidence : ''
  }`;
};

const getMonthlySaleData = (
  monthlySale: SaleMonthlyOverviewResponse,
  transactionType: string,
  returnValueType: OverviewValueType,
): number | null => {
  return (
    monthlySale.overviews.find(
      overview =>
        getTransactionOverviewType(overview) === transactionType &&
        overview.overviewValueType === returnValueType,
    ) ?? { value: null }
  ).value;
};

const getDailySaleQuantity = (
  dailySale: DailyInventoryMovementOverview,
  transactionType: string,
): number | null => {
  switch (transactionType) {
    case TransactionOverviewType.SaleRegular.toString():
      return dailySale.quantities?.regular ?? null;
    case TransactionOverviewType.SalePromo.toString():
      return dailySale.quantities?.promo ?? null;
    case TransactionOverviewType.SaleSellout.toString():
      return dailySale.quantities?.sellout ?? null;
    case TransactionOverviewType.Inbound.toString():
      return dailySale.quantities?.inbound ?? null;
    case TransactionOverviewType.Outbound.toString():
      return dailySale.quantities?.outbound ?? null;
    default: {
      return null;
      // throw new Error(`Not implemented TransactionOverviewType ${transactionType} case`);
    }
  }
};

const getMonthlySaleQuantity = (
  monthlySale: SaleMonthlyOverviewResponse,
  transactionType: string,
): number | null => {
  return getMonthlySaleData(monthlySale, transactionType, OverviewValueType.Quantity);
};

const getMonthlySaleValue = (
  monthlySale: SaleMonthlyOverviewResponse,
  transactionType: string,
): number | null => {
  return getMonthlySaleData(monthlySale, transactionType, OverviewValueType.Value);
};

export type DailyInventoryMovementQuantity = SaleQuantityResponse & {
  inbound: number;
  outbound: number;
};

export type DailyInventoryMovementOverview = SaleDailyOverviewResponse & {
  quantities?: DailyInventoryMovementQuantity | null;
};

export type ParseTransactionsInput = {
  dateToStartCalculatingCumulativeValues: Date;
  lineTypes: TLineType[];
} & (
  | {
      type: 'monthly';
      transactions: SaleMonthlyOverviewResponse[] | ForecastMonthlyOverviewResponse[];
      computeSupply?: undefined;
    }
  | {
      type: 'daily';
      transactions: DailyInventoryMovementOverview[];
      computeSupply?: {
        supplyDate: Date;
        supplyStart: number;
        supplyLine: TLineType;
        supplyValue?: number;
      };
    }
);

export type NonSaleDataByDay = Record<
  string,
  | {
      inbounds: NonSaleOverviewResponse[];
      outbounds: NonSaleOverviewResponse[];
    }
  | undefined
>;

export const mergeDailyInventoryMovements = (
  transactions: SaleDailyOverviewResponse[],
  inbounds: NonSaleDailyOverviewResponse[],
  outbounds: NonSaleDailyOverviewResponse[],
): {
  summedData: DailyInventoryMovementOverview[];
  nonSaleDataByDay: NonSaleDataByDay;
} => {
  const nonSaleDataByDay: NonSaleDataByDay = {};

  const summedData = transactions.map(t => {
    const dayInbounds = inbounds.find(i => i.day === t.day)?.nonSaleOverviews ?? [];
    const dayOutbounds = outbounds.find(i => i.day === t.day)?.nonSaleOverviews ?? [];

    if (dayInbounds.length > 0 || dayOutbounds.length > 0) {
      nonSaleDataByDay[t.day] = {
        inbounds: dayInbounds,
        outbounds: dayOutbounds,
      };
    }

    return {
      ...t,
      quantities: {
        regular: t.quantities?.regular ?? 0,
        promo: t.quantities?.promo ?? 0,
        sellout: t.quantities?.sellout ?? 0,
        inbound: dayInbounds.reduce((p, c) => p + c.quantity, 0),
        outbound: dayOutbounds.reduce((p, c) => p + c.quantity, 0),
      },
    };
  });

  return {
    nonSaleDataByDay,
    summedData,
  };
};

export const parseTransactions = ({
  transactions,
  lineTypes,
  dateToStartCalculatingCumulativeValues,
  computeSupply,
}: ParseTransactionsInput): TParsedTransactions[] => {
  const actualCumulativeValue: TCumulativeLine = {};

  //initialize cumulative values to zero
  lineTypes.forEach((lineType: TLineType) => {
    actualCumulativeValue[lineType.name] = {
      quantity: 0,
      value: 0,
    };
  });

  let actualMonthData: TCumulativeLine = actualCumulativeValue;
  const parsedCumulativeData: TCumulativeLine[] = transactions.map(dailyOrMonthlySale => {
    const newCumulativeLine: TCumulativeLine = {};

    lineTypes.forEach((lineType: TLineType) => {
      newCumulativeLine[lineType.name] = {
        quantity: actualMonthData[lineType.name].quantity,
        value: actualMonthData[lineType.name].value,
      };

      let saleTypeQuantity: number | null = null;
      let saleTypeValue: number | null = null;

      const dateFromSale =
        'monthInterval' in dailyOrMonthlySale
          ? dailyOrMonthlySale.monthInterval.dateTo
          : dailyOrMonthlySale.day;

      if (new Date(dateFromSale) > dateToStartCalculatingCumulativeValues) {
        if ('monthInterval' in dailyOrMonthlySale) {
          saleTypeQuantity = getMonthlySaleQuantity(dailyOrMonthlySale, lineType.name);
          saleTypeValue = getMonthlySaleValue(dailyOrMonthlySale, lineType.name);
        } else {
          saleTypeQuantity = getDailySaleQuantity(dailyOrMonthlySale, lineType.name);
        }

        newCumulativeLine[lineType.name].quantity += saleTypeQuantity ?? 0;
        newCumulativeLine[lineType.name].value += saleTypeValue ?? 0;
      }
    });
    actualMonthData = newCumulativeLine;
    return newCumulativeLine;
  });

  const verticalCumulativeValuesDefault = {
    quantityToThisPriority: 0,
    quantityToThisPriorityCumulative: 0,
    valueToThisPriority: 0,
    valueToThisPriorityCumulative: 0,
  };

  const parsedT = transactions
    .map((dailyOrMonthlySale, index) => {
      const parsedData: TParsedMonthlyTransactionsData = {};
      let verticalCumulativeValues = { ...verticalCumulativeValuesDefault };
      const cumulativeValues = parsedCumulativeData[index];

      let lastLineTypeType: LineTypesTuple[number];
      lineTypes.forEach(lineType => {
        if (lastLineTypeType !== lineType.type) {
          verticalCumulativeValues = { ...verticalCumulativeValuesDefault };
          lastLineTypeType = lineType.type;
        }

        let saleTypeQuantity: number | null;
        let saleTypeValue: number | null = null;

        if ('monthInterval' in dailyOrMonthlySale) {
          saleTypeQuantity = getMonthlySaleQuantity(dailyOrMonthlySale, lineType.name);
          saleTypeValue = getMonthlySaleValue(dailyOrMonthlySale, lineType.name);
        } else {
          saleTypeQuantity = getDailySaleQuantity(dailyOrMonthlySale, lineType.name);
        }

        verticalCumulativeValues.quantityToThisPriority += saleTypeQuantity ?? 0;
        verticalCumulativeValues.quantityToThisPriorityCumulative +=
          cumulativeValues[lineType.name].quantity;

        verticalCumulativeValues.valueToThisPriority += saleTypeValue ?? 0;
        verticalCumulativeValues.valueToThisPriorityCumulative +=
          cumulativeValues[lineType.name].value;

        parsedData[lineType.name] = {
          quantity: saleTypeQuantity ?? null,
          quantityToThisPriority: verticalCumulativeValues.quantityToThisPriority,
          quantityCumulative: cumulativeValues[lineType.name].quantity,
          quantityToThisPriorityCumulative:
            verticalCumulativeValues.quantityToThisPriorityCumulative,
          value: saleTypeValue ?? null,
          valueToThisPriority: verticalCumulativeValues.valueToThisPriority,
          valueCumulative: cumulativeValues[lineType.name].value,
          valueToThisPriorityCumulative: verticalCumulativeValues.valueToThisPriorityCumulative,
          avgValue:
            saleTypeQuantity && saleTypeValue && saleTypeQuantity > 0
              ? saleTypeValue / saleTypeQuantity
              : 0,
        };
      });

      if ('monthInterval' in dailyOrMonthlySale) {
        return {
          month: dailyOrMonthlySale.monthInterval.month,
          year: dailyOrMonthlySale.monthInterval.year,
          dates: {
            stringFrom: dailyOrMonthlySale.monthInterval.dateFrom,
            stringTo: dailyOrMonthlySale.monthInterval.dateTo,
            dateFrom: new Date(dailyOrMonthlySale.monthInterval.dateFrom),
            dateTo: new Date(dailyOrMonthlySale.monthInterval.dateTo),
          },
          data: parsedData,
        };
      } else {
        const d = new Date(dailyOrMonthlySale.day);
        return {
          month: d.getMonth(),
          year: d.getFullYear(),
          dates: {
            stringFrom: dailyOrMonthlySale.day,
            stringTo: dailyOrMonthlySale.day,
            dateFrom: d,
            dateTo: d,
          },
          data: parsedData,
        };
      }
    })
    .sort((a, b) => {
      return b.dates.stringTo.localeCompare(a.dates.stringTo);
    });

  if (computeSupply) {
    let actualSupply = computeSupply.supplyStart;
    let validComputationDate = false;
    const { supplyDate, supplyLine, supplyValue = 1 } = computeSupply;
    parsedT.forEach(pt => {
      if (!validComputationDate) {
        validComputationDate = supplyDate.getTime() >= pt.dates.dateTo.getTime();
      }
      if (validComputationDate) {
        lineTypes.forEach(lt => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- pt.data may be null
          actualSupply += (pt.data?.[lt.name]?.quantity ?? 0) * (lt.supplyMultiplier ?? 0) * -1;
        });
        const value = actualSupply * supplyValue;
        pt.data[supplyLine.name] = {
          quantity: actualSupply,
          quantityToThisPriority: actualSupply,
          quantityCumulative: actualSupply,
          quantityToThisPriorityCumulative: actualSupply,
          value: value,
          valueToThisPriority: value,
          valueToThisPriorityCumulative: value,
          valueCumulative: value,
          avgValue: value,
        };
      }
    });
  }

  return parsedT;
};

/**
 * Take sales used for forecast and add it to sale types.
 * The priority of the sale used for forecast is the max priority of the sale types + 1
 * @param salesUsedForForecast
 * @param saleTypes
 */
export const addSalesUsedForForecastToSaleTypes = (
  salesUsedForForecast: TLineType,
  saleTypes: TLineType[],
): TLineType[] => {
  const maxPriority = Math.max(...saleTypes.map(saleType => saleType.priority));

  salesUsedForForecast.priority = maxPriority + 1;
  return saleTypes.concat(saleUsedForForecast);
};

export function copyLineTypeToParsedMonthlyTransactionsByAnotherLineType(
  parsedMonthlyTransactions: TParsedTransactions[],
  lineTypeToCopy: TLineType,
  copyDataFromThisLineTypeName: string,
): TParsedTransactions[] {
  return parsedMonthlyTransactions.map(transaction => {
    return {
      ...transaction,
      data: {
        ...transaction.data,
        [lineTypeToCopy.name]: transaction.data[copyDataFromThisLineTypeName],
      },
    };
  });
}

/**
 * Sort line types by priority
 * @param left
 * @param right
 */
const lineTypePrioritySorter = (left: TLineType, right: TLineType): number => {
  if (left.priority > right.priority) {
    return 1;
  }
  if (left.priority < right.priority) {
    return -1;
  }
  return 0;
};

/**
 * Get sale line definitions sorted by priority
 */
export const getSaleLineDefinitions = (): TLineType[] => {
  saleTypesDefault.sort(lineTypePrioritySorter);
  return saleTypesDefault;
};

/**
 * Get forecast line definitions sorted by priority
 */
export const getForecastLineDefinitions = (linesToExclude: ForecastName[] = []): TLineType[] => {
  return (
    forecastTypesDefault
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- line name should be ForecastName
      .filter(l => !linesToExclude.includes(l.name as ForecastName))
      .sort(lineTypePrioritySorter)
  );
};

export const getChartPointSizeBasedOnDisplayedDays = (displayedDayDifference: number): number => {
  let finalSize = 3;
  if (displayedDayDifference >= 180) {
    finalSize = 0;
  } else if (displayedDayDifference >= 90) {
    finalSize = 2;
  }
  return finalSize;
};

export const adjustPointSizeInLines = (lineTypes: TLineType[], pointRadius: number): TLineType[] =>
  lineTypes.map(lt =>
    'lineInfo' in lt ? { ...lt, lineInfo: { ...lt.lineInfo, pointRadius } } : lt,
  );

export const setupChartLines = (
  lineTypes: TLineType[],
  parsedTransactions: TParsedTransactions[] | TParsedDailyTransactions[],
  cumulativeMode: boolean,
  dateToStartCalculatingCumulativeValues: Date,
  units: 'Quantity' | 'Value' = 'Value',
): TChartLine => {
  const chartLines: TChartLine = {};
  lineTypes.forEach(lineType => {
    chartLines[lineType.name] = parsedTransactions.map(pmt => {
      const lineTypeData = pmt.data[lineType.name];
      const dateForAddingToCumulative = 'dateTo' in pmt.dates ? pmt.dates.dateTo : pmt.dates.date;

      if (
        dateForAddingToCumulative > dateToStartCalculatingCumulativeValues &&
        lineType.name === 'SaleUsedForForecast'
      ) {
        return null;
      }

      if (
        lineTypeData &&
        units === 'Quantity' &&
        lineTypeData.quantity !== null &&
        lineTypeData.quantity >= 0
      ) {
        if (cumulativeMode && dateForAddingToCumulative > dateToStartCalculatingCumulativeValues) {
          if (lineType.type === 'Sale') {
            return lineTypeData.quantityToThisPriorityCumulative ?? 0;
          } else {
            return lineTypeData.quantityCumulative ?? 0;
          }
        } else {
          return lineType.type === 'Sale'
            ? lineTypeData.quantityToThisPriority
            : lineTypeData.quantity;
        }
      } else if (
        lineTypeData &&
        units === 'Value' &&
        lineTypeData.value !== null &&
        lineTypeData.value >= 0
      ) {
        if (cumulativeMode && dateForAddingToCumulative > dateToStartCalculatingCumulativeValues) {
          if (lineType.type === 'Sale') {
            return lineTypeData.valueToThisPriorityCumulative ?? 0;
          } else {
            return lineTypeData.valueCumulative ?? 0;
          }
        } else {
          return lineType.type === 'Sale' ? lineTypeData.valueToThisPriority : lineTypeData.value;
        }
      } else {
        return null;
      }
    });
  });

  return chartLines;
};

export const hideNullLineTypes = (
  lineTypes: TLineType[],
  parsedMonthlyTransactions: TParsedTransactions[],
): TLineType[] => {
  const lineTypesCopy = cloneDeep(lineTypes);

  lineTypesCopy.forEach((lineType: TLineType, index) => {
    const found = parsedMonthlyTransactions.find(pmt => {
      const pmtData = pmt.data[lineType.name];
      return lineType.name === 'SaleUsedForForecast' || (pmtData?.quantity && pmtData.quantity > 0);
    });

    if (!found) {
      if ('lineInfo' in lineTypesCopy[index]) {
        lineTypesCopy[index].lineInfo.hidden = true;
      } else if ('barInfo' in lineTypesCopy[index]) {
        lineTypesCopy[index].barInfo.hidden = true;
      }
    }
  });

  return lineTypesCopy;
};

export const changeFillInLineTypesBasedOnHiddenLines = (lineTypes: TLineType[]): TLineType[] => {
  const reversedLineTypes = [...lineTypes].reverse();

  lineTypes.forEach((lineType: TLineType, index) => {
    if (!('lineInfo' in lineType) || !('lineInfo' in lineTypes[index])) return;
    const fill = lineType.lineInfo.fill;

    if (fill !== true && fill !== false) {
      let numericFill = 0;
      if (typeof fill === 'number') {
        numericFill = fill;
      } else if (typeof fill === 'string') {
        numericFill = parseInt(fill);
      }

      if (numericFill !== 0) {
        const priority = lineType.priority;
        let positive = 1;
        let cutLineTypes: TLineType[];
        if (numericFill < 0) {
          positive = -1;
          cutLineTypes = reversedLineTypes.filter(lt => lt.priority < priority);
        } else {
          cutLineTypes = lineTypes.filter(lt => lt.priority > priority);
        }

        if (Math.abs(numericFill) > cutLineTypes.length && positive < 0) {
          lineTypes[index].lineInfo.fill = true;
        } else {
          let actuallyFilledLines = 0;
          let totalFilledLines = 0;
          cutLineTypes.forEach((lt: TLineType, index: number) => {
            if (
              actuallyFilledLines === Math.abs(numericFill) ||
              (Math.abs(numericFill) < index + 1 && actuallyFilledLines > 0)
            )
              return;
            totalFilledLines++;
            if ('lineInfo' in lt && lt.lineInfo.hidden) {
              return;
            }
            actuallyFilledLines++;
            if (actuallyFilledLines === Math.abs(numericFill)) {
              return;
            }
          });
          lineTypes[index].lineInfo.fill = (totalFilledLines * positive).toString();
        }
      }
    }
  });

  return lineTypes;
};

export const createDatasetsFromLineTypesAndGraphLines = (
  lineTypes: TLineType[],
  chartLines: TChartLine,
): (ChartDataset<'line'> | ChartDataset<'bar'>)[] => {
  return lineTypes.map(lineType => {
    const data: (number | null)[] = chartLines[lineType.name];

    return {
      label: lineType.label,
      // type: lineType.chartDatasetType,
      ...('lineInfo' in lineType ? lineType.lineInfo : lineType.barInfo),
      data: data,
      metaExtensions: {
        displayLegend: lineType.displayLegend,
      },
    };
  });
};

export const createChartDataFromDatasetsAndParsedMonthlyTransactions = (
  datasets: ChartDataset<'line'>[],
  parsedMonthlyTransactions: TParsedTransactions[],
): ChartData<'line'> => {
  const labels = parsedMonthlyTransactions.map(m => m.dates.dateTo);
  return {
    labels,
    datasets,
  };
};

export const extractOnlySalesFromParsedData = (
  parsedMonthlyTransactions: TParsedTransactions[],
): TParsedTransactions[] => {
  return parsedMonthlyTransactions
    .map(transaction => {
      const newData: TParsedMonthlyTransactionsData = {};
      Object.keys(transaction.data).forEach(saleType => {
        if (saleType.includes('Sale') && saleType !== saleUsedForForecast.name) {
          newData[saleType] = transaction.data[saleType];
        }
      });

      return {
        ...transaction,
        data: newData,
      };
    })
    .filter(transaction => transaction.data.SaleRegular?.quantity !== null);
};

/**
 * Adds VAT to amount if vat is not NaN
 * @param amount
 * @param vat
 */
export const addVat = (amount: number, vat: number): number =>
  !isNaN(vat) ? Math.floor((amount + (amount / 100) * vat) * 100) / 100 : amount;
