import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetCategoryOverviewForDrilldownQuery } from '../apiDrillDown';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { CategoryDrillDownData, SkuTypeForDrilldown } from '../drillDownLib';
import { getCategoryDrillDownColumns } from './categoryDrillDownColumns';
import { useCurrency } from '../../../../hooks/useCurrency';
import { setSubpage } from '../../../../routes/routerSlice';
import { ConfigurationSubpage } from '../../Configuration';
import { useNavigate, useParams } from 'react-router';
import { setSelectedCategory } from '../../../../components/global/CategoryTree/categoryTreeSlice';
import { COLORS, computeRemSize, ScrolledTable } from '@ydistri/ds';
import ScrolledTableWrapper from '../../../../components/domain/SKUTable/ScrolledTableWrapper';
import { styled } from 'styled-components';
import { useAppDispatch } from '../../../../store';

const CategoryDrillDownTable = styled(ScrolledTable<CategoryDrillDownData>)`
  .ant-table-small .ant-table-tbody > tr:not(:first-child) > td {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .highlight-selected {
    background-color: ${props => props.theme.item.hovered.backgroundColor};

    & td:first-child {
      border-left: ${computeRemSize(3)} solid ${COLORS.PRIMARY};
    }
  }

  .add-left-border td:first-child {
    border-left: ${computeRemSize(3)} solid transparent;
  }

  td.ant-table-cell:not(.ant-table-cell-scrollbar),
  th.ant-table-cell {
    padding: 0.25rem !important;
    padding-right: 0.75rem !important;
  }
`;

interface CategoryDrillDownProps {
  selectedSkuType?: SkuTypeForDrilldown;
  takeSupplyAndRevenueFromAttributes?: boolean;
  omitPotentialColumns?: boolean;
}

const CategoryDrillDown: React.FC<CategoryDrillDownProps> = ({
  selectedSkuType = 'total',
  takeSupplyAndRevenueFromAttributes = false,
  omitPotentialColumns = false,
}) => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency();
  const navigate = useNavigate();
  const [openedCategories, setOpenedCategories] = useState<number[]>([1]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we do not want to sort on every render
  const openedCategoryKey = useMemo(
    () => openedCategories.sort((a, b) => a - b).join('-'),
    [openedCategories],
  );

  const { data: drillDownData, isFetching } = useGetCategoryOverviewForDrilldownQuery({
    templateOrCalculation,
    selectedSkuType,
    openedCategoryKey,
    takeSupplyAndRevenueFromAttributes,
  });

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.DRILLDOWN));
  });

  useEffect(() => {
    if (slug) {
      setOpenedCategories(slug.split('-').map(parseInt));
      dispatch(setSelectedCategory(slug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- slug is not a dependency
  }, [dispatch]);

  const columns = useMemo(
    () => getCategoryDrillDownColumns(templateOrCalculation.type, currencyId, omitPotentialColumns),
    [templateOrCalculation.type, currencyId, omitPotentialColumns],
  );

  const rowKeySelector = useCallback((c: CategoryDrillDownData) => c.categoryId, []);

  const rowClassProvider = useCallback((record: CategoryDrillDownData) => {
    let classes = '';

    if (record.categoryLevel <= 1) {
      classes += ' bold';
    } else {
      classes += ` bg-orange-${record.categoryLevel}`;
    }

    return classes;
  }, []);

  const tableOnRow = useCallback(
    (record: CategoryDrillDownData) => ({
      onClick: () => {
        setOpenedCategories(prev => {
          if (record.categoryId === 1) return prev;
          let result: number[];
          if (prev.includes(record.categoryId)) {
            result = prev.filter(id => id !== record.categoryId);
          } else {
            result = [...prev, record.categoryId];
          }
          return result.sort((a, b) => a - b);
        });
        navigate(`../${ConfigurationSubpage.DRILLDOWN}/${record.categorySlug}`);
      },
    }),
    [navigate],
  );

  return (
    <ScrolledTableWrapper offset={80}>
      <CategoryDrillDownTable
        size="small"
        loading={isFetching}
        showHeader={true}
        columns={columns}
        dataSource={drillDownData}
        onRow={tableOnRow}
        id="drill-down-categories"
        rowKey={rowKeySelector}
        rowClassName={rowClassProvider}
      />
    </ScrolledTableWrapper>
  );
};

export default CategoryDrillDown;
