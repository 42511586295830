import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../../../apiCalculationDetail';
import { useAppDispatch } from '../../../../../store';
import React, { useCallback, useEffect } from 'react';
import { setSubpage } from '../../../../../routes/routerSlice';
import { CalculationDetailSubpages } from '../../../../Calculations/calculationsLib';
import {
  selectSKURedistribution,
  setSelectedRowKeys,
} from '../../../../Calculations/calculationsSlice';
import { CalculationMode } from '@ydistri/api-sdk';
import { Helmet } from 'react-helmet-async';
import { MainContent, MainContentWrapper } from '@ydistri/ds';
import CategoryTree from '../../../../../components/global/CategoryTree/CategoryTree';
import CalculationRedistributionSubpageContent from './components/CalculationRedistributionSubpageContent';
import {
  SUBCALCULATION_SOURCE_SELECTOR_TABLE_ID,
  SUBCALCULATION_TARGET_SELECTOR_TABLE_ID,
} from './components/SubordinateCalculationSelector/subordinateCalculationSelectorLib';

const CalculationRedistributionListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const calculationData = useTemplateOrCalculation();
  const { data: calculation, isError, isFetching } = useGetCalculationQuery(calculationData.id);

  useEffect(() => {
    dispatch(setSubpage(CalculationDetailSubpages.REDISTRIBUTION));
  }, [dispatch]);

  useEffect(() => {
    if (isFetching) return;
    if (calculation?.mode === CalculationMode.Merged) {
      dispatch(
        setSelectedRowKeys({
          tableId: SUBCALCULATION_SOURCE_SELECTOR_TABLE_ID,
          data: [calculation.id],
        }),
      );
      dispatch(
        setSelectedRowKeys({
          tableId: SUBCALCULATION_TARGET_SELECTOR_TABLE_ID,
          data: [calculation.id],
        }),
      );
    }
  }, [calculation?.id, calculation?.mode, dispatch, isFetching]);

  //we don't want to keep selected pairing "p" in the URL on category change - since its the only one URL param we have on this screen for now, we can just erase the whole search params
  const searchParamModifier = useCallback(() => '', []);

  const onCategoryChange = useCallback(() => {
    dispatch(selectSKURedistribution(undefined));
  }, [dispatch]);

  if (!isFetching && !isError && calculation) {
    return (
      <>
        <Helmet title={`Calculation ${calculation.id} - Redistribution`} />
        <MainContentWrapper>
          <CategoryTree
            searchParamModifier={searchParamModifier}
            onCategoryChange={onCategoryChange}
          />
          <MainContent>
            <CalculationRedistributionSubpageContent calculationId={calculation.id} />
          </MainContent>
        </MainContentWrapper>
      </>
    );
  } else {
    return null;
  }
};

export default CalculationRedistributionListing;
