import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TemplateSelect } from './TemplateSelect';
import { CreateTemplatePayload, useCreateTemplateMutation } from './apiTemplate';
import { useNavigate, useParams } from 'react-router';
import { generatePartialPath } from '../../routes/routerSlice';
import { ROUTES } from '../../components/menu/menuLeftItemTemplate';
import { ConfigurationSubpage } from './Configuration';

interface CreateTemplateModalProps {
  open: boolean;
  templateId?: number;
  onCancel: () => void;
}

interface FormFields {
  title: string;
  description: string;
}

const titleInputRules = [{ required: true, message: 'Please enter a title for your template' }];
const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({
  open,
  templateId,
  onCancel,
}) => {
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const { projectShortName } = useParams<{ projectShortName: string }>();
  const [form] = Form.useForm<FormFields>();
  const [createTemplate, { isLoading }] = useCreateTemplateMutation();
  const [basedOnTemplateId, setBasedOnTemplateId] = useState<number | undefined>(templateId);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      if (basedOnTemplateId !== undefined) {
        const payload: CreateTemplatePayload = {
          basedOnTemplateId,
          data: values,
        };

        createTemplate(payload).then(x => {
          form.resetFields();

          if ('data' in x) {
            navigate(
              generatePartialPath(
                ROUTES.configurationChildRoute,
                { projectShortName, slug: '1', templateId: x.data?.id.toString() },
                ConfigurationSubpage.CATEGORIES,
              ),
            );
          }

          onCancel();
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- navigate is not included in deps
  }, [form, basedOnTemplateId, createTemplate, onCancel, projectShortName]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const onChangeTemplateHandler = useCallback((value: number) => {
    setBasedOnTemplateId(value);
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Modal
      data-type="modal"
      title="Create template"
      open={open}
      width={1000}
      okText="Create"
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Form
        data-type="create-template-form"
        form={form}
        labelCol={formLayout.labelCol}
        wrapperCol={formLayout.wrapperCol}
        name="create_template_modal"
        layout="horizontal"
      >
        <Form.Item name="title" label="Title" rules={titleInputRules}>
          <Input ref={inputRef} onKeyDown={handleKeyDown} />
        </Form.Item>
        <Form.Item label="Based on template">
          <TemplateSelect onChange={onChangeTemplateHandler} templateId={basedOnTemplateId} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTemplateModal;
