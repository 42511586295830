import React, { useCallback } from 'react';
import { ActionsBar, Button, Row, SearchBox } from '@ydistri/ds';
import { isDigitsOnly } from '@ydistri/utils';

interface SearchPanelProps {
  value?: string;
  onSearch: (query: string) => void;
  onSkuSearch: (skuId: number) => void;
}

const SearchPanel: React.FC<SearchPanelProps> = ({ value, onSearch, onSkuSearch }) => {
  const skuSearchHandler = useCallback(() => {
    if (!value) {
      return;
    }

    const skuId = parseInt(value);
    onSkuSearch(skuId);
  }, [onSkuSearch, value]);

  return (
    <Row $flexGrow={0} $gap="1rem">
      <SearchBox
        onSearch={onSearch}
        placeholder="Search products"
        value={value}
        flexGrow={1}
        id="product-search-box"
      />
      {value && isDigitsOnly(value) && (
        <ActionsBar>
          <Button onClick={skuSearchHandler} data-testid="SKUSearchButton">
            SKU
          </Button>
        </ActionsBar>
      )}
    </Row>
  );
};

export default SearchPanel;
