import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState, useAppDispatch } from '../../store';
import { useGetCalculationQuery } from '../CalculationDetail/apiCalculationDetail';
import {
  PatchCalculationPayload,
  usePatchCalculationMutation,
} from '../Calculations/apiCalculations';
import { CalculationType } from '@ydistri/api-sdk';
import { GenericConfirmationModal } from '@ydistri/ds';
import { Alert } from 'antd';

const modalId = AvailableModals.UNSET_PRODUCTION;

const UnsetProductionModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const modalData = useSelector((state: ReduxState) => state.modal.modalData[modalId]);
  const calculationId = modalData?.calculationId ?? 0;

  const { data: calculationData } = useGetCalculationQuery(calculationId);
  const [editCalculation, { isLoading }] = usePatchCalculationMutation();

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const okHandler = useCallback(() => {
    const payload: PatchCalculationPayload = {
      calculationId: calculationId,
      data: {
        calculationType: CalculationType.Simulation,
        finishExecutionUntil: undefined,
      },
    };
    editCalculation(payload).then(() => {
      handleClose();
    });
  }, [calculationId, editCalculation, handleClose]);

  const message = calculationData?.isPap ? (
    <Alert
      message="This calculation is exported to Y'pick, so Production flag can not be removed."
      type="warning"
      showIcon
    />
  ) : (
    `Calculation '${calculationData?.title}' will not be marked as "Production" anymore. Continue? `
  );

  if (!open) return null;
  return (
    <GenericConfirmationModal
      title="Remove production"
      message={message}
      onConfirmed={okHandler}
      onCanceled={handleClose}
      running={isLoading}
      okButtonProps={{
        disabled: calculationData?.isPap,
      }}
    />
  );
};

export default UnsetProductionModal;
