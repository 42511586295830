import React, { useCallback } from 'react';
import { CategorySelectionType, setOpenedCategory } from './categoryTreeSlice';
import { PaddingSpan } from './PaddingSpan';
import {
  ExceptionIcon,
  IconWrapper,
  OpenIcon,
  TreeLeafTitle,
  TreeLeafWrapper,
} from './categoryTreeStyledComponents';
import { BsSliders } from 'react-icons/bs';
import { RxSlider } from 'react-icons/rx';
import { IoChevronDown } from 'react-icons/io5';
import { useLocation } from 'react-router';
import { useAppDispatch } from '../../../store';

interface TreeLeafProps {
  slug: string;
  title: string;
  selectionType: CategorySelectionType;
  opened: boolean;
  hasException: boolean;
  subcategoryHasException: boolean;
  level: number;
  lastLevel: boolean;
  onCategoryChange?: (event: React.MouseEvent, categorySlug: string) => void;
  searchParamModifier?: (value: string) => string;
}

const TreeLeaf: React.FC<TreeLeafProps> = ({
  slug,
  title,
  selectionType,
  opened,
  hasException,
  subcategoryHasException,
  level,
  lastLevel,
  onCategoryChange,
  searchParamModifier = (value: string) => value,
}) => {
  const dispatch = useAppDispatch();
  const canBeOpened = !selectionType || selectionType === 'exact' || !opened;
  const location = useLocation();

  const onOpenHandler = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      if (canBeOpened) {
        dispatch(setOpenedCategory({ categorySlug: slug, opened: !opened }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- slug is not a dependency (but why?)
    [opened, canBeOpened],
  );

  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      onCategoryChange?.(event, slug);
    },
    [onCategoryChange, slug],
  );

  return (
    <TreeLeafWrapper
      $level={level}
      $selectionType={selectionType}
      $isOpened={opened}
      to={`../${slug}${searchParamModifier(location.search)}`}
      relative="path"
      onClick={clickHandler}
    >
      {slug.split('-').map((x, i) => (i > 1 ? <PaddingSpan key={x} /> : null))}
      <TreeLeafTitle>{title}</TreeLeafTitle>
      {level > 0 && (
        <IconWrapper>
          {(hasException || subcategoryHasException) && (
            <ExceptionIcon $hasException={hasException}>
              {hasException && subcategoryHasException ? <BsSliders /> : <RxSlider />}
            </ExceptionIcon>
          )}
          {!lastLevel && (
            <OpenIcon $isOpened={opened} $canBeOpened={canBeOpened} onClick={onOpenHandler}>
              <IoChevronDown />
            </OpenIcon>
          )}
        </IconWrapper>
      )}
    </TreeLeafWrapper>
  );
};

export default React.memo(TreeLeaf);
