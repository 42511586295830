import { styled } from 'styled-components';
import { COLORS } from '@ydistri/ds';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import React, { useMemo } from 'react';
import { RenderFunction } from 'antd/es/_util/getRenderPropValue';
import { Popover } from 'antd';

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: ${COLORS.GREY};
  width: 1.25rem;
  min-width: 1.25rem;
`;

interface InfoIconTooltipProps {
  htmlContent?: string;
  content?: React.ReactNode | RenderFunction;
  icon?: React.ReactNode;
}

/**
 * Standardized icon for displaying a tooltip with information
 * @param htmlContent html content of the tooltip
 * @param content content of the tooltip
 * @param icon custom icon component - defaults to AiOutlineInfoCircle
 * @constructor
 */
export const InfoIconTooltip: React.FC<InfoIconTooltipProps> = ({ htmlContent, content, icon }) => {
  const dangerousContent = useMemo(
    // eslint-disable-next-line @typescript-eslint/naming-convention -- mandated by react
    () => (htmlContent ? { __html: htmlContent } : undefined),
    [htmlContent],
  );

  const contentHtml = useMemo(
    () => (dangerousContent ? <div dangerouslySetInnerHTML={dangerousContent} /> : undefined),
    [dangerousContent],
  );

  return (
    <InfoIconWrapper>
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- we want to check like this */}
      {(contentHtml || content) && (
        <Popover
          placement="topLeft"
          showArrow={false}
          content={contentHtml ?? content}
          trigger="hover"
        >
          {icon ?? <AiOutlineInfoCircle />}
        </Popover>
      )}
    </InfoIconWrapper>
  );
};
