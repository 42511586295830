import {
  CompareType,
  IdentificationType,
  ProductListItemComparisonInformationResponse,
  ProductListItemsRequest,
  ProductListResponse,
  ProductResponse,
} from '@ydistri/api-sdk';
import { ProductListInputData, ProductListRow, ProductListValidatedItem } from './productListTypes';
import {
  AdministrationItemAction,
  AdministrationItemContentCompared,
  AdministrationItemListHandlingMethod,
  AdministrationItemOrigin,
  AdministrationItemParser,
  AdministrationItemParserFunction,
  AdministrationItemRow,
} from '../common/administrationItemsTypes';
import { selectParser } from '../common/administrationItemsLib';
import { ProductListsCollection } from '../../../swagger/collections';

/**
 * Create a title suitable for a section in the Product List Administration page
 * @param productList
 * @returns string with name, customer product list id and archived status
 */
export const getSectionTitle = (productList: ProductListResponse): string => {
  return `${productList.name ?? '-no title-'} [${productList.customerProductListId ?? '-'}]${
    productList.isArchived ? ' - [archived]' : ''
  }`;
};

const getProductListItems = (cells: NodeListOf<HTMLElement>): Partial<ProductListInputData>[] => {
  const result: Partial<ProductListInputData>[] = [];

  cells.forEach(cell => {
    const resultItem: Partial<ProductListInputData> = {};
    let content = cell.innerText;
    if (!content) {
      //fallback when innerText is not supported
      content = cell.firstChild?.textContent ?? '';
    }
    resultItem.productId = content.trim();
    result.push(resultItem);
  });

  return result;
};

/**
 * Parse product identification out of an HTML table.
 * The table may contain more than one row and more than one column at the same time. Each cell is treated
 * as one product identification.
 * @param content
 * @param parser
 */
export const parseTabularDataFromHtml: AdministrationItemParserFunction<ProductListRow> = (
  content: string,
  parser?: DOMParser,
): ProductListRow[] => {
  if (!parser) {
    parser = new DOMParser();
  }

  const htmlDoc = parser.parseFromString(content, 'text/html');
  const rows = htmlDoc.querySelectorAll('table tr');

  const result: ProductListRow[] = [];

  let rowNumber = 0;
  rows.forEach(row => {
    const cells = row.querySelectorAll('td');

    const rowData: Partial<ProductListInputData>[] = getProductListItems(cells);
    rowData.forEach(item => {
      rowNumber++;
      const resultRow: ProductListRow = {
        rowNumber: rowNumber,
        inputData: item,
      };

      result.push(resultRow);
    });
  });

  return result;
};

/**
 * Parse product identification out of a plain.
 * Products can be either one per line or separated by a comma, semicolon or tab or combined -
 * there can be more products on one line and many lines in the pasted data.
 * @param content plain text containing product identification
 */
export const parseTabularDataFromPlainText: AdministrationItemParserFunction<ProductListRow> = (
  content: string,
): ProductListRow[] => {
  const result: ProductListRow[] = [];
  const rows = content.split(/\n/).filter(tmpRow => tmpRow.length > 0);

  let rowNumber = 0;

  rows.forEach((row: string) => {
    const cells = row.split(/[(,|;)]+|\t/);
    cells.forEach(cell => {
      const cellTrimmed = cell.trim();
      if (cellTrimmed.length > 0) {
        rowNumber++;
        result.push({
          rowNumber: rowNumber,
          inputData: {
            productId: cellTrimmed,
          },
        });
      }
    });
  });

  return result;
};

const productListParsers: AdministrationItemParser<ProductListRow>[] = [
  {
    contentType: 'text/html',
    parse: parseTabularDataFromHtml,
  },
  {
    contentType: 'text/plain',
    parse: parseTabularDataFromPlainText,
  },
];

export const getParserForContentType = (
  contentType: string,
): AdministrationItemParser<ProductListRow> | undefined => {
  return productListParsers.find(parser => parser.contentType === contentType);
};

/**
 * Select the parser to use based on the content type.
 * If the data contains html data, then html parser will be used first as an HTML table
 * is more likely to be pasted than a plain text table.
 * @param data Pasted data
 */
export const selectProductListParser = (
  data: DataTransfer,
): AdministrationItemParser<AdministrationItemRow<ProductListInputData>> | null => {
  return selectParser(data, productListParsers);
};

/**
 * Find a product in the list based on the identification type and the identification
 * @param productIdentification Identification of the product
 * @param productIdentificationType Type of identification used to identify the product (either a code or customer id)
 * @param products List of products to search in
 */
const findProduct = (
  productIdentification: string,
  productIdentificationType: IdentificationType,
  products: ProductResponse[],
): ProductResponse | undefined => {
  return products.find(product => {
    if (productIdentificationType === IdentificationType.Code) {
      return product.code === productIdentification;
    } else {
      return product.customerId === productIdentification;
    }
  });
};

/**
 * Tests if the product is in the list of unidentified items
 * @param productId
 * @param unidentifiedItems
 * @return true if the product is in the list and was not in fact identified
 */
const wasNotIdentified = (productId: string, unidentifiedItems: string[]): boolean => {
  return unidentifiedItems.includes(productId);
};

/**
 * Create validated item out of parsed item.
 * Validated item contains the parsed item data, the compare type and the actions that can be performed on it
 * The data attribute is replaced with a Product object if the product was identified.
 * @param parsedItem the parsed item
 * @param unidentifiedItems list of unidentified items retrieved from the backend
 * @param productIdentificationTypeId the type of identification used to identify the product (either a code or customer id)
 * @param products list of products retrieved from the backend as part of the compare operation
 * @param currentProducts list of products currently in the list
 */
const createValidatedItem = (
  parsedItem: ProductListRow,
  unidentifiedItems: string[],
  productIdentificationTypeId: IdentificationType,
  products: ProductResponse[],
  currentProducts: ProductResponse[],
): ProductListValidatedItem | undefined => {
  if (!parsedItem.inputData.productId) {
    return;
  }

  //the data attribute is added later
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- the object is finalized down a few lines
  const validatedItem = {
    ...parsedItem,
    errors: parsedItem.errors ? Array.from(parsedItem.errors) : [],
    actions: {
      [AdministrationItemListHandlingMethod.ADD]: AdministrationItemAction.NONE,
      [AdministrationItemListHandlingMethod.REPLACE]: AdministrationItemAction.NONE,
    },
    origin: AdministrationItemOrigin.USER,
  } as ProductListValidatedItem;

  //if the product was not identified, we report it as error and return
  if (wasNotIdentified(parsedItem.inputData.productId, unidentifiedItems)) {
    validatedItem.isError = true;
    if (!validatedItem.errors) {
      validatedItem.errors = [];
    }
    validatedItem.errors.push(`Product ${parsedItem.inputData.productId} was not found`);
    return validatedItem;
  }

  //if the product was identified, we check its CompareType
  const product = findProduct(
    parsedItem.inputData.productId,
    productIdentificationTypeId,
    products,
  );
  if (product) {
    validatedItem.data = product;
    validatedItem.compareType = product.compareType ?? undefined;

    switch (validatedItem.compareType) {
      case CompareType.Missing:
        validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
          AdministrationItemAction.INSERT;
        validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
          AdministrationItemAction.INSERT;
        break;
      case CompareType.Contains:
        validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
          AdministrationItemAction.NONE;
        validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
          AdministrationItemAction.INSERT;
        break;
      case CompareType.Updated:
        validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
          AdministrationItemAction.UPDATE;
        validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
          AdministrationItemAction.UPDATE;
        break;
      case undefined:
        break;
    }
  } else {
    //we asked for a product which is already in the list, so we need to look it up in the current products
    const currentProduct = findProduct(
      parsedItem.inputData.productId,
      productIdentificationTypeId,
      currentProducts,
    );
    if (currentProduct) {
      validatedItem.data = currentProduct;
      validatedItem.compareType = CompareType.Contains;
      validatedItem.actions[AdministrationItemListHandlingMethod.ADD] =
        AdministrationItemAction.NONE;
      validatedItem.actions[AdministrationItemListHandlingMethod.REPLACE] =
        AdministrationItemAction.NONE;
    }
  }

  return validatedItem;
};

const sortByRowNumber = (items: ProductListValidatedItem[]) => {
  items.sort((left, right) => {
    if (left.rowNumber > right.rowNumber) {
      return 1;
    } else {
      return -1;
    }
  });
};

/**
 * Items that are in the list but were not included in the pasted content are
 * evaluated and marked as removed when mode is Replace
 * @param containedItems
 * @param lastRowNumber the last row number of the pasted content
 */
const createValidatedItemsFromContainedItems = (
  containedItems: ProductResponse[],
  lastRowNumber: number,
) => {
  let rowNumber = lastRowNumber + 1;
  const result: ProductListValidatedItem[] = [];

  containedItems.forEach(item => {
    const validatedItem: ProductListValidatedItem = {
      inputData: {},
      rowNumber: rowNumber,
      data: item,
      compareType: item.compareType ?? undefined,
      origin: AdministrationItemOrigin.BACKEND,
      actions: {
        [AdministrationItemListHandlingMethod.ADD]: AdministrationItemAction.NONE,
        [AdministrationItemListHandlingMethod.REPLACE]: AdministrationItemAction.REMOVE,
      },
    };

    result.push(validatedItem);
    rowNumber++;
  });

  return result;
};

/**
 * Assign an action for ADD and REPLACE modes to each of the items in the pasted content or item in the list and mark
 * pasted items as errors if they are not valid products.
 *
 * We have a response from the server with the comparison information for the items in the list and
 * the items in the pasted content. We need to match the items in the pasted content with the items
 * in the comparison response.
 * Some items in the pasted content may not be in the comparison response, because they are not
 * valid products or because they are both in the list and in the pasted content.
 */
export const parseComparisonResult = (
  result: ProductListItemComparisonInformationResponse,
  parsedProductListItems: ProductListRow[],
  currentItems: ProductResponse[],
): AdministrationItemContentCompared<ProductListInputData, ProductResponse> => {
  const itemsValidated: ProductListValidatedItem[] = [];

  //first process pasted items
  if (result.productIdentificationTypeId) {
    for (const parsedItem of parsedProductListItems) {
      const validatedItem = createValidatedItem(
        parsedItem,
        result.unidentifiedItems,
        result.productIdentificationTypeId,
        result.products,
        currentItems,
      );

      if (validatedItem) {
        itemsValidated.push(validatedItem);
      }
    }
  }

  //now process items currently in the list that are not in the pasted content
  //(backend returns them with compare type 'Contains')
  const currentItemsNotInPastedContent = result.products.filter(
    item => item.compareType === CompareType.Contains,
  );
  const lastRowNumber = itemsValidated[itemsValidated.length - 1].rowNumber;
  const itemsNotInPastedContentValidated = createValidatedItemsFromContainedItems(
    currentItemsNotInPastedContent,
    lastRowNumber,
  );

  const resultItems = [...itemsValidated, ...itemsNotInPastedContentValidated];
  sortByRowNumber(resultItems);

  return {
    result,
    items: resultItems,
  };
};

/**
 * Create a string that can be pasted into the ProductListEditor component.
 * Used to construct such a string from the items that are marked as errors,
 * so the user can copy it back to Excel for example.
 * @param data
 * @param productIdentificationTypeId
 */
export const getParseableString = (
  data: ProductListValidatedItem[],
  productIdentificationTypeId: IdentificationType,
): string => {
  const translated = data.map(item => {
    if (item.isError) {
      return item.inputData.productId ?? '';
    } else {
      if (productIdentificationTypeId === IdentificationType.Code) {
        return item.data.code;
      } else {
        return item.data.customerId;
      }
    }
  });

  return translated.join('\n');
};

export const getParseableStringFromPastedData = (
  data: AdministrationItemRow<ProductListInputData>[],
): string => {
  const translated = data.map(row => {
    const rowData = row.inputData;
    return rowData.productId ?? '';
  });
  return translated.join('\n');
};

export const filterValidItems = <T extends ProductListValidatedItem | ProductListRow>(
  items: T[],
): T[] => items.filter(item => !item.isError);

export const createProductListItemsPayload = (
  data: ProductListValidatedItem[] | undefined,
  productIdentificationTypeId: IdentificationType | undefined,
): ProductListItemsRequest | undefined => {
  if (!data || !productIdentificationTypeId) {
    return undefined;
  }

  const payload = filterValidItems(data).filter(
    item => item.origin === AdministrationItemOrigin.USER,
  );

  if (payload.length > 0) {
    const data: string[] = payload
      .map(item => {
        if (productIdentificationTypeId === IdentificationType.Code) {
          return item.data.code;
        } else {
          return item.data.customerId ?? '';
        }
      })
      .filter(item => item.length > 0);

    return {
      productIdentificationTypeId,
      productIdentifications: data,
    };
  }
};

export const validateProductListContent = async (
  selectedProductList: ProductListResponse,
  parsedProductListItems: ProductListRow[],
  productIdentificationType: IdentificationType,
): Promise<AdministrationItemContentCompared<ProductListInputData, ProductResponse>> => {
  const productIds = parsedProductListItems
    .filter(item => !item.isError)
    .map(item => item.inputData.productId ?? '');

  const payloadIdentification: ProductListItemsRequest = {
    productIdentificationTypeId: productIdentificationType,
    productIdentifications: productIds,
  };

  const responses = await Promise.all([
    ProductListsCollection.productsCompareCreate(
      selectedProductList.productListId,
      payloadIdentification,
    ),
    ProductListsCollection.getProducts(selectedProductList.productListId),
  ]);
  const itemsCompared = responses[0].data.data;
  const currentItems = responses[1].data.data;

  return parseComparisonResult(itemsCompared, parsedProductListItems, currentItems);
};
