import React, { useCallback } from 'react';
import { IdentificationType } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { setProductItemsContent } from '../../productListAdministrationSlice';
import { getParseableStringFromPastedData, selectProductListParser } from '../../productListsLib';
import ListItemsTextArea from '../../../common/edit/ListItemsTextArea';
import { ProductListInputData } from '../../productListTypes';
import { AdministrationItemRow } from '../../../common/administrationItemsTypes';
import { ReduxState, useAppDispatch } from '../../../../../store';

interface TargetListItemsTextAreaProps {
  id?: string;
  label?: string;
}

const ProductListItemsTextArea: React.FC<TargetListItemsTextAreaProps> = ({ label, id }) => {
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.productListsAdministration.productIdentificationType,
  );
  const productItemsContent = useSelector(
    (state: ReduxState) => state.productListsAdministration.productItemsContent,
  );

  const dispatch = useAppDispatch();

  const itemsPlaceholder = `Paste product ${
    productIdentificationType === IdentificationType.Code ? 'codes' : 'IDs'
  } from Excel (or separated by semicolons)`;

  /**
   * Saves the content of the text area to state
   * Called on text area's change event
   */
  const productListItemsHandler = useCallback(
    (newValue: string) => {
      dispatch(setProductItemsContent(newValue));
    },
    [dispatch],
  );

  const productsSerializer = useCallback((data: AdministrationItemRow<ProductListInputData>[]) => {
    return getParseableStringFromPastedData(data);
  }, []);

  return (
    <ListItemsTextArea<ProductListInputData>
      label={label}
      id={id ?? 'ProductListItemsInput'}
      value={productItemsContent}
      placeholder={itemsPlaceholder}
      onValueChanged={productListItemsHandler}
      parserProvider={selectProductListParser}
      serializer={productsSerializer}
    />
  );
};

export default ProductListItemsTextArea;
