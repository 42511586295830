import { apiSlice, APITAGS, ErrorType } from '../../apis/api';
import { CalculationsCollection } from '../../swagger/collections';
import {
  CalculationDetailResponse,
  CalculationFileType,
  CalculationType,
  CalculationUpdateRequest,
  DataRetrievalStatus,
  Operation,
  PapProgressStatus,
  PapSyncStatus,
  SkuRedistributionResponse,
} from '@ydistri/api-sdk';
import { apiCalculations } from '../Calculations/apiCalculations';
import { getTags } from '../../apis/apiLib';
import {
  SignalCalculationFileStatusChanged,
  SignalCalculationStatusChanged,
} from '../Calculations/calculationsTypes';
import { signalrClient } from '../../signalr/client';
import { addToast } from '@ydistri/utils';
import { SignalConfigurationChanged } from '../../signalr/signalrInterfaces';

const { TAGS, TAGS_ARRAY } = getTags('calculationDetail');

export interface GetSinglePickingPositionRequestParams {
  calculationId: number;
  sourceSkuId: number;
  targetSkuId: number;
}

export interface CalculationUpdateRequestParams {
  calculationId: number;
  updateRequest: CalculationUpdateRequest;
}

export interface SkuRedistributionResponseDataResponse {
  messages?: string[] | null;
  data: SkuRedistributionResponse | undefined;
}

export const apiCalculationDetail = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getCalculation: builder.query<CalculationDetailResponse, number>({
        queryFn: async calculationId => {
          const { data } = await CalculationsCollection.getCalculation(calculationId);
          return data;
        },
        providesTags: result => [{ type: TAGS.calculation, id: result?.id ?? 'undefined' }],
        async onCacheEntryAdded(calculationId, api) {
          await api.cacheDataLoaded;

          const cacheEntry = api.getCacheEntry();
          if (cacheEntry.data) {
            const listener = (signal: SignalCalculationFileStatusChanged) => {
              if (signal.calculationId === calculationId) {
                //when a file was saved we fetch the calculation to replace the
                //files array with a new one that contains the new file and possibly another
                //file that is computing as we have no way of knowing whether the saved file
                //was the last one
                if (signal.dataRetrievalStatus === DataRetrievalStatus.Saved) {
                  CalculationsCollection.getCalculation(calculationId)
                    .then(response => {
                      const files = response.data.data.files;
                      api.updateCachedData(draft => {
                        draft.files = files;
                      });
                      const file = files.find(f => f.id === signal.fileId);
                      if (file) {
                        api.dispatch(
                          addToast({
                            message: `Calculation ${
                              file.calculationFileType === CalculationFileType.PairingXlsx
                                ? 'XLSX'
                                : 'ERP'
                            } file is ready`,
                          }),
                        );
                      }
                    })
                    .catch((error: unknown) => {
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
                      const err = error as ErrorType;
                      // eslint-disable-next-line no-console -- we want the output here
                      console.error('Error when retrieving list of files', err);
                      api.dispatch(
                        addToast({
                          message:
                            'There was an error when retrieving list of files. Please reload the page',
                          isError: true,
                        }),
                      );
                    });
                }
              }
            };

            const listener2 = (signal: SignalConfigurationChanged) => {
              // eslint-disable-next-line no-console -- we want the output here
              console.log('SIGNAL! SignalConfigurationChanged', signal);
              api.updateCachedData(draft => {
                if (draft.id !== signal.templateId) return;

                signal.updates.forEach(u => {
                  if (u.value) {
                    switch (u.fieldName) {
                      case 'name':
                        draft.title = u.value;
                        break;
                      case 'description':
                        draft.description = u.value;
                        break;
                      case 'isPap':
                        draft.isPap = u.value === '1';
                        break;
                      case 'papSyncStatus':
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
                        draft.papDbSyncStatus = u.value as PapSyncStatus;

                        //if synced, refetch calculation data to get new "synced" time
                        if (draft.papDbSyncStatus === PapSyncStatus.Synced) {
                          api.dispatch(
                            addToast({
                              message: `Calculation ${draft.id} synced to Y'pick. Refreshing data.`,
                            }),
                          );

                          api.dispatch(
                            apiCalculationDetail.util.invalidateTags([
                              { type: TAGS.calculation, id: draft.id },
                            ]),
                          );
                          return;
                        }
                        break;
                      case 'papResultCalculationProgressStatus':
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
                        draft.papResultCalculationProgressStatus = u.value as PapProgressStatus;
                        break;
                      case 'calculationTypeId':
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
                        draft.type = u.value as CalculationType;
                        break;
                    }
                  }
                });
              });
            };

            const listener3 = (signal: SignalCalculationStatusChanged) => {
              api.updateCachedData(draft => {
                if (draft.id === signal.calculationId) {
                  draft.status = signal.status;
                }
              });
            };

            signalrClient.on('CalculationFileStatusChanged', listener);
            signalrClient.on('TemplateConfigurationChanged', listener2);
            signalrClient.on('CalculationStatusChanged', listener3);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('CalculationFileStatusChanged', listener);
              signalrClient.off('TemplateConfigurationChanged', listener2);
              signalrClient.off('CalculationStatusChanged', listener3);
            });
          }

          await api.cacheEntryRemoved;
        },
      }),
      getSinglePickingPosition: builder.query<
        SkuRedistributionResponse | undefined,
        GetSinglePickingPositionRequestParams
      >({
        queryFn: async ({ calculationId, sourceSkuId, targetSkuId }) => {
          const { data } = await CalculationsCollection.getCalculationCategoryPairings(
            calculationId,
            1,
            {
              conditions: [
                { fieldName: 'SourceSkuId', operation: Operation.Eq, value: sourceSkuId },
                { fieldName: 'TargetSkuId', operation: Operation.Eq, value: targetSkuId },
              ],
            },
          );

          const result: SkuRedistributionResponseDataResponse = {
            data: data.data.length > 0 ? data.data[0] : undefined,
          };

          return result;
        },
        providesTags: (result, e, arg) => [
          { type: TAGS.calculationSinglePickingPosition, id: JSON.stringify(arg) },
        ],
      }),
      updateCalculation: builder.mutation<
        CalculationDetailResponse,
        CalculationUpdateRequestParams
      >({
        queryFn: async ({ calculationId, updateRequest }) => {
          const response = await CalculationsCollection.patchCalculation(
            calculationId,
            updateRequest,
          );
          return { data: response.data.data };
        },
        onQueryStarted: ({ calculationId, updateRequest }, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            apiCalculationDetail.util.updateQueryData('getCalculation', calculationId, draft => {
              const requestCopy = { ...updateRequest };
              const newType = requestCopy.calculationType ?? draft.type;
              if ('calculationType' in requestCopy) delete requestCopy.calculationType;
              Object.assign(draft, { type: newType, ...requestCopy });
            }),
          );

          //we need to invalidate the list of calculations after the update
          //so when user returns to the list of calculations, the list is refreshed with new type, name or description
          queryFulfilled
            .then(() => {
              dispatch(
                apiCalculations.util.invalidateTags([
                  { type: APITAGS.calculations.calculations, id: calculationId },
                  { type: APITAGS.calculations.calculations, id: 'LIST' },
                ]),
              );
            })
            .catch(patchResult.undo);
        },
      }),
    }),
  });

export const {
  useGetCalculationQuery,
  useUpdateCalculationMutation,
  useGetSinglePickingPositionQuery,
} = apiCalculationDetail;
