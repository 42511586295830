import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { computeRemSize, getDefaultSorting, InfiniteScrollTable, YColumnsType } from '@ydistri/ds';
import { EntityListItemResponse, EntityListType } from '@ydistri/api-sdk';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import { GetLatestEntityListItemsRequest, useGetEntityListItemsQuery } from '../../apiEntityLists';
import {
  entityListTypesWithProducts,
  entityListTypesWithStores,
  productDataIndexByEntityListType,
  storeDataIndexByEntityListType,
} from '../../entityListsLib';
import { capitalizeFirstLetter } from '@ydistri/utils';
import ScrolledTableWrapper from '../../../../../components/domain/SKUTable/ScrolledTableWrapper';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

interface EntityListGridProps {
  entityListType: EntityListType;
  selectedEntityListId: number;
}

const BLOCK_SIZE = 45;

const EntityListGrid: React.FC<EntityListGridProps> = ({
  entityListType,
  selectedEntityListId,
}) => {
  const { productIdColumnWidth, storeIdColumnWidth } = useColumnWidth();
  const [tableHeight] = useState(480);

  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const columns: YColumnsType<EntityListItemResponse>[] = useMemo(() => {
    const tmpColumns: YColumnsType<EntityListItemResponse>[] = [];

    if (entityListTypesWithProducts.includes(entityListType)) {
      const productObjectPath = productDataIndexByEntityListType[entityListType]
        .map(capitalizeFirstLetter)
        .join('/');

      const productColumnsConfig: EntityColumnsConfig<EntityListItemResponse> = {
        [EntityColumns.ID]: {
          width: productIdColumnWidth,
          apiColumnName: `${productObjectPath}/Id`,
          apiFilterable: true,
        },
        [EntityColumns.NAME]: {
          width: '29rem',
          apiColumnName: `${productObjectPath}/Name`,
          apiFilterable: true,
        },
        [EntityColumns.CODE]: {
          width: '6rem',
          apiColumnName: `${productObjectPath}/Code`,
          apiFilterable: true,
        },
      };

      const productColumns = productColumnsProvider(
        productColumnsConfig,
        productDataIndexByEntityListType[entityListType],
      );
      tmpColumns.push(...productColumns);
    }

    if (entityListTypesWithStores.includes(entityListType)) {
      const objectPath = storeDataIndexByEntityListType[entityListType]
        .map(capitalizeFirstLetter)
        .join('/');

      const columnsConfig: EntityColumnsConfig<EntityListItemResponse> = {
        [EntityColumns.ID]: {
          width: storeIdColumnWidth,
          apiColumnName: `${objectPath}/Id`,
          apiFilterable: true,
        },
        [EntityColumns.NAME]: {
          width: '29rem',
          apiColumnName: `${objectPath}/Name`,
          apiFilterable: true,
        },
        [EntityColumns.CODE]: {
          width: '6rem',
          apiColumnName: `${objectPath}/Code`,
          apiFilterable: true,
        },
      };
      const storeColumns = storeColumnsProvider(
        columnsConfig,
        storeDataIndexByEntityListType[entityListType],
      );
      tmpColumns.push(...storeColumns);

      tmpColumns.push({
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: '10rem',
        align: 'right',
        apiColumnName: 'Quantity',
        apiFilterable: true,
        apiFilterType: 'number',
        sorter: true,
      });
    }

    return tmpColumns;
  }, [
    entityListType,
    productColumnsProvider,
    productIdColumnWidth,
    storeColumnsProvider,
    storeIdColumnWidth,
  ]);

  const defaultParams = useMemo(() => {
    return {
      entityListId: selectedEntityListId,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [columns, selectedEntityListId]);

  const [params, setParams] = useState<GetLatestEntityListItemsRequest>(defaultParams);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  const { data, isFetching } = useGetEntityListItemsQuery(params);

  const rowKeyProvider = useCallback(
    (row: EntityListItemResponse) =>
      row.sku?.skuId ? `sku-${row.sku.skuId}` : `s-${row.store?.id}-p-${row.product?.id}`,
    [],
  );

  return (
    <ScrolledTableWrapper offset={60}>
      <InfiniteScrollTable<EntityListItemResponse, GetLatestEntityListItemsRequest>
        id="targetListContent"
        height={computeRemSize(tableHeight)}
        setParams={setParams}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
      />
    </ScrolledTableWrapper>
  );
};

export default EntityListGrid;
