import { apiSlice, TemplateOrCalculation, wrongTemplateInHeaders } from '../../../apis/api';
import { CalculationsCollection, CurrentSetupCollection } from '../../../swagger/collections';
import {
  ApiAction,
  ApiOperationType,
  CategoryFieldConfigurationResponse,
  CategorySkuCountResponse,
  ConfigurationFieldType,
  FieldConfigurationDeleteRequest,
  SkuType,
} from '@ydistri/api-sdk';
import { signalrClient } from '../../../signalr/client';
import { capitalizeFirstLetter } from '@ydistri/utils';
import { SignalConfigurationChanged } from '../../../signalr/signalrInterfaces';
import { getTags } from '../../../apis/apiLib';
import { createDebugLog } from '../../../lib/utils/logging';
import { EntityListConfiguration } from '../../../screens/Configuration/ConfigurationCategories/SegmentPotentialDefinition/ConfigurationEntityLists/entityListLib';

const debugLog = createDebugLog('apiCategoryTree');

const { TAGS, TAGS_ARRAY } = getTags('categoryTree');

export type PartialException = Omit<CategoryFieldConfigurationResponse, 'categoryId'>;

export type CategoryTreeNode = CategorySkuCountResponse & {
  parent: CategoryTreeNode | null;
  exceptions: PartialException[];
  childExceptionCount: number;
  slug: string;
  children: number[];
};

export type CategoryTree = Record<number, CategoryTreeNode | undefined>;

export interface ResetCategoryConfigurationsPayload {
  categoryId: number;
  apiAction: ApiAction;
}

export interface DeleteCategoryConfigurationPayload {
  categoryId: number;
  fields: FieldConfigurationDeleteRequest[];
}

export interface DeleteEntityListConfigurationPayload {
  categoryId: number;
  entityListId: number;
  configurationFieldType: ConfigurationFieldType;
}

export const categoriesTillParent = (
  category: CategoryTreeNode,
  includeRoot: boolean = false,
): CategoryTreeNode[] => {
  const categories: CategoryTreeNode[] = [category];
  let parent = category.parent;
  while (parent) {
    if (parent.parent === null && !includeRoot) break;
    categories.unshift(parent);
    parent = parent.parent;
  }
  return categories;
};

export const categoryStringRoute = (
  category: CategoryTreeNode | undefined,
  includeRoot: boolean = false,
): string =>
  category
    ? categoriesTillParent(category, includeRoot)
        .map(c => c.title)
        .join(' > ')
    : '';

export const apiCategoryTree = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getCategoryTree: builder.query<CategoryTree, TemplateOrCalculation>({
        queryFn: async arg => {
          if (wrongTemplateInHeaders(arg)) return { data: {} };
          //TODO: load at the same time
          const { data: categoryData } = await (arg.type === 'Template'
            ? CurrentSetupCollection.getCurrentCategories()
            : CalculationsCollection.getCalculationCategories(arg.id));
          const { data: exceptionData } = await (arg.type === 'Template'
            ? CurrentSetupCollection.getCurrentDeviations()
            : CalculationsCollection.getCalculationDeviations(arg.id));

          const categoryTree: CategoryTree = {};

          const exceptions: Record<number, PartialException[] | undefined> = {};

          exceptionData.data.forEach(e => {
            const { categoryId, ...partial } = e;
            if (exceptions[categoryId] === undefined) exceptions[categoryId] = [];
            exceptions[categoryId].push(partial);
          });
          const categoryList = categoryData.data.sort((a, b) => a.level - b.level);

          categoryList.forEach(category => {
            const exceptionsForThisCategory = exceptions[category.id] ?? [];

            categoryTree[category.id] = {
              ...category,
              childExceptionCount: 0,
              exceptions: exceptionsForThisCategory,
              slug: category.parentCategoryId
                ? `${categoryTree[category.parentCategoryId]?.slug ?? ''}-${category.id}`
                : category.id.toString(),
              parent: category.parentCategoryId
                ? (categoryTree[category.parentCategoryId] ?? null)
                : null,
              children: [],
            };

            if (category.parentCategoryId) {
              let parent = categoryTree[category.parentCategoryId];
              if (parent) {
                parent.children.push(category.id);

                if (exceptionsForThisCategory.length > 0) {
                  do {
                    parent.childExceptionCount += exceptionsForThisCategory.length;
                    parent = parent.parent ?? undefined;
                  } while (parent !== undefined);
                }
              }
            }
          });

          categoryList.forEach(category => {
            const c = categoryTree[category.id];
            if (c) {
              c.children = c.children.sort((a, b) => {
                const titleA = categoryTree[a]?.title ?? '';
                const titleB = categoryTree[b]?.title ?? '';
                return titleA.localeCompare(titleB);
              });
            }
          });

          return { data: categoryTree };
        },
        providesTags: [TAGS.categoryTree],
        async onCacheEntryAdded({ type: _, id: selectedTemplateId }, api) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              if (selectedTemplateId !== signal.templateId) return;
              if (signal.entityType === 'Category')
                api.updateCachedData(draft => {
                  const { updates, entityId: categoryId, operationType, apiAction } = signal;
                  // eslint-disable-next-line no-console -- we want this output
                  console.log('SIGNAL [in CategoryTree]', signal);

                  if (categoryId === 1 && operationType !== ApiOperationType.Replace) return;

                  const category = draft[categoryId];
                  if (category) {
                    let exceptionCountDiff = 0;

                    if (operationType === ApiOperationType.Replace) {
                      if (apiAction === ApiAction.DeleteSingleCategoryDeviations) {
                        // eslint-disable-next-line no-console -- we want this output
                        console.log('Deleting single...');
                        exceptionCountDiff -= category.exceptions.length;
                        // eslint-disable-next-line no-console -- we want this output
                        console.log('exceptionCountDiff increased to ', exceptionCountDiff);
                        category.exceptions = [];
                      } else if (apiAction === ApiAction.DeleteSubordinateCategoryDeviations) {
                        // eslint-disable-next-line no-console -- we want this output
                        console.log('Deleting also subcategories...');
                        const resetCategoryExceptions = (c: CategoryTreeNode) => {
                          c.childExceptionCount = 0;
                          exceptionCountDiff -= c.exceptions.length;
                          // eslint-disable-next-line no-console -- we want this output
                          console.log(
                            'exceptionCountDiff changed to ',
                            exceptionCountDiff,
                            'category (',
                            c.id,
                            ')',
                          );
                          c.exceptions = [];
                          c.children.forEach(childId => {
                            // eslint-disable-next-line no-console -- we want this output
                            console.log('Child', childId);
                            const child = draft[childId];
                            if (child) resetCategoryExceptions(child);
                          });
                        };

                        resetCategoryExceptions(category);
                      }
                    } else if (
                      operationType === ApiOperationType.Patch ||
                      operationType === ApiOperationType.Delete
                    ) {
                      updates.forEach(u => {
                        if (u.value !== undefined) {
                          const split = u.fieldName.split('|').map(capitalizeFirstLetter);
                          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it's a ConfigurationFieldType
                          const fieldType = split[0] as ConfigurationFieldType;
                          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it's a SkuType
                          const skuType = (split[1] === 'Null' ? null : split[1]) as SkuType;

                          if (operationType === ApiOperationType.Patch) {
                            const foundException = category.exceptions.find(
                              e => e.fieldType === fieldType && e.skuType === skuType,
                            );
                            if (foundException) {
                              foundException.value = u.value;
                              // eslint-disable-next-line no-console -- we want this output
                              console.log('exception found, setting to ', u.value);
                            } else {
                              const newException = {
                                skuType,
                                fieldType,
                                value: u.value,
                              };
                              // eslint-disable-next-line no-console -- we want this output
                              console.log('exceptionNotFound, pushing new', newException);
                              category.exceptions.push(newException);
                              exceptionCountDiff++;
                            }
                          } else {
                            category.exceptions = category.exceptions.filter(
                              e => !(e.fieldType === fieldType && e.skuType === skuType),
                            );
                            exceptionCountDiff--;
                          }
                        } else {
                          throw Error(`No value in update!`);
                        }
                      });
                    }

                    category.slug.split('-').forEach((c, i) => {
                      const pCategory = draft[parseInt(c)];
                      if (pCategory && i < category.level)
                        pCategory.childExceptionCount += exceptionCountDiff;
                    });
                  }
                });
              if (signal.entityType === 'EntityListConfiguration') {
                api.updateCachedData(draft => {
                  const { entityId: entityListId } = signal;
                  // eslint-disable-next-line no-console -- we want this output
                  console.log('SIGNAL [in CategoryTree]', signal);
                  const category = draft[1];

                  //TODO - add another ConfigurationFieldType everytime new entityType for entity lists is added to signalR
                  const fieldType = ConfigurationFieldType.PsMinLayerList;

                  if (category) {
                    const deleteEntityListFromExceptions = (c: CategoryTreeNode) => {
                      let exceptionCountDiff = 0;

                      c.exceptions = c.exceptions.filter(e => {
                        if (e.fieldType === fieldType) {
                          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it's a EntityListConfiguration[]
                          const oldConfigs = JSON.parse(e.value) as EntityListConfiguration[];
                          if (oldConfigs.length > 0) {
                            const newConfigs = oldConfigs.filter(
                              x => x.EntityListId !== entityListId,
                            );
                            if (newConfigs.length !== oldConfigs.length) {
                              e.value = JSON.stringify(newConfigs);
                              if (newConfigs.length === 0) {
                                exceptionCountDiff++;
                                return false;
                              }
                            }
                          }
                        }
                        return true;
                      });
                      let childExceptionCountDiff = 0;
                      c.children.forEach(childId => {
                        const child = draft[childId];
                        if (child) {
                          childExceptionCountDiff = deleteEntityListFromExceptions(child);
                        }
                      });

                      c.childExceptionCount -= childExceptionCountDiff;
                      return exceptionCountDiff + childExceptionCountDiff;
                    };

                    deleteEntityListFromExceptions(category);
                  }
                });
              }
            };

            debugLog('API - CategoryTree - onCacheEntryAdded [signalrClient.on]');
            signalrClient.on('TemplateConfigurationChanged', listener);

            api.cacheEntryRemoved.then(() => {
              debugLog('API - CategoryTree - cacheEntryRemoved [signalrClient.off]');
              signalrClient.off('TemplateConfigurationChanged', listener);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await api.cacheEntryRemoved;
        },
      }),
      resetCategoryConfigurations: builder.mutation<
        CategoryFieldConfigurationResponse[],
        ResetCategoryConfigurationsPayload
      >({
        queryFn: async ({ categoryId, apiAction }) => {
          const response = await CurrentSetupCollection.deleteCurrentCategoryDeviationsApiAction(
            categoryId,
            apiAction,
          );
          return { data: response.data.data };
        },
      }),
      deleteCategoryConfiguration: builder.mutation<undefined, DeleteCategoryConfigurationPayload>({
        queryFn: async ({ categoryId, fields }) => {
          await CurrentSetupCollection.deleteCurrentCategoryConfigurations(categoryId, fields);
          return { data: undefined };
        },
      }),
      deleteEntityListConfigurations: builder.mutation<
        undefined,
        DeleteEntityListConfigurationPayload
      >({
        queryFn: async ({ categoryId, entityListId, configurationFieldType }) => {
          await CurrentSetupCollection.deleteCurrentCategoryEntityListConfigurations(
            categoryId,
            entityListId,
            configurationFieldType,
          );
          return { data: undefined };
        },
      }),
    }),
  });

export const {
  useGetCategoryTreeQuery,
  useResetCategoryConfigurationsMutation,
  useDeleteCategoryConfigurationMutation,
  useDeleteEntityListConfigurationsMutation,
} = apiCategoryTree;

/*

  const deleteInheritance = useCallback(
    (
      skuType: SkuTypeColumn,
      configurationFieldType: ConfigurationFieldType,
      description: string,
    ) => {
      let isTarget = false;

      if (skuType === 'Target') {
        skuType = SkuType.FastMover;
        isTarget = true;
      }

      if (selectedTemplateId && categoryConfigurationDefinition.length > 0) {
        const payload: FieldConfigurationDeleteRequest[] = [
          {
            configurationFieldType: configurationFieldType,
            skuType: skuType ?? undefined,
          },
        ];

        //TODO - right now, we keep it like this - we display only one column for target on FE,
        // but in DB we still have 3 sku types, so all of them need to get updated
        if (isTarget) {
          payload.push({
            configurationFieldType: configurationFieldType,
            skuType: SkuType.SlowMover,
          });
          payload.push({
            configurationFieldType: configurationFieldType,
            skuType: SkuType.DeadStock,
          });
        }

        CurrentSetupCollection.deleteCurrentCategoryConfigurations(selectedCategory, payload).then(
          async () => {
            dispatch({
              type: ActionTypes.SET_DATA_STATUS,
              data: {
                templateStatus: 'Outdated',
              },
            });
          },
        );
      }
    },
    [selectedCategory, dispatch, selectedTemplateId, categoryConfigurationDefinition],
  );
 */
